import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const CHANGE_PASSWORD_VALIDATION = yup.object().shape({
    currentPassword: yup
        .string()
        .required('Please enter your current password'),
    password: yup
        .string()
        .min(8, 'Please use a minimum of 8 characters')
        .required('Please enter your new password'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], "This password doesn't match")
        .required('Please confirm your new password'),
});
async function changePasswordApi(buslane, validation, { formik, values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const body = await buslane
            .post('api/rest/me/password', { json: input })
            .json();
        if (!body.success) {
            throw new Error('Auth failure');
        }
        formik.resetForm();
    }
    catch (e) {
        formik.setFieldError('currentPassword', 'Incorrect password');
        throw e;
    }
    finally {
    }
}
const useChangePasswordApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => changePasswordApi(buslane, CHANGE_PASSWORD_VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: CHANGE_PASSWORD_VALIDATION,
    };
};
export default useChangePasswordApi;
