/* eslint-disable no-param-reassign */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React from 'react';
import { Money } from '@local/website-stdlib';
import FinanceCell from '../../components/FinanceTableCell';
import ForecastFieldMoney from "../components/ForecastFieldMoney";
import ForecastFieldPercent from "../components/ForecastFieldPercent";
import { calculatePercentOf, calculateCogsAsPercentageOfRevenue } from '../../entities/forecast';
function calculateValueFromPercentage(value, percent) {
    return new Money(value).multiply(Number(percent) / 100);
}
const ForecastGrowthTable = ({ forecast, }) => (React.createElement(TableContainer, { className: "forecast-table" },
    React.createElement(Table, { size: "small", padding: "default" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, { className: "forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }),
                React.createElement(TableCell, { className: "forecast-card__prior-period", component: "th", align: "right" }, "Prior Period"),
                React.createElement(TableCell, { component: "th" }),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", colSpan: 3, align: "center" },
                    "Forecast from",
                    ' ',
                    formatDate(forecast.firstMonth, 'LLL-yy')),
                React.createElement(TableCell, { component: "th", colSpan: 2, align: "center" }, "Changes")),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }),
                React.createElement(TableCell, { className: "forecast-card__prior-period", component: "th", align: "right" }, "Average Month"),
                React.createElement(TableCell, { component: "th", align: "center" }, "Growth"),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "Average Month"),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "12 Months"),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "% of Rev."),
                React.createElement(TableCell, { component: "th", align: "center" }, "Monthly $"),
                React.createElement(TableCell, { component: "th", align: "center" }, "%"))),
        React.createElement(TableBody, null,
            React.createElement(TableRow, { className: "forecast-table__row--start forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Revenue"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.revenue?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.revenue.subtract(forecast.rollingAverage.revenue), forecast.rollingAverage.revenue)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.revenue?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.revenue, compare: forecast.totals.revenue, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.revenuePercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.revenue", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.revenuePercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.revenue = calculateValueFromPercentage(forecast.totalsAverage.revenue, event.target.value);
                        } }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
                React.createElement(TableCell, { align: "right" }),
                React.createElement(TableCell, { align: "right" }),
                React.createElement(TableCell, { align: "right" }),
                React.createElement(TableCell, { align: "right" }),
                React.createElement(TableCell, { align: "right" },
                    React.createElement("b", null, "Price Increase (%)")),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.priceIncreasePercent", variant: "outlined", fullWidth: true }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "COGS"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.cogs?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.cogs.subtract(forecast.rollingAverage.cogs), forecast.rollingAverage.cogs)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.cogs?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.cogs?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.cogsPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.cogs", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.cogsPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            const cogs = calculateCogsAsPercentageOfRevenue(forecast.totals.revenue, forecast.totals.cogs, forecast.growthMonthly.revenue);
                            forecast.monthlyChange.cogs = calculateValueFromPercentage(cogs, event.target.value);
                        } }))),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Gross Margin"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.grossProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.grossProfit.subtract(forecast.rollingAverage.grossProfit), forecast.rollingAverage.grossProfit)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.grossProfit?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.grossProfit, compare: forecast.totals.grossProfit, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.grossProfitPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Direct Labour"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.directLabour?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.directLabour.subtract(forecast.rollingAverage.directLabour), forecast.rollingAverage.directLabour)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.directLabour?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.directLabour?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.directLabourPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.directLabour", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.directLabourPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.directLabour = calculateValueFromPercentage(forecast.totalsAverage.directLabour, event.target.value);
                        } }))),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Contribution Profit"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.contributionProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.contributionProfit.subtract(forecast.rollingAverage.contributionProfit), forecast.rollingAverage.contributionProfit)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.contributionProfit?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.contributionProfit, compare: forecast.totals.contributionProfit, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.contributionProfitPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Facilities"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.facilities?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.facilities.subtract(forecast.rollingAverage.facilities), forecast.rollingAverage.facilities)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.facilities?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.facilities?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.facilitiesPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.facilities", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.facilitiesPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.facilities = calculateValueFromPercentage(forecast.totalsAverage.facilities, event.target.value);
                        } }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Marketing and Sales"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.marketingSales?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.marketingSales.subtract(forecast.rollingAverage.marketingSales), forecast.rollingAverage.marketingSales)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.marketingSales?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.marketingSales?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.marketingSalesPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.marketingSales", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.marketingSalesPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.marketingSales = calculateValueFromPercentage(forecast.totalsAverage.marketingSales, event.target.value);
                        } }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Salaries"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.salaries?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.salaries.subtract(forecast.rollingAverage.salaries), forecast.rollingAverage.salaries)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.salaries?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.salaries?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.salariesPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.salaries", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.salariesPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.salaries = calculateValueFromPercentage(forecast.totalsAverage.salaries, event.target.value);
                        } }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Payroll and Benefits"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.payrollBenefits?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.payrollBenefits.subtract(forecast.rollingAverage.payrollBenefits), forecast.rollingAverage.payrollBenefits)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.payrollBenefits?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.payrollBenefits?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.payrollBenefitsPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.payrollBenefits", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.payrollBenefitsPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.payrollBenefits = calculateValueFromPercentage(forecast.totalsAverage.payrollBenefits, event.target.value);
                        } }))),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Remaining OpEx"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.remainingOpEx?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.remainingOpEx.subtract(forecast.rollingAverage.remainingOpEx), forecast.rollingAverage.remainingOpEx)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.remainingOpEx?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.remainingOpEx?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.remainingOpExPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.remainingOpEx", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.remainingOpExPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.remainingOpEx = calculateValueFromPercentage(forecast.totalsAverage.remainingOpEx, event.target.value);
                        } }))),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Operating Profit"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.operatingProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.operatingProfit.subtract(forecast.rollingAverage.operatingProfit), forecast.rollingAverage.operatingProfit)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.operatingProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.operatingProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.operatingProfitPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Other Income / Expenses"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.otherIncomeExpenses?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.otherIncomeExpenses.subtract(forecast.rollingAverage.otherIncomeExpenses), forecast.rollingAverage.otherIncomeExpenses)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.otherIncomeExpenses?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.otherIncomeExpenses?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.otherIncomeExpensesPercent),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldMoney, { name: "monthlyChange.otherIncomeExpenses", fullWidth: true, variant: "outlined" })),
                React.createElement(TableCell, null,
                    React.createElement(ForecastFieldPercent, { name: "monthlyChange.otherIncomeExpensesPercent", variant: "outlined", fullWidth: true, onChange: (event) => {
                            forecast.monthlyChange.otherIncomeExpenses = calculateValueFromPercentage(forecast.totalsAverage.otherIncomeExpenses, event.target.value);
                        } }))),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Net Profit Before Tax"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.netProfitBeforeTax?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growthMonthly.netProfitBeforeTax.subtract(forecast.rollingAverage.netProfitBeforeTax), forecast.rollingAverage.netProfitBeforeTax)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.netProfitBeforeTax?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.netProfitBeforeTax, compare: forecast.totals.netProfitBeforeTax, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.netProfitBeforeTaxPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null))))));
export default ForecastGrowthTable;
