import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCacheQuery } from 'butler';
import formatDate from 'date-fns/format';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useForecastApi from '../../../api/useForecastApi';
import positiveLogoImage from '../../../static/images/logo_positive.png';
import ButtonLink from '../../components/ButtonLink';
import Dashboard from '../../components/DashboardComponent';
import PrimaryNavListComponent from '../../components/PrimaryNavListComponent';
import Forecast from '../../entities/forecast';
import ErrorCard from '../../errors/ErrorCard';
import Page from './pages/Page';
import ReportCash from './pages/ReportCash';
import ReportGrowth from './pages/ReportGrowth';
import ReportProfit from './pages/ReportProfit';
import ReportSales from './pages/ReportSales';
import ReportStart from './pages/ReportStart';
const ReportPage = () => {
    const { id } = useParams();
    const forecastApi = useForecastApi();
    const forecastQuery = useCacheQuery(['forecast', id], forecastApi.handleRequest, forecastApi.transform, {
        retry: false,
    });
    if (forecastQuery.isLoading) {
        return (React.createElement(Box, { display: "flex", width: "100%" },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Loading...")),
            React.createElement(Box, { width: "100%" }, "Loading...")));
    }
    const forecastResource = forecastQuery.data;
    if (!forecastResource) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Forecast Not Found")),
            React.createElement(ErrorCard, { title: "Forecast not found" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonLink, { to: "/", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "Take me home"))))));
    }
    const forecast = new Forecast(forecastResource);
    const createdAt = formatDate(forecast.values.updatedAt, 'dd/MM/yyyy');
    const printedAt = formatDate(new Date(), 'dd/MM/yyyy');
    const pages = [
        React.createElement(ReportStart, { forecast: forecast.values }),
        React.createElement(ReportSales, { forecast: forecast }),
        React.createElement(ReportProfit, { forecast: forecast }),
        React.createElement(ReportCash, { forecast: forecast }),
        React.createElement(ReportGrowth, { forecast: forecast }),
    ];
    const headers = ['Start', 'Sales and Margin', 'Profit', 'Cash', 'Growth'];
    if (!forecast.values.revenueStreams.length) {
        pages.splice(1, 1);
        headers.splice(1, 1);
    }
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Helmet, null,
            React.createElement("title", null, `Numereyes - ${forecast.values.name}`)),
        React.createElement(Page, { pages: pages, headers: headers }),
        React.createElement(Box, { className: "page-header", position: "fixed", height: 72, top: 0, paddingY: 1, display: "flex" },
            React.createElement(Box, { paddingTop: 1 },
                React.createElement(Typography, { variant: "h5" }, forecast.values.name),
                React.createElement(Typography, { variant: "subtitle2" },
                    forecast.values.numberOfMonths,
                    " Month Forecast (created",
                    ' ',
                    createdAt,
                    ")")),
            React.createElement(Box, { flexGrow: 1 }),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", alignItems: "center" },
                    React.createElement("img", { src: positiveLogoImage, alt: "Numereyes", width: "auto", height: "56px" }),
                    React.createElement(Box, { paddingTop: "12px", paddingLeft: 2 },
                        React.createElement(Typography, { variant: "h5" }, "Profit and Cash Forecast"))),
                React.createElement(Box, { display: "flex", justifyContent: "flex-end" },
                    React.createElement(Typography, { variant: "subtitle2" }, forecast.values.companyName)))),
        React.createElement(Box, { className: "page-footer", position: "fixed", height: 48, bottom: 0, paddingY: 1, display: "flex" },
            React.createElement(Box, { flexGrow: 1 }),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", justifyContent: "flex-end" },
                    React.createElement(Typography, { variant: "subtitle2" }, printedAt))))));
};
export default ReportPage;
