import React, { useState } from 'react';
import { MoneyHundred } from '@local/website-stdlib';
const IS_SSR = typeof window === 'undefined';
let ReactApexChart;
if (!IS_SSR) {
    ReactApexChart = require('react-apexcharts').default;
}
function toThousands(value) {
    return Math.round(Number(value) / 100) * 100;
}
const FinancialGraph = ({ labels, data, type, stacked, hideByDefault, noAnimate, }) => {
    const [hiddenSeries, setHiddenSeries] = useState(() => {
        const initialSeries = {};
        hideByDefault?.forEach((name) => {
            initialSeries[name] = true;
        });
        return initialSeries;
    });
    if (IS_SSR) {
        return null;
    }
    const seriesList = data.map((series) => {
        const nextSeries = {
            ...series,
        };
        if (hiddenSeries[nextSeries.name]) {
            nextSeries.data = [];
        }
        return nextSeries;
    });
    let max = 0;
    seriesList.forEach((series) => {
        series.data.forEach((value) => {
            max = Math.max(max, value);
        });
    });
    const options = {
        chart: {
            animations: {
                enabled: !noAnimate,
            },
            type: 'line',
            stacked,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            events: {
                mounted: (chartContext, config) => {
                    Object.keys(hiddenSeries).forEach((seriesName) => {
                        chartContext.hideSeries(seriesName);
                    });
                },
                updated: (chartContext, { config }) => {
                    let isEqual = true;
                    const nextHiddenSeries = {};
                    config.series.forEach((series) => {
                        const { isHidden } = chartContext.series.isSeriesHidden(series.name);
                        nextHiddenSeries[series.name] = isHidden;
                        isEqual =
                            hiddenSeries[series.name] === isHidden && isEqual;
                    });
                    if (!isEqual) {
                        setHiddenSeries(nextHiddenSeries);
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            dashArray: data.map((category) => category.dash || 0),
        },
        fill: {
            type: 'solid',
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
            },
        },
        labels,
        legend: {
            position: 'left',
            horizontalAlign: 'left',
        },
        xaxis: {
            type: 'string',
        },
        yaxis: {
            type: 'string',
            forceNiceScale: true,
            labels: {
                formatter(value) {
                    if (value === undefined) {
                        return value;
                    }
                    return new MoneyHundred(toThousands(Number(value)), 'NZD').toString();
                },
            },
            max(value) {
                if (value > max) {
                    return toThousands(max * 1.05);
                }
                return value;
            },
            tickAmount: 6,
        },
        tooltip: {
            shared: true,
            y: {
                formatter(value) {
                    if (value === undefined) {
                        return value;
                    }
                    return new MoneyHundred(value, 'NZD').toString();
                },
            },
        },
    };
    return (React.createElement(ReactApexChart, { options: options, series: seriesList, type: type, height: "100%" }));
};
export default FinancialGraph;
