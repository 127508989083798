import Box from '@material-ui/core/Box';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import CreateResellerComponent from './CreateResellerComponent';
const CreateResellerPage = () => (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
    React.createElement(Box, { display: "flex", width: "100%", justifyContent: "center", paddingTop: "10%" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Numereyes - Create Coach")),
        React.createElement(Box, { width: "100%", maxWidth: 480, padding: 4 },
            React.createElement(CreateResellerComponent, null)))));
export default CreateResellerPage;
