import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import createTheme from '@material-ui/core/styles/createTheme';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import React from 'react';
import './app.scss';
import { CoreStateProvider } from './CoreState';
export const theme = createTheme({
    palette: {
        primary: {
            main: '#F68B1F',
        },
        secondary: {
            main: '#808285',
        },
    },
});
const App = ({ children }) => (React.createElement(React.Fragment, null,
    React.createElement(CoreStateProvider, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(CssBaseline, null),
            React.createElement(LocalizationProvider, { dateAdapter: DateFnsUtils }, children)))));
export default App;
