import { useAbility } from '@casl/react';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { GatekeeperContext } from './Gatekeeper';
const CanRoute = ({ to, redirect }) => {
    let child = null;
    const location = useLocation();
    const ability = useAbility(GatekeeperContext);
    Object.entries(to).some(([permission, component]) => {
        if (permission === '') {
            child = React.createElement(component);
            return true;
        }
        if (ability.can(permission, undefined)) {
            child = React.createElement(component);
            return true;
        }
        return false;
    });
    if (!child && redirect) {
        return (React.createElement(Redirect, { to: {
                pathname: redirect,
                state: { from: location.pathname },
            } }));
    }
    return child;
};
export default CanRoute;
