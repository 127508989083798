import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useMutation, useQueryCache } from 'butler';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useLogoutApi from '../../api/useLogoutApi';
const LogoutListItemComponent = () => {
    const history = useHistory();
    const { handleRequest } = useLogoutApi();
    const [mutate, logoutInfo] = useMutation(handleRequest);
    const queryCache = useQueryCache();
    const onLogout = useCallback(async () => {
        try {
            await mutate();
            history.push('/login');
        }
        catch (e) { }
    }, [mutate, queryCache, history]);
    return (React.createElement(Box, { position: "relative" },
        React.createElement(ListItem, { button: true, onClick: onLogout, disabled: logoutInfo.isLoading },
            React.createElement(ListItemIcon, null,
                React.createElement(ExitToAppIcon, null)),
            React.createElement(ListItemText, { primary: "Logout" })),
        React.createElement(Fade, { in: logoutInfo.isLoading, style: {
                transitionDelay: logoutInfo.isLoading ? '200ms' : '0ms',
            }, unmountOnExit: true },
            React.createElement(Box, { position: "absolute", top: "0", left: "16px", width: "24px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
                React.createElement(CircularProgress, { size: 24 })))));
};
export default LogoutListItemComponent;
