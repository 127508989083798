import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { useBuslane } from 'buslane';
import { useCacheQuery } from 'butler';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
// Exports...
export { default as CanRoute } from './CanRoute';
// This also exposes our rest client so we can intercept 401 and 403
// @ts-ignore
export const GatekeeperContext = createContext();
const ContextCan = createContextualCan(GatekeeperContext.Consumer);
export const Can = ({ children, ...rest }) => (
// @ts-ignore
React.createElement(ContextCan, { ...rest }, children));
Can.defaultProps = {
    a: undefined,
};
function transformPermissions(permissions = []) {
    return {
        permissions: permissions.map((permission) => ({ action: permission })),
    };
}
async function fetchPermissions(buslane) {
    const body = await buslane
        .post('api/rest/auth/permissions')
        .json();
    if (!body.success) {
        throw new Error('Not found');
    }
    return body.data.permissions;
}
const Gatekeeper = ({ children }) => {
    const buslane = useBuslane();
    const handleFetchPermissions = useCallback((args) => fetchPermissions(buslane), [buslane]);
    const authQuery = useCacheQuery('auth', handleFetchPermissions, transformPermissions);
    const ability = useMemo(() => {
        return new Ability(authQuery.data ? authQuery.data.permissions : []);
    }, [authQuery.data]);
    useEffect(() => {
        const on401 = async () => {
            console.log('event 401');
            await authQuery.refetch().catch(() => { });
        };
        const on403 = async () => {
            console.log('event 403');
            await authQuery.refetch().catch(() => { });
        };
        const onAuthentication = async () => {
            console.log('event authentication');
            await authQuery.refetch().catch(() => { });
        };
        buslane.on('401', on401);
        buslane.on('403', on403);
        buslane.on('authentication', onAuthentication);
        return () => {
            console.log('unmount?');
            buslane.removeListener('401', on401);
            buslane.removeListener('403', on403);
            buslane.removeListener('authentication', onAuthentication);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buslane]);
    // TODO: add listeners... to the buslane
    if (authQuery.isLoading) {
        return (React.createElement("div", null,
            React.createElement("span", null, "Loading...")));
    }
    if (authQuery.isError) {
        return (React.createElement("div", null,
            React.createElement("span", null, "Loading...")));
    }
    return (React.createElement(GatekeeperContext.Provider, { value: ability }, children));
};
export default Gatekeeper;
