import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
import { MONTH_OPTIONS } from '../app/utils/getMonthName';
const RECORD_VALIDATION = yup.object().shape({
    accountYear: yup
        .number()
        .min(0, 'Please enter a positive year')
        .required('Please enter a year'),
    accountMonth: yup
        .string()
        .oneOf(MONTH_OPTIONS.map(({ value }) => value), `Please select one of ${MONTH_OPTIONS.map(({ label }) => label).join(', ')}`)
        .required('Please select an month'),
    monthlyAccounts: yup
        .array()
        .min(1, 'Accounts file required')
        .required('Accounts file required'),
});
/*
)
        yup.object().shape({
            account: yup.string().required(),
            category: yup.string().required(),
            credit: yup.string().optional().default(''),
            debit: yup.string().optional().default(''),
            openingBalance: yup.string().optional().default(''),
        }
 */
const VALIDATION = yup.object().shape({
    record: RECORD_VALIDATION.required(),
});
async function createRecordApi(buslane, validation, { formik, values, companyId }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const yearInt = Number(input.record.accountYear);
        const monthInt = Number(input.record.accountMonth);
        const accountMonth = new Date(yearInt, monthInt, 21, 0, 0, 0);
        const { monthlyAccounts } = input.record;
        const transformedInput = {
            companyId,
            accountMonth,
            monthlyAccounts,
        };
        const body = await buslane
            .post('api/rest/account-record/create', { json: transformedInput })
            .json();
        if (body.success) {
            return true;
        }
    }
    catch (e) { }
    throw new Error('Failed to create record');
}
const useCreateRecordApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => createRecordApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
        monthOptions: MONTH_OPTIONS,
    };
};
export default useCreateRecordApi;
