import { useBuslane } from 'buslane';
import qs from 'qs';
import { useCallback } from 'react';
export function transformCompanies(companies) {
    return {
        totalCount: companies.totalCount,
        items: companies.items.map((company) => ({
            ...company,
            createdAt: new Date(company.createdAt),
            updatedAt: new Date(company.updatedAt ?? company.createdAt),
        })),
    };
}
async function companiesListApi(buslane, { page, pageSize, sort }) {
    const input = {
        $limit: pageSize,
        $offset: page * pageSize,
        $orderBy: sort,
    };
    const body = await buslane
        .get('api/rest/companies', {
        searchParams: qs.stringify(input),
    })
        .json();
    if (!body.success) {
        return {
            items: [],
            totalCount: 0,
        };
    }
    const tableInfo = {
        items: body.data,
        totalCount: body.pageInfo.totalCount,
    };
    return tableInfo;
}
const useCompaniesListApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, parameters) => {
        return companiesListApi(buslane, parameters);
    }, [buslane]);
    return {
        handleRequest,
        handleTransform: transformCompanies,
    };
};
export default useCompaniesListApi;
