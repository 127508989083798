import React, { useContext, useReducer } from 'react';
const defaultState = {
    isDrawOpen: true,
};
const CoreStateContext = React.createContext(defaultState);
function reducer(currentState, action) {
    return {
        ...currentState,
        ...action,
    };
}
export const CoreStateProvider = ({ children }) => {
    const coreState = useReducer(reducer, undefined, () => defaultState);
    return (React.createElement(CoreStateContext.Provider, { value: coreState }, children));
};
export function useCoreState() {
    const [coreState, coreReducer] = useContext(CoreStateContext);
    return [coreState, coreReducer];
}
