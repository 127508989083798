import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import withStyles from '@material-ui/styles/withStyles';
const WarningButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(Button);
export default WarningButton;
