import Box from '@material-ui/core/Box';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ResellersListComponent from './ResellerListComponent';
const ResellersPage = () => {
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Numereyes - Coaches")),
        React.createElement(Box, { width: "100%", padding: 4 },
            React.createElement(ResellersListComponent, null))));
};
export default ResellersPage;
