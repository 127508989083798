import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useCacheQuery } from 'butler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useForecastApi from '../../api/useForecastApi';
import ButtonLink from '../components/ButtonLink';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ErrorCard from '../errors/ErrorCard';
import EditForecastForm from './EditForecastForm';
const ViewForecastPage = () => {
    const { id } = useParams();
    const forecastApi = useForecastApi();
    const forecastQuery = useCacheQuery(['forecast', id], forecastApi.handleRequest, forecastApi.transform, {
        retry: false,
    });
    if (forecastQuery.isLoading) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Loading...")),
            React.createElement(Box, { width: "100%" }, "Loading...")));
    }
    const forecast = forecastQuery.data;
    if (!forecast) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Forecast Not Found")),
            React.createElement(ErrorCard, { title: "Forecast not found" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonLink, { to: "/", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "Take me home"))))));
    }
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Box, { display: "flex", width: "100%" },
            React.createElement(Helmet, null,
                React.createElement("title", null, `Numereyes - ${forecast.name}`)),
            React.createElement(Box, { width: "100%", padding: 4 },
                React.createElement(EditForecastForm, { forecast: forecast })))));
};
export default ViewForecastPage;
