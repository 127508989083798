import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useCacheQuery } from 'butler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useCompanyApi from '../../api/useCompanyApi';
import ButtonLink from '../components/ButtonLink';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ErrorCard from '../errors/ErrorCard';
import ForecastsListComponent from './ForecastListComponent';
const CompanyForecastPage = () => {
    const { id } = useParams();
    const companyApi = useCompanyApi();
    const companyQuery = useCacheQuery(['company', id], companyApi.handleRequest, companyApi.transform, {
        retry: false,
    });
    if (companyQuery.isLoading) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Loading...")),
            React.createElement(Box, { width: "100%" }, "Loading...")));
    }
    const company = companyQuery.data;
    if (!company) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Company Not Found")),
            React.createElement(ErrorCard, { title: "Company not found" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonLink, { to: "/company/create", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "Create new company"))))));
    }
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Helmet, null,
            React.createElement("title", null, `Numereyes - ${company.name}`)),
        React.createElement(Box, { width: "100%", padding: 4 },
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12, sm: 9 }),
                React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                    React.createElement(ButtonLink, { to: `/company/${company.id}/create-forecast`, variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "New Forecast"))),
            React.createElement(Box, { marginTop: 4 },
                React.createElement(ForecastsListComponent, { companyId: company.id, companyName: company.name })))));
};
export default CompanyForecastPage;
