import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const VALIDATION = yup.object().shape({
    companyId: yup.string(),
    firstMonth: yup
        .date()
        .required('Please enter a date')
        .typeError('Please enter a date'),
    lastMonthOfData: yup
        .date()
        .required('Please enter a date')
        .typeError('Please enter a date'),
    gstPeriodEndedAt: yup
        .date()
        .required('Please enter a date')
        .typeError('Please enter a date'),
    name: yup
        .string()
        .min(1, 'Please enter a name')
        .max(50, 'Please use a shorter name')
        .required('Please enter a name'),
    numberOfMonths: yup
        .number()
        .integer(' ')
        .min(1, ' ')
        .max(120, ' ')
        .required(' '),
    creditorDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    debtorDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    inventoryDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required')
        .typeError('Please enter a number'),
    gstPaidAfterDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstRevenue: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstExpenses: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstPeriodMonths: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    taxRate: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    cashOnHand: yup.money().required('This field is required'),
    payables: yup.money().required('This field is required'),
    receivables: yup.money().required('This field is required'),
    inventoryValue: yup.money().required('This field is required'),
    rolling: yup.object().shape({
        revenue: yup.money().required('Please enter your revenue'),
        cogs: yup.money().required('Please enter your cogs'),
        directLabour: yup.money().required('Please enter your direct labour'),
        facilities: yup.money().required('Please enter your facilities'),
        marketingSales: yup
            .money()
            .required('Please enter your marketing and sales'),
        salaries: yup.money().required('Please enter your salaries'),
        payrollBenefits: yup
            .money()
            .required('Please enter your payroll benefits'),
        remainingOpEx: yup
            .money()
            .required('Please enter your remaining op ex'),
        otherIncomeExpenses: yup
            .money()
            .required('Please enter your other income or expenses'),
    }),
});
async function createForecastApi(buslane, validation, { formik, values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const body = await buslane
            .post('api/rest/forecast/create', { json: input })
            .json();
        if (body.success) {
            return body.data.id;
        }
    }
    catch (e) {
        console.log('#createForecastApi', e);
    }
    throw new Error('Failed to create forecast');
}
const useCreateForecastApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => createForecastApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
    };
};
export default useCreateForecastApi;
