import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import { Form } from 'formik';
import React from 'react';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import PositiveButton from '../../components/PositiveButton';
import ForecastSubHeader from '../ForecastSubHeader';
import ForecastProfitGraph from './ForecastProfitGraph';
import ForecastProfitTable from './ForecastProfitTable';
import { useForecast } from "../components/forecastProvider";
import AutofillToggle from "../components/autofillToggle.";
const useStyles = makeStyles((theme) => ({
    tableContainer: {
        '& table': {
            whiteSpace: 'nowrap',
        },
        '& table th': {
            padding: '4px 8px',
        },
        '& table td': {
            padding: '4px 8px',
        },
        '& table .group-start': {
            borderTopColor: '#E0E0E0',
            borderTopWidth: 4,
            borderTopStyle: 'solid',
        },
        '& table .group-end': {
            borderBottomColor: '#E0E0E0',
            borderBottomWidth: 4,
            borderBottomStyle: 'solid',
        },
        '& table .MuiInputBase-root': {
            fontSize: '1em',
        },
        '& table .MuiFormLabel-root': {
            fontSize: '1em',
        },
        '& table .MuiInputBase-root input': {
            padding: '8px 10px',
            minWidth: 108,
        },
    },
}));
// <ForecastProfitGraph profit={profit} />
const ForecastProfitComponent = ({ formikProps, }) => {
    const forecast = useForecast();
    return (React.createElement(Form, { noValidate: true, autoComplete: "off", method: "POST" },
        React.createElement(Card, null,
            React.createElement(CardHeader, { className: "forecast-card__header", title: "Profit Calculator", subheader: React.createElement(ForecastSubHeader, { forecast: forecast }), action: React.createElement(Box, { display: "flex", alignItems: "center" },
                    React.createElement(AutofillToggle, { forecast: forecast }),
                    React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Save")) }),
            React.createElement(CardContent, null,
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ForecastProfitGraph, { forecast: forecast.values })),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ForecastProfitTable, { forecast: forecast.values, formikProps: formikProps })))),
            React.createElement(CardActions, null,
                React.createElement(Box, { padding: 1, flexGrow: 1, display: "flex", justifyContent: "flex-end", position: "relative" },
                    React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Save"))))));
};
export default ForecastProfitComponent;
