import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'butler';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useCreateCompanyApi from '../../api/useCreateCompanyApi';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import PositiveButton from '../components/PositiveButton';
import FormTextField from '../../form/FormTextField';
import FormSelectField from '../../form/FormSelectField';
const CreateCompanyComponent = () => {
    const history = useHistory();
    const createCompanyApi = useCreateCompanyApi();
    const [mutate] = useMutation(createCompanyApi.handleRequest);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            const companyId = await mutate({
                values,
                formik: formikHelpers,
            });
            if (companyId) {
                history.push(`/company/${companyId}`);
            }
        }
        catch (e) { }
    }, [mutate, history]);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Formik, { validationSchema: createCompanyApi.validation, onSubmit: onSubmit, initialValues: {} }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST" },
            React.createElement(Card, null,
                React.createElement(CardHeader, { title: "Create Company" }),
                React.createElement(CardContent, null,
                    React.createElement(Grid, { container: true, spacing: 6 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "company.name", label: "Name", variant: "outlined" })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormSelectField, { fullWidth: true, variant: "outlined", name: "company.erpType", label: "ERP Used", options: createCompanyApi.erpOptions })))),
                React.createElement(CardActions, null,
                    React.createElement(Box, { padding: 1, width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" },
                        React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Create Company")))))))));
};
export default CreateCompanyComponent;
