// @graph-mind
// Remove the previous line to stop Ada from updating this file
import * as yupBase from 'yup';
import Money from '../primitive/money';
const isAbsent = (value) => value == null;
export default class MoneySchemaType extends yupBase.mixed {
    constructor() {
        super();
        this.withMutation(() => {
            this.transform(function (value, originalValue) {
                if (this.isType(value)) {
                    // we have a valid value
                    return value;
                }
                return new Money(originalValue.amount, originalValue.currency);
            });
        });
    }
    _cast(rawValue) {
        let value = rawValue;
        // @ts-ignore
        if (!value && this._default) {
            const defaultValue = this.default();
            if (typeof defaultValue === 'number') {
                value = { amount: defaultValue };
            }
            else {
                value = defaultValue;
            }
        }
        if (!value) {
            value = { amount: 0 };
        }
        if (value instanceof Money) {
            return value;
        }
        return new Money(value.amount, value.currency);
    }
    _typeCheck(value) {
        if (value instanceof Money) {
            return true;
        }
        return false;
    }
    async _validate(_value, options = {}) {
        try {
            // @ts-ignore
            const result = await super._validate(_value, options);
            return result;
        }
        catch (err) {
            if (!err.inner || !err.inner.length) {
                throw err;
            }
            const innerError = err.inner[0];
            if (innerError.type === 'currency') {
                err.path = `${err.path}.currency`;
            }
            else {
                err.path = `${err.path}.amount`;
            }
            innerError.path = err.path;
            throw err;
        }
    }
    min(min, message = '') {
        return this.test({
            message,
            name: 'min',
            exclusive: true,
            params: { min },
            test(value) {
                return (isAbsent(value) || value.value.value >= this.resolve(min));
            },
        });
    }
    max(max, message = '') {
        return this.test({
            message,
            name: 'max',
            exclusive: true,
            params: { max },
            test(value) {
                return (isAbsent(value) || value.value.value <= this.resolve(max));
            },
        });
    }
    lessThan(less, message = '') {
        return this.test({
            message,
            name: 'max',
            exclusive: true,
            params: { less },
            test(value) {
                return (isAbsent(value) || value.value.value < this.resolve(less));
            },
        });
    }
    moreThan(more, message = '') {
        return this.test({
            message,
            name: 'min',
            exclusive: true,
            params: { more },
            test(value) {
                return (isAbsent(value) || value.value.value > this.resolve(more));
            },
        });
    }
}
