import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'butler';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import FormTextField from '../../form/FormTextField';
import useChangePasswordApi from '../../api/useChangePasswordApi';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import PositiveButton from '../components/PositiveButton';
const ChangePasswordComponent = () => {
    const { handleRequest, validation } = useChangePasswordApi();
    const [mutate] = useMutation(handleRequest);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            await mutate({ values, formik: formikHelpers });
        }
        catch (e) { }
    }, [mutate]);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Formik, { validationSchema: validation, onSubmit: onSubmit, initialValues: {} }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST" },
            React.createElement(Card, null,
                React.createElement(CardHeader, { title: "Change password" }),
                React.createElement(CardContent, null,
                    React.createElement(Grid, { container: true, spacing: 4 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "currentPassword", label: "Current Password", type: "password", autoComplete: "current-password", variant: "outlined" })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "password", label: "New Password", type: "password", autoComplete: "password", variant: "outlined" })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "confirmPassword", label: "Confirm Password", type: "password", autoComplete: "new-password", variant: "outlined" })))),
                React.createElement(CardActions, null,
                    React.createElement(Box, { padding: 1, width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" },
                        React.createElement(ButtonWithSpinner, { type: "submit", onClick: formikProps.submitForm, disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Change Password")))))))));
};
export default ChangePasswordComponent;
