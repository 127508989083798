import Box from '@material-ui/core/Box';
import { useMutation } from 'butler';
import { Formik } from 'formik';
import React, { useCallback, useState, useEffect, } from 'react';
import { useHistory } from 'react-router-dom';
import useCreateForecastApi from '../../../api/useCreateForecastApi';
import ForecastStartingComponent from './ForecastStartingComponent';
import Forecast from "../../entities/forecast";
import { ForecastProvider } from '../components/forecastProvider';
const CreateForecastForm = ({ companyId, companyName }) => {
    const [forecast] = useState(() => new Forecast({
        companyId,
        companyName,
    }));
    const [forceRefresh, setRefresh] = useState(false);
    useEffect(() => {
        forecast.on('refresh', () => {
            setRefresh(!forceRefresh);
        });
        return () => {
            forecast.removeListener('refresh');
        };
    }, [forceRefresh]);
    const history = useHistory();
    const forecastApi = useCreateForecastApi();
    const [mutate] = useMutation(forecastApi.handleRequest);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            await forecast.validateWith(forecastApi.validation);
        }
        catch (e) {
            return;
        }
        try {
            const forecastId = await mutate({
                formik: formikHelpers,
                values: forecast.values,
                companyId,
            });
            if (forecastId) {
                history.push(`/forecast/${forecastId}`);
            }
        }
        catch (e) { }
    }, [mutate, history]);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(ForecastProvider, { forecast: forecast },
            React.createElement(Formik, { onSubmit: onSubmit, initialValues: {} }, (formikProps) => {
                return (React.createElement(ForecastStartingComponent, { formikProps: formikProps, create: true }));
            }))));
};
export default CreateForecastForm;
