/* eslint-disable no-underscore-dangle,@typescript-eslint/ban-ts-comment */
import { Buslane, BuslaneProvider } from 'buslane';
import { Butler as ButlerCache, ButlerProvider } from 'butler';
import React from 'react';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { makeQueryCache, ReactQueryCacheProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import './static/scss/styles.scss';
import App from './app';
import Gatekeeper from './app/gatekeeper/Gatekeeper';
import Pages from './app/Pages';
// @ts-ignore
const INITIAL_STATE = window.__STATE__ || {};
// @ts-ignore
delete window.__STATE__;
const buslane = new Buslane({
    prefixUrl: '/',
    hooks: {
        beforeRequest: [],
        afterResponse: [
            async (request, options, response) => {
                if (response.status === 401) {
                    await buslane.emitAsync('401');
                }
                else if (response.status === 403) {
                    await buslane.emitAsync('403');
                }
            },
        ],
    },
});
const butler = new ButlerCache();
butler.loadCache(INITIAL_STATE);
const queryCache = makeQueryCache();
const Main = () => {
    React.useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
        const stateServerSide = document.querySelector('#state-server-side');
        if (stateServerSide) {
            stateServerSide.parentNode.removeChild(stateServerSide);
        }
    }, []);
    return (React.createElement(BrowserRouter, null,
        React.createElement(HelmetProvider, null,
            React.createElement(BuslaneProvider, { buslane: buslane },
                React.createElement(ReactQueryCacheProvider, { queryCache: queryCache },
                    React.createElement(ButlerProvider, { butler: butler },
                        React.createElement(Gatekeeper, null,
                            React.createElement(App, null,
                                React.createElement(Pages, null)))))))));
};
hydrate(React.createElement(Main, null), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
}
