import { useBuslane } from 'buslane';
import qs from 'qs';
import { useCallback } from 'react';
import { transformForecast } from './useForecastApi';
export function transformForecasts(forecasts) {
    return {
        totalCount: forecasts.totalCount,
        items: forecasts.items.map(transformForecast),
    };
}
async function forecastsListApi(buslane, { page, pageSize, sort, companyId }) {
    const input = {
        $limit: pageSize,
        $offset: page * pageSize,
        $orderBy: sort,
        companyId,
    };
    const body = await buslane
        .get('api/rest/forecasts', {
        searchParams: qs.stringify(input),
    })
        .json();
    if (!body.success) {
        return {
            items: [],
            totalCount: 0,
        };
    }
    const tableInfo = {
        items: body.data,
        totalCount: body.pageInfo.totalCount,
    };
    return tableInfo;
}
const useForecastsListApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, parameters) => forecastsListApi(buslane, parameters), [buslane]);
    return {
        handleRequest,
        handleTransform: transformForecasts,
    };
};
export default useForecastsListApi;
