import Typography from '@material-ui/core/Typography';
import React from 'react';
const Page = ({ pages, headers }) => (React.createElement("table", null,
    React.createElement("thead", { className: "page-head" },
        React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("div", { className: "page-header-space" })))),
    React.createElement("tbody", null,
        React.createElement("tr", null,
            React.createElement("td", null, pages.map((child, childIndex) => (React.createElement("div", { className: "page" },
                React.createElement(Typography, { variant: "h4", gutterBottom: true }, headers[childIndex]),
                child || null)))))),
    React.createElement("tfoot", { className: "page-foot" },
        React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("div", { className: "page-footer-space" }))))));
export default Page;
