import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React from 'react';
import { calculatePercentOf } from "../../../entities/forecast";
const ForecastSalesStream = ({ revenueStream, forecast, }) => {
    const revenueCols = [];
    const cogsCols = [];
    const grossProfitCols = [];
    revenueStream.months.forEach((month, monthId) => {
        const monthString = monthId;
        revenueCols.push(React.createElement(TableCell, { key: `${monthString}-revenue`, align: "right" }, month.revenue.toString()));
        cogsCols.push(React.createElement(TableCell, { key: `${monthString}-cogs`, align: "right" }, month.cogs.toString()));
        grossProfitCols.push(React.createElement(TableCell, { key: `${monthString}-grossProfit`, align: "right" }, month.grossProfit?.toString?.()));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { className: "forecast-table__row--start" },
            React.createElement(TableCell, { component: "th", scope: "row" }, "Revenue"),
            React.createElement(TableCell, null, revenueStream.name),
            revenueCols,
            React.createElement(TableCell, { align: "right" }, revenueStream.totalRevenue.toString()),
            React.createElement(TableCell, { align: "right" },
                React.createElement("b", null, calculatePercentOf(revenueStream.totalRevenue, forecast.totals.revenue))),
            React.createElement(TableCell, null)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { component: "th" }, "COGS"),
            React.createElement(TableCell, { align: "right" }, revenueStream.cogsPercent &&
                `${revenueStream.cogsPercent}%`),
            cogsCols,
            React.createElement(TableCell, { align: "right" }, revenueStream.totalCogs.toString()),
            React.createElement(TableCell, { align: "right" }, calculatePercentOf(revenueStream.totalCogs, forecast.totals.revenue)),
            React.createElement(TableCell, null)),
        React.createElement(TableRow, { className: "forecast-table__row--end" },
            React.createElement(TableCell, { component: "th" }, "Gross Profit"),
            React.createElement(TableCell, null),
            grossProfitCols,
            React.createElement(TableCell, { align: "right" }, revenueStream.totalGrossProfit.toString()),
            React.createElement(TableCell, { align: "right" },
                React.createElement("b", null, calculatePercentOf(revenueStream.totalGrossProfit, forecast.totals.revenue))),
            React.createElement(TableCell, null))));
};
const ReportSalesTable = ({ forecast, }) => (React.createElement(TableContainer, { className: "forecast-table" },
    React.createElement(Table, { size: "small" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, null, "Month"),
                React.createElement(TableCell, null),
                forecast.months.map((month) => (React.createElement(TableCell, { align: "right", key: month.toString() }, formatDate(month, 'LLL-yy')))),
                React.createElement(TableCell, { component: "th", align: "right" }, "Total"),
                React.createElement(TableCell, { component: "th", align: "right" }, "%"),
                React.createElement(TableCell, null))),
        React.createElement(TableBody, null,
            React.createElement(TableRow, { className: "forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }, "Total Revenue"),
                React.createElement(TableCell, null),
                forecast.monthlyAccounts.map((month, index) => (React.createElement(TableCell, { align: "right", key: `revenue-${index}` }, month.revenue.toString()))),
                React.createElement(TableCell, { align: "right" }, forecast.totals.revenue.toString()),
                React.createElement(TableCell, { align: "right" },
                    React.createElement("b", null, calculatePercentOf(forecast.totals.revenue, forecast.totals.revenue))),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, { className: "forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }, "Total COGS"),
                React.createElement(TableCell, null),
                forecast.monthlyAccounts.map((month, index) => (React.createElement(TableCell, { align: "right", key: `cogs-${index}` }, month.cogs.toString()))),
                React.createElement(TableCell, { align: "right" }, forecast.totals.cogs.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.totals.cogs, forecast.totals.revenue)),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, { className: "forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }, "Total Gross Profit"),
                React.createElement(TableCell, null),
                forecast.monthlyAccounts.map((month, index) => (React.createElement(TableCell, { align: "right", key: `grossProfit-${index}` }, month.grossProfit.toString()))),
                React.createElement(TableCell, { align: "right" }, forecast.totals.grossProfit.toString()),
                React.createElement(TableCell, { align: "right" },
                    React.createElement("b", null, forecast.totals.grossProfitPercent)),
                React.createElement(TableCell, null)),
            forecast.revenueStreams.map((revenueStream, index) => (React.createElement(ForecastSalesStream, { key: revenueStream.name, revenueStream: revenueStream, forecast: forecast })))))));
export default ReportSalesTable;
