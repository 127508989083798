import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const ERP_TYPES = [
    {
        value: '',
        label: 'None',
    },
    {
        value: 'SAP4.4',
        label: 'SAP 4.4',
    },
];
const RESELLER_VALIDATION = yup.object().shape({
    email: yup
        .string()
        .min(1, 'Please enter an email')
        .max(255, 'Please use a shorter email')
        .required('Please enter an email'),
    name: yup
        .string()
        .min(1, 'Please enter a name')
        .max(100, 'Please use a shorter name')
        .required('Please enter a name'),
    initialPassword: yup
        .string()
        .min(8, 'Password must contain at least 8 characters')
        .max(50, 'Please use a shorter password')
        .required('Please enter an initial password for the reseller account'),
});
const VALIDATION = yup.object().shape({
    reseller: RESELLER_VALIDATION.required(),
});
async function createResellerApi(buslane, validation, { formik, values }) {
    let body;
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        body = await buslane
            .post('api/rest/reseller', { json: input.reseller })
            .json();
        if (body.success) {
            return body.data.id;
        }
    }
    catch (e) { }
    if (!body.success && body.error) {
        const error = new Error('Failed to create coach');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.fields = body.error;
        throw error;
    }
    throw new Error('Failed to create coach');
}
const useCreateResellerApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => createResellerApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
        erpOptions: ERP_TYPES,
    };
};
export default useCreateResellerApi;
