import camelcase from 'camelcase';
import Papa from 'papaparse';
const HEADER_LOOKUP = {
    accountCategory: 'category',
    dr: 'debit',
    cr: 'credit',
};
const RECORD_SHAPE = {
    account: true,
    category: true,
    openingBalance: true,
    credit: true,
    debit: true,
};
function transformHeader(header) {
    // Normalize the headers
    const cleanHeader = camelcase(header);
    // Search for alternative names
    return HEADER_LOOKUP[cleanHeader] ?? cleanHeader;
}
async function processRecordFile(erpType, file) {
    return new Promise((resolve, reject) => {
        const errors = [];
        const normalizedRecords = [];
        Papa.parse(file, {
            worker: true,
            header: true,
            skipEmptyLines: true,
            complete(result) {
                if (errors.length) {
                    reject(new Error('File parsing failed'));
                    return;
                }
                resolve(normalizedRecords);
            },
            step(results, parser) {
                try {
                    if (results.errors?.length) {
                        errors.push(...results.errors);
                        return;
                    }
                    if (!results.data) {
                        return;
                    }
                    // Normalize the record then push it to the normalized records list
                    const normalizedRecord = {};
                    Object.entries(results.data).forEach(([key, value]) => {
                        const normalizedKey = transformHeader(key);
                        if (!RECORD_SHAPE[normalizedKey]) {
                            return;
                        }
                        normalizedRecord[transformHeader(key)] = value;
                    });
                    // Required fields
                    const hasRequiredFields = [
                        'account',
                        'category',
                    ].every((field) => Boolean(normalizedRecord[field]));
                    if (!hasRequiredFields) {
                        errors.push({
                            code: 'MissingFields',
                            message: 'Missing fields',
                            row: 0,
                            type: 'FieldMismatch',
                        });
                        return;
                    }
                    // Default values;
                    normalizedRecord.openingBalance =
                        normalizedRecord.openingBalance ?? '';
                    normalizedRecord.credit = normalizedRecord.credit ?? '';
                    normalizedRecord.debit = normalizedRecord.debit ?? '';
                    normalizedRecords.push(normalizedRecord);
                }
                catch (e) {
                    errors.push({
                        code: 'FieldError',
                        message: 'Field error',
                        row: 0,
                        type: 'FieldError',
                    });
                }
            },
            error(error) {
                reject(error);
            },
        });
    });
}
export default processRecordFile;
