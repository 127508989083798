import { useMutation } from 'butler';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useCopyForecastApi from '../../../api/useCopyForecastApi';
import ForecastGrowthComponent from './ForecastGrowthComponent';
import { useForecast } from "../components/forecastProvider";
const ForecastGrowthForm = ({ formikProps, }) => {
    const forecast = useForecast();
    const history = useHistory();
    const forecastApi = useCopyForecastApi();
    const [mutate] = useMutation(forecastApi.handleRequest);
    const onCopyForecast = useCallback(async () => {
        formikProps.setSubmitting(true);
        try {
            await forecast.validateWith(forecastApi.validation);
        }
        catch (e) {
            return;
        }
        try {
            const forecastId = await mutate({
                formik: formikProps,
                values: forecast.values,
            });
            if (forecastId) {
                history.push(`/forecast/${forecastId}`);
            }
            else {
                formikProps.setSubmitting(false);
            }
        }
        catch (e) {
            formikProps.setSubmitting(false);
        }
    }, [mutate, formikProps]);
    return (React.createElement(ForecastGrowthComponent, { formikProps: formikProps, onCopyForecast: onCopyForecast }));
};
export default ForecastGrowthForm;
