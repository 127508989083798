import TextField from '@material-ui/core/TextField/TextField';
import { DatePicker } from '@material-ui/pickers';
import { connect } from 'formik';
import React from 'react';
import useForecastField from './useForecastField';
const ForecastDateField = ({ formik, name, label, disabled, ...rest }) => {
    const { value, hasError, helperText, inputProps, } = useForecastField(formik, name, rest.helperText, rest.value, 'updateNumericField');
    return (React.createElement(DatePicker, { name: name, label: label, value: value, disabled: formik.isSubmitting || disabled, ...inputProps, ...rest, onChange: (val) => {
            inputProps.onChange({ target: { value: val } });
        }, onClose: () => {
            inputProps.onBlur();
        }, onAccept: (val) => {
            inputProps.onChange({ target: { value: val } });
            setTimeout(() => inputProps.onBlur(), 2);
        }, renderInput: (props) => {
            return (React.createElement(TextField, { ...props, onBlur: () => {
                    inputProps.onBlur();
                }, helperText: helperText, error: hasError, variant: "outlined", margin: "dense", fullWidth: true }));
        } }));
};
// @ts-ignore
export default connect(ForecastDateField);
