import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import PrivacyComponent from './PrivacyComponent';
import PrimaryNavListComponent from "../components/PrimaryNavListComponent";
import Dashboard from 'app/components/DashboardComponent';
import { GatekeeperContext } from "../gatekeeper/Gatekeeper";
import { useAbility } from '@casl/react';
const PrivacyPage = () => {
    const ability = useAbility(GatekeeperContext);
    const isAuthenticated = useMemo(() => ability.can('website:Authenticated', undefined), [ability]);
    if (isAuthenticated) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Privacy")),
            React.createElement(Box, { width: "100%", maxWidth: "md", padding: 4 },
                React.createElement(PrivacyComponent, null))));
    }
    return (React.createElement(Box, { display: "flex", width: "100%", justifyContent: "center", alignItems: "center" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Numereyes - Privacy")),
        React.createElement(Box, { width: "100%", maxWidth: "1240px", padding: 4 },
            React.createElement(PrivacyComponent, null))));
};
export default PrivacyPage;
