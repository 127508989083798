import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import ReportGrowthLersTable from './ReportGrowthLersTable';
import ReportGrowthPositionTable from './ReportGrowthPositionTable';
import ReportGrowthTable from './ReportGrowthTable';
const ReportGrowth = ({ forecast }) => (React.createElement(Box, { width: "100%" },
    React.createElement(Box, { width: "100%", display: "flex", justifyContent: "space-between", style: {
            pageBreakInside: 'avoid',
        } },
        React.createElement(Box, { flexGrow: 1 },
            React.createElement(ReportGrowthTable, { forecast: forecast.values })),
        React.createElement(Box, { display: "flex", paddingX: 2, justifyContent: "center", height: "auto", alignSelf: "stretch" },
            React.createElement(Divider, { orientation: "vertical", flexItem: true })),
        React.createElement(Box, { width: "35%" },
            React.createElement(ReportGrowthPositionTable, { forecast: forecast }),
            React.createElement(ReportGrowthLersTable, { forecast: forecast })))));
export default ReportGrowth;
