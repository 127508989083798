import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { Link as RouterLink, } from 'react-router-dom';
const NavListItemComponent = (props) => {
    const { to, children, tooltip, ...rest } = props;
    const renderLink = React.useMemo(() => React.forwardRef((itemProps, ref) => (React.createElement(RouterLink, { to: to, ref: ref, ...itemProps }))), [to]);
    return (React.createElement(Tooltip, { title: tooltip || '', placement: "right" },
        React.createElement(ListItem, { ...rest, component: renderLink }, children)));
};
export default NavListItemComponent;
