import Box from '@material-ui/core/Box';
import React from 'react';
import ForecastSalesGraph from '../../sales/ForecastSalesGraph';
import ReportSalesTable from './ReportSalesTable';
const ReportSales = ({ forecast }) => {
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Box, { className: "forecast-report__graph", width: "100%", minHeight: 480 },
            React.createElement(ForecastSalesGraph, { forecast: forecast.values, noAnimate: true })),
        React.createElement(Box, { width: "100%", style: {
                pageBreakInside: 'avoid',
            } },
            React.createElement(ReportSalesTable, { forecast: forecast.values }))));
};
export default ReportSales;
