import currency from 'currency.js';
import Money from './money';
export default class MoneyHundred extends Money {
    constructor(value, currencyCode) {
        super(value, currencyCode);
        const iso = MoneyHundred.getIso(this.currency);
        // Because we have cents, might need to round larger
        const roundFrom = 100 ** (iso.precision || 1);
        this.value = currency(Math.round(this.value.intValue / roundFrom) * roundFrom, { ...iso, fromCents: true });
        this.amount = this.value.intValue;
        this.currency = iso.currency;
        this.unit = iso.unit;
    }
    add(money) {
        return new MoneyHundred(super.add(money));
    }
    subtract(money) {
        return new MoneyHundred(super.subtract(money));
    }
    multiply(value) {
        return new MoneyHundred(super.multiply(value));
    }
    divide(value) {
        return new MoneyHundred(super.divide(value));
    }
    allocate(ratios) {
        return super.allocate(ratios).map((value) => new MoneyHundred(value));
    }
    distribute(between) {
        return super
            .distribute(between)
            .map((value) => new MoneyHundred(value, this.currency));
    }
    /**
     * X of the current currency to one of the target currency
     * @param ratio X of the current currency to one of the target currency
     * @param currencyCode
     */
    convert(ratio, currencyCode) {
        return new MoneyHundred(super.convert(ratio, currencyCode));
    }
    format(withSymbol = true) {
        if (!this.value) {
            return '';
        }
        if (withSymbol) {
            return currency(this.value, {
                precision: 0,
                symbol: this.getSymbol(),
            }).format();
        }
        return currency(this.value, { precision: 0, symbol: '' }).format();
    }
}
