import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsIcon from '@material-ui/icons/Settings';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useCoreState } from '../../CoreState';
import { Can } from '../gatekeeper/Gatekeeper';
import LogoutListItemComponent from '../logout/LogoutListItemComponent';
import AppIcon from './AppIcon';
import AppLogo from './AppLogo';
import NavListItemComponent from './NavListItemComponent';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer__paper: {
        position: 'fixed',
        whiteSpace: 'nowrap',
        overflowY: 'visible',
        width: theme.spacing(9) + 1,
        transition: theme.transitions.create(['width', 'box-shadow'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& .draw-container': {
            display: 'flex',
            overflowY: 'auto',
            overflowX: 'hidden',
            flexDirection: 'column',
            flexGrow: 1,
        },
        '&.draw--open': {
            width: drawerWidth,
            boxShadow: theme.shadows[3],
        },
    },
    'draw__control-container': {
        position: 'absolute',
        top: 10,
        right: -26,
        padding: 8,
        borderRadius: '50%',
        cursor: 'pointer',
    },
    'draw__control-button': {
        width: 24,
        height: 24,
        padding: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
        '& svg': {
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.short,
            }),
        },
        '&.draw--open svg': {
            transform: 'rotate(-180deg)',
        },
    },
    drawer__logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        padding: '16px 8px',
        minHeight: 56,
        marginLeft: 0,
        transition: theme.transitions.create(['margin-left'], {
            duration: theme.transitions.duration.short,
        }),
        '&.draw--open': {
            marginLeft: -72,
        },
        '& img:last-child': {
            marginLeft: 24,
            width: drawerWidth - theme.spacing(4) - 1,
        },
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
        flexDirection: 'column',
        paddingLeft: theme.spacing(9) + 1,
        '&.draw--open': {
            paddingLeft: drawerWidth,
        },
        transition: theme.transitions.create(['padding-left'], {
            duration: theme.transitions.duration.short,
        }),
    },
    footer: {
        marginTop: 'auto',
    },
}));
export default function Dashboard(props) {
    const classes = useStyles();
    const [coreState, setCoreState] = useCoreState();
    const handleDrawOpen = useCallback(() => {
        setCoreState({
            isDrawOpen: !coreState.isDrawOpen,
        });
    }, [coreState, setCoreState]);
    const { mainListItems, secondaryListItems, children } = props;
    const { isDrawOpen } = coreState;
    return (React.createElement("div", { className: classes.root },
        React.createElement(CssBaseline, null),
        React.createElement(Drawer, { open: true, variant: "permanent", classes: {
                paper: clsx({
                    [classes.drawer__paper]: true,
                    'draw--open': isDrawOpen,
                }),
            } },
            React.createElement(Box, { position: "relative" },
                React.createElement(Box, { className: classes['draw__control-container'], onClick: handleDrawOpen },
                    React.createElement(IconButton, { className: clsx({
                            [classes['draw__control-button']]: true,
                            'draw--open': isDrawOpen,
                        }), onClick: handleDrawOpen },
                        React.createElement(ChevronRightIcon, null))),
                React.createElement(Link, { to: "/", className: clsx({
                        [classes.drawer__logo]: true,
                        'draw--open': isDrawOpen,
                    }) },
                    React.createElement(AppIcon, null),
                    React.createElement(AppLogo, null))),
            React.createElement("div", { className: "draw-container" },
                React.createElement(Divider, null),
                React.createElement(List, null, mainListItems),
                React.createElement(Divider, null),
                React.createElement(List, null, secondaryListItems),
                React.createElement(Box, { flexGrow: 1 }),
                React.createElement(Divider, null),
                React.createElement(NavListItemComponent, { button: true, to: "/privacy-policy", tooltip: "Privacy Policy" },
                    React.createElement(ListItemIcon, null),
                    React.createElement(ListItemText, { primary: "Privacy Policy" })),
                React.createElement(Can, { I: "website:Authenticated" },
                    React.createElement(NavListItemComponent, { button: true, to: "/account", tooltip: "My Account" },
                        React.createElement(ListItemIcon, null,
                            React.createElement(SettingsIcon, null)),
                        React.createElement(ListItemText, { primary: "My Account" })),
                    React.createElement(Divider, null),
                    React.createElement(LogoutListItemComponent, null)))),
        React.createElement("main", { className: clsx({
                [classes.content]: true,
                'draw--open': isDrawOpen,
            }) },
            React.createElement(Box, { display: "flex", flexGrow: 1 }, children))));
}
