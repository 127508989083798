import Box from '@material-ui/core/Box';
import React from 'react';
import ForecastProfitGraph from '../../profit/ForecastProfitGraph';
import ReportProfitTable from './ReportProfitTable';
const ReportProfit = ({ forecast }) => {
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Box, { className: "forecast-report__graph", width: "100%", minHeight: 480 },
            React.createElement(ForecastProfitGraph, { forecast: forecast.values, noAnimate: true })),
        React.createElement(Box, { width: "100%", style: {
                pageBreakInside: 'avoid',
            } },
            React.createElement(ReportProfitTable, { forecast: forecast.values }))));
};
export default ReportProfit;
