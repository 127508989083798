import createTheme from '@material-ui/core/styles/createTheme';
import { theme } from '../../app';
const TableListTheme = createTheme({
    overrides: {
        // @ts-ignore
        MUIDataTableHeadRow: {
            root: {
                '& th': {
                    whiteSpace: 'nowrap',
                },
            },
        },
        // @ts-ignore
        MUIDataTableBodyRow: {
            root: {
                '& td:first-child': {
                    width: '100%',
                    whiteSpace: 'normal',
                },
                '& td': {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MUIDataTableToolbar: {
            root: {
                paddingLeft: 16,
                paddingRight: 16,
            },
            actions: {
                display: 'none',
            },
        },
    },
}, theme);
export default TableListTheme;
