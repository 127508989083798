/* eslint-disable no-param-reassign */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React from 'react';
import FinanceCell from '../../../components/FinanceTableCell';
import { calculatePercentOf } from "../../../entities/forecast";
const ReportGrowthTable = ({ forecast }) => (React.createElement(TableContainer, { className: "forecast-table" },
    React.createElement(Table, { size: "small", padding: "default" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, { className: "forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }),
                React.createElement(TableCell, { className: "forecast-card__prior-period", component: "th", align: "right" }, "Prior Period"),
                React.createElement(TableCell, { component: "th" }),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", colSpan: 3, align: "center" },
                    "Forecast from",
                    ' ',
                    formatDate(forecast.firstMonth, 'LLL-yy')),
                React.createElement(TableCell, { component: "th", colSpan: 2, align: "center" }, "Changes")),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { component: "th" }),
                React.createElement(TableCell, { className: "forecast-card__prior-period", component: "th", align: "right" }, "Rolling 12"),
                React.createElement(TableCell, { component: "th", align: "center" }, "Growth"),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "Average Month"),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "12 Months"),
                React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "% of Rev."),
                React.createElement(TableCell, { component: "th", align: "center" }, "Monthly $"),
                React.createElement(TableCell, { component: "th", align: "center" }, "Monthly %"))),
        React.createElement(TableBody, null,
            React.createElement(TableRow, { className: "forecast-table__row--start forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Revenue"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.revenue?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.revenue.subtract(forecast.rolling.revenue), forecast.rolling.revenue)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.revenue?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.revenue, compare: forecast.totals.revenue, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.revenuePercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.revenue }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.revenuePercent })),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "COGS"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.cogs?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.cogs.subtract(forecast.rolling.cogs), forecast.rolling.cogs)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.cogs?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.cogs?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.cogsPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.cogs }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.cogsPercent })),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Gross Margin"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.grossProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.grossProfit.subtract(forecast.rolling.grossProfit), forecast.rolling.grossProfit)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.grossProfit?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.grossProfit, compare: forecast.totals.grossProfit, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.grossProfitPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Direct Labour"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.directLabour?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.directLabour.subtract(forecast.rolling.directLabour), forecast.rolling.directLabour)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.directLabour?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.directLabour?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.directLabourPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.directLabour }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.directLabourPercent })),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Contribution Profit"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.contributionProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.contributionProfit.subtract(forecast.rolling.contributionProfit), forecast.rolling.contributionProfit)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.contributionProfit?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.contributionProfit, compare: forecast.totals.contributionProfit, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.contributionProfitPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Facilities"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.facilities?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.facilities.subtract(forecast.rolling.facilities), forecast.rolling.facilities)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.facilities?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.facilities?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.facilitiesPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.facilities }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.facilitiesPercent })),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Marketing and Sales"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.marketingSales?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.marketingSales.subtract(forecast.rolling.marketingSales), forecast.rolling.marketingSales)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.marketingSales?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.marketingSales?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.marketingSalesPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.marketingSales }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.marketingSalesPercent })),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Salaries"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.salaries?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.salaries.subtract(forecast.rolling.salaries), forecast.rolling.salaries)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.salaries?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.salaries?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.salariesPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.salaries }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.salariesPercent })),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Payroll and Benefits"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.payrollBenefits?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.payrollBenefits.subtract(forecast.rolling.payrollBenefits), forecast.rolling.payrollBenefits)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.payrollBenefits?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.payrollBenefits?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.payrollBenefitsPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.payrollBenefits }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.payrollBenefitsPercent })),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Remaining OpEx"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.remainingOpEx?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.remainingOpEx.subtract(forecast.rolling.remainingOpEx), forecast.rolling.remainingOpEx)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.remainingOpEx?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.remainingOpEx?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.remainingOpExPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.remainingOpEx }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.remainingOpExPercent })),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Operating Profit"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.operatingProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.operatingProfit.subtract(forecast.rolling.operatingProfit), forecast.rolling.operatingProfit)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.operatingProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.operatingProfit?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.operatingProfitPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null)),
            React.createElement(TableRow, null,
                React.createElement(TableCell, { align: "left" }, "Other Income / Expenses"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.otherIncomeExpenses?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.otherIncomeExpenses.subtract(forecast.rolling.otherIncomeExpenses), forecast.rolling.otherIncomeExpenses)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.otherIncomeExpenses?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.otherIncomeExpenses?.toString()),
                React.createElement(TableCell, { align: "right" }, forecast.growth.otherIncomeExpensesPercent),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.otherIncomeExpenses }),
                React.createElement(FinanceCell, { value: forecast.monthlyChange.otherIncomeExpensesPercent })),
            React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                React.createElement(TableCell, { align: "left" }, "Net Profit Before Tax"),
                React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rolling.netProfitBeforeTax?.toString()),
                React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.growth.netProfitBeforeTax.subtract(forecast.rolling.netProfitBeforeTax), forecast.rolling.netProfitBeforeTax)),
                React.createElement(TableCell, { align: "right" }, forecast.growthMonthly.netProfitBeforeTax?.toString()),
                React.createElement(FinanceCell, { value: forecast.growth.netProfitBeforeTax, compare: forecast.totals.netProfitBeforeTax, align: "right" }),
                React.createElement(TableCell, { align: "right" }, forecast.growth.netProfitBeforeTaxPercent),
                React.createElement(TableCell, null),
                React.createElement(TableCell, null))))));
export default ReportGrowthTable;
