import TextField from '@material-ui/core/TextField';
import { connect } from 'formik';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Money } from '@local/website-stdlib';
import useForecastField from "./useForecastField";
function MoneyInput(props) {
    const { inputRef, onChange, value, ...other } = props;
    const [isNegative, setNegative] = useState(false);
    const literalSymbol = value?.getSymbol?.() || '$';
    let literalValue = value?.toNumber?.();
    if (!other.disabled) {
        if (!literalValue) {
            if (isNegative) {
                literalValue = '-';
            }
            else {
                literalValue = '';
            }
        }
    }
    return (React.createElement(NumberFormat, { ...other, value: literalValue, getInputRef: inputRef, onChange: (event) => {
            let nextValueString = String(event.target.value)
                .replace(literalSymbol, '')
                .replace(/,/g, '');
            const currentValueString = String(literalValue);
            // There is a bug with backspacing negative numbers, -176000 becomes 17600
            // Another way to look at it:
            // -176000
            //  17600
            // There for IF the current value is negative, AND the next value matches the inside
            // of the current value, invert the current values sign.
            // Then reset the selection to the first character
            if (currentValueString.startsWith('-') && currentValueString.slice(1, -1) === nextValueString) {
                nextValueString = currentValueString.slice(1);
                try {
                    event.target.setSelectionRange(0, 0);
                }
                catch (e) {
                    // Silence is bliss
                }
            }
            let moneyValue;
            if (nextValueString === '-') {
                moneyValue = new Money(0);
            }
            else {
                moneyValue = new Money(String(nextValueString || 0));
            }
            if (nextValueString.startsWith('-')) {
                setNegative(true);
            }
            else {
                setNegative(false);
            }
            if (moneyValue.equals(value)) {
                return;
            }
            onChange({
                target: {
                    name: props.name,
                    value: moneyValue,
                },
            });
        }, thousandSeparator: true, isNumericString: true, allowNegative: true, prefix: literalSymbol }));
}
const ForecastFieldMoney = ({ formik, name, label, disabled, onChange, ...rest }) => {
    const { value, hasError, helperText, inputProps, } = useForecastField(formik, name, rest.helperText, rest.value, 'updateNumericField');
    let handleChange = inputProps.onChange;
    if (onChange) {
        handleChange = (event) => {
            onChange({
                // @ts-ignore
                target: {
                    value: event.target.value,
                    name,
                },
            });
            inputProps.onChange(event);
        };
    }
    return (React.createElement(TextField, { name: name, label: label, value: value, helperText: helperText, error: hasError, disabled: formik.isSubmitting || disabled, type: "text", InputProps: {
            inputComponent: MoneyInput,
            inputProps: {
                className: 'money-input',
            },
        }, ...inputProps, ...rest, onChange: handleChange }));
};
// @ts-ignore
export default connect(ForecastFieldMoney);
