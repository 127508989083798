import Box from '@material-ui/core/Box';
import formatDate from 'date-fns/format';
import React, { useMemo } from 'react';
import FinancialGraph from '../FinancialGraph';
const ForecastCashGraph = ({ forecast, noAnimate }) => {
    const labels = useMemo(() => [...forecast.months.map((month) => formatDate(month, 'LLL-yy'))], [forecast]);
    const series = [
        {
            dash: 8,
            color: '#F68B1F',
            name: 'Cash on Hand',
            type: 'line',
            data: [],
        },
        {
            dash: 8,
            color: '#1e88e5',
            name: 'Core Cash Requirements',
            type: 'line',
            data: [],
        },
        {
            dash: 8,
            color: '#8e24aa',
            name: 'Receivables',
            type: 'line',
            data: [],
        },
        {
            dash: 4,
            color: '#d81b60',
            name: 'Payables',
            type: 'line',
            data: [],
        },
    ];
    forecast.monthlyAccounts.forEach((monthlyAccount) => {
        series[0].data.push(monthlyAccount.closingBalance?.toNumber?.() || 0);
        series[1].data.push(monthlyAccount.coreCashRequirements?.toNumber?.() || 0);
        series[2].data.push(monthlyAccount.debtorsValue?.toNumber?.() || 0);
        series[3].data.push(monthlyAccount.creditorsValue?.toNumber?.() || 0);
    });
    return (React.createElement(Box, { width: "94%", marginLeft: "6%", height: 480, paddingX: 2 },
        React.createElement(FinancialGraph, { labels: labels, data: series, type: "area", noAnimate: noAnimate })));
};
export default ForecastCashGraph;
