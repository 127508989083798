import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import { connect } from 'formik';
import React from 'react';
import ForecastFieldMoney from "./ForecastFieldMoney";
const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
        '& label': {
            fontWeight: 'bold',
        },
    },
}));
const RollingForecastField = ({ formik, bold, percentage, average, ...rest }) => {
    const classes = useStyles();
    const typographyClass = clsx({ [classes.bold]: bold });
    return (React.createElement(Grid, { item: true, xs: 12, container: true },
        React.createElement(Grid, { item: true, xs: 5, sm: 5 },
            React.createElement(ForecastFieldMoney, { className: typographyClass, name: rest.name, label: rest.label, disabled: rest.disabled, fullWidth: true, variant: "outlined", margin: "dense" })),
        React.createElement(Grid, { item: true, xs: 4, sm: 4 },
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", width: "100%", height: "100%" },
                React.createElement(Typography, { variant: "body2", className: typographyClass }, average?.toString()))),
        React.createElement(Grid, { item: true, xs: 3, sm: 3 },
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", width: "100%", height: "100%" },
                React.createElement(Typography, { variant: "body2", className: typographyClass }, percentage)))));
};
export default connect(RollingForecastField);
