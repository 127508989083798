import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'butler';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useCreateResellerApi from '../../api/useCreateResellerApi';
import FormTextField from '../../form/FormTextField';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import PositiveButton from '../components/PositiveButton';
const CreateResellerComponent = () => {
    const history = useHistory();
    const createResellerApi = useCreateResellerApi();
    const [mutate] = useMutation(createResellerApi.handleRequest);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            const resellerId = await mutate({
                values,
                formik: formikHelpers,
            }, {
                onError: (e) => {
                    console.log(e);
                    if (!e.fields) {
                        formikHelpers.setFieldError('reseller.email', 'There was an error creating the coach');
                        return;
                    }
                    if (e.fields.email) {
                        formikHelpers.setFieldError('reseller.email', 'This email has already been used');
                    }
                },
            });
            if (resellerId) {
                history.push(`/reseller/${resellerId}`);
            }
        }
        catch (e) {
            console.warn(e);
        }
    }, [mutate, history]);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Formik, { validationSchema: createResellerApi.validation, onSubmit: onSubmit, initialValues: {} }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST", autoComplete: "off" },
            React.createElement(Card, null,
                React.createElement(CardHeader, { title: "Create Coach" }),
                React.createElement(CardContent, null,
                    React.createElement(Grid, { container: true, spacing: 6 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "reseller.email", label: "Email", variant: "outlined" })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "reseller.name", label: "Name", variant: "outlined" })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "reseller.initialPassword", label: "Initial Password", variant: "outlined" })))),
                React.createElement(CardActions, null,
                    React.createElement(Box, { padding: 1, width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" },
                        React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Create Coach")))))))));
};
export default CreateResellerComponent;
