import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Form } from 'formik';
import React from 'react';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import PositiveButton from '../../components/PositiveButton';
import ForecastSubHeader from '../ForecastSubHeader';
import ForecastGrowthLersTable from './ForecastGrowthLersTable';
import ForecastGrowthPositionTable from './ForecastGrowthPositionTable';
import ForecastGrowthTable from './ForecastGrowthTable';
import { useForecast } from "../components/forecastProvider";
// <ForecastProfitGraph profit={profit} />
const ForecastGrowthComponent = ({ formikProps, onCopyForecast, }) => {
    const forecast = useForecast();
    return (React.createElement(Form, { noValidate: true, autoComplete: "off", method: "POST" },
        React.createElement(Card, null,
            React.createElement(CardHeader, { className: "forecast-card__header", title: "Growth Levers", subheader: React.createElement(ForecastSubHeader, { forecast: forecast }), action: (React.createElement(ButtonWithSpinner, { type: "button", onClick: onCopyForecast, disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Copy Revised Forecast")) }),
            React.createElement(CardContent, null,
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, md: true, className: "flex-grow-2" },
                        React.createElement(ForecastGrowthTable, { forecast: forecast.values })),
                    React.createElement(Hidden, { mdDown: true },
                        React.createElement(Box, { display: "flex", paddingX: 2, justifyContent: "center", height: "auto", alignSelf: "stretch" },
                            React.createElement(Divider, { orientation: "vertical", flexItem: true }))),
                    React.createElement(Grid, { item: true, xs: 12, md: true },
                        React.createElement(ForecastGrowthPositionTable, { forecast: forecast, formikProps: formikProps }),
                        React.createElement(ForecastGrowthLersTable, { forecast: forecast })))),
            React.createElement(CardActions, null,
                React.createElement(Box, { padding: 1, flexGrow: 1, display: "flex", justifyContent: "flex-end", position: "relative" },
                    React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Save"))))));
};
export default ForecastGrowthComponent;
