import { useAbility } from '@casl/react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CreateCompanyIcon from '@material-ui/icons/Add';
import CreateResellerIcon from '@material-ui/icons/PersonAdd';
import HomeIcon from '@material-ui/icons/Home';
import CompaniesIcon from '@material-ui/icons/ViewList';
import ResellersIcon from '@material-ui/icons/People';
import React from 'react';
import NavListItemComponent from './NavListItemComponent';
import { GatekeeperContext } from "../gatekeeper/Gatekeeper";
const PrimaryNavListComponent = () => {
    const ability = useAbility(GatekeeperContext);
    return (React.createElement("div", null,
        React.createElement(NavListItemComponent, { button: true, to: "/", tooltip: "Home" },
            React.createElement(ListItemIcon, null,
                React.createElement(HomeIcon, null)),
            React.createElement(ListItemText, { primary: "Home" })),
        React.createElement(NavListItemComponent, { button: true, to: "/companies", tooltip: "Companies" },
            React.createElement(ListItemIcon, null,
                React.createElement(CompaniesIcon, null)),
            React.createElement(ListItemText, { primary: "Companies" })),
        React.createElement(NavListItemComponent, { button: true, to: "/company/create", tooltip: "Create Company" },
            React.createElement(ListItemIcon, null,
                React.createElement(CreateCompanyIcon, null)),
            React.createElement(ListItemText, { primary: "Create Company" })),
        ability.can("website:Administrator", undefined) ? (React.createElement(React.Fragment, null,
            React.createElement(NavListItemComponent, { button: true, to: "/resellers", tooltip: "Resellers" },
                React.createElement(ListItemIcon, null,
                    React.createElement(ResellersIcon, null)),
                React.createElement(ListItemText, { primary: "Coaches" })),
            React.createElement(NavListItemComponent, { button: true, to: "/reseller/create", tooltip: "Create Coach" },
                React.createElement(ListItemIcon, null,
                    React.createElement(CreateResellerIcon, null)),
                React.createElement(ListItemText, { primary: "Create Coach" })))) : null));
};
export default PrimaryNavListComponent;
