import { MoneyHundred } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
export function transformForecast(forecast) {
    return {
        ...forecast,
        createdAt: new Date(forecast.createdAt),
        updatedAt: new Date(forecast.updatedAt ?? forecast.createdAt),
        firstMonth: new Date(forecast.firstMonth),
        lastMonthOfData: new Date(forecast.lastMonthOfData),
        gstPeriodEndedAt: new Date(forecast.gstPeriodEndedAt),
        cashOnHand: new MoneyHundred(forecast.cashOnHand ?? 0),
        receivables: new MoneyHundred(forecast.receivables ?? 0),
        payables: new MoneyHundred(forecast.payables ?? 0),
        inventoryValue: new MoneyHundred(forecast.inventoryValue ?? 0),
        rolling: {
            revenue: new MoneyHundred(forecast.rolling.revenue ?? 0),
            cogs: new MoneyHundred(forecast.rolling.cogs ?? 0),
            directLabour: new MoneyHundred(forecast.rolling.directLabour ?? 0),
            facilities: new MoneyHundred(forecast.rolling.facilities ?? 0),
            marketingSales: new MoneyHundred(forecast.rolling.marketingSales ?? 0),
            salaries: new MoneyHundred(forecast.rolling.salaries ?? 0),
            payrollBenefits: new MoneyHundred(forecast.rolling.payrollBenefits ?? 0),
            remainingOpEx: new MoneyHundred(forecast.rolling.remainingOpEx ?? 0),
            otherIncomeExpenses: new MoneyHundred(forecast.rolling.otherIncomeExpenses ?? 0),
        },
        revenueStreams: forecast.revenueStreams.map((revenueStream) => ({
            ...revenueStream,
            months: revenueStream.months.map((month) => ({
                revenue: new MoneyHundred(month.revenue ?? 0),
                cogs: new MoneyHundred(month.cogs ?? 0),
            })),
        })),
        monthlyAccounts: forecast.monthlyAccounts.map((monthlyAccount) => ({
            ...monthlyAccount,
            revenue: new MoneyHundred(monthlyAccount.revenue ?? 0),
            cogs: new MoneyHundred(monthlyAccount.cogs ?? 0),
            directLabour: new MoneyHundred(monthlyAccount.directLabour ?? 0),
            facilities: new MoneyHundred(monthlyAccount.facilities ?? 0),
            marketingSales: new MoneyHundred(monthlyAccount.marketingSales ?? 0),
            salaries: new MoneyHundred(monthlyAccount.salaries ?? 0),
            payrollBenefits: new MoneyHundred(monthlyAccount.payrollBenefits ?? 0),
            remainingOpEx: new MoneyHundred(monthlyAccount.remainingOpEx ?? 0),
            otherIncomeExpenses: new MoneyHundred(monthlyAccount.otherIncomeExpenses ?? 0),
            additionalCashForInventory: new MoneyHundred(monthlyAccount.additionalCashForInventory ?? 0),
            additionalCashInOut: new MoneyHundred(monthlyAccount.additionalCashInOut ?? 0),
            inventoryValue: new MoneyHundred(monthlyAccount.inventoryValue ?? 0),
        })),
        monthlyChange: {
            ...forecast.monthlyChange,
            revenue: new MoneyHundred(forecast.monthlyChange.revenue ?? 0),
            cogs: new MoneyHundred(forecast.monthlyChange.cogs ?? 0),
            directLabour: new MoneyHundred(forecast.monthlyChange.directLabour ?? 0),
            facilities: new MoneyHundred(forecast.monthlyChange.facilities ?? 0),
            marketingSales: new MoneyHundred(forecast.monthlyChange.marketingSales ?? 0),
            salaries: new MoneyHundred(forecast.monthlyChange.salaries ?? 0),
            payrollBenefits: new MoneyHundred(forecast.monthlyChange.payrollBenefits ?? 0),
            remainingOpEx: new MoneyHundred(forecast.monthlyChange.remainingOpEx ?? 0),
            otherIncomeExpenses: new MoneyHundred(forecast.monthlyChange.otherIncomeExpenses ?? 0),
        },
    };
}
async function forecastApi(buslane, id) {
    const body = await buslane
        .get(`api/rest/forecasts/${id}`)
        .json();
    if (!body.success) {
        throw new Error('Forecast not found');
    }
    return body.data;
}
const useForecastApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, id) => {
        return forecastApi(buslane, id);
    }, [buslane]);
    return {
        handleRequest,
        transform: transformForecast,
    };
};
export default useForecastApi;
