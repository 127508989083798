import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCacheQuery } from 'butler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useRecordApi from '../../api/useRecordApi';
import ButtonLink from '../components/ButtonLink';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ErrorCard from '../errors/ErrorCard';
import UpdateRecordComponent from './UpdateRecordComponent';
const UpdateRecordPage = () => {
    const { id } = useParams();
    const recordApi1 = useRecordApi();
    const recordQuery = useCacheQuery(['record', id], recordApi1.handleRequest, recordApi1.transform, {
        retry: false,
    });
    if (recordQuery.isLoading) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Loading...")),
            React.createElement(Box, { width: "100%" }, "Loading...")));
    }
    const { record, company } = recordQuery.data ?? {};
    if (!record || !company) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Record not found")),
            React.createElement(ErrorCard, { title: "Record not found" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonLink, { to: "/companies", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "View Companies"))))));
    }
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Box, { display: "flex", width: "100%", justifyContent: "center", paddingTop: "8%" },
            React.createElement(Helmet, null,
                React.createElement("title", null, `Numereyes - ${company.name}`)),
            React.createElement(Box, { width: "100%", maxWidth: 480, padding: 4 },
                React.createElement(Typography, { variant: "h5", gutterBottom: true }, company.name),
                React.createElement(UpdateRecordComponent, { recordId: record.id, accountYear: record.accountYear, accountMonth: record.accountMonthName, companyId: company.id, erpType: company.erpType })))));
};
export default UpdateRecordPage;
