import { useBuslane } from 'buslane';
import { useCallback } from 'react';
export function transformCompany(company) {
    return {
        ...company,
        createdAt: new Date(company.createdAt),
        updatedAt: new Date(company.updatedAt ?? company.createdAt),
    };
}
async function companyApi(buslane, id) {
    const body = await buslane
        .get(`api/rest/companies/${id}`)
        .json();
    if (!body.success) {
        throw new Error('Company not found');
    }
    return body.data;
}
const useCompanyApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, id) => {
        return companyApi(buslane, id);
    }, [buslane]);
    return {
        handleRequest,
        transform: transformCompany,
    };
};
export default useCompanyApi;
