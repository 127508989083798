import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import FormTextField from './FormTextField';
const FormSelectFieldInner = ({ options, ...props }) => {
    return (
    // @ts-ignore
    React.createElement(TextField, { ...props, select: true }, options.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)))));
};
const FormSelectField = (props) => {
    // @ts-ignore
    return React.createElement(FormTextField, { ...props, as: FormSelectFieldInner });
};
FormSelectField.defaultProps = {};
// @ts-ignore
export default FormSelectField;
