import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React from 'react';
import ForecastFieldSimple from "../components/ForecastFieldSimple";
import ForecastFieldMoney from "../components/ForecastFieldMoney";
const ROW_CLASSES = {
    closingBalance: 'forecast-table__row--start forecast-table__row--end forecast-table__row--important',
};
const ForecastCashTable = ({ forecast, formikProps, }) => {
    const tableData = {
        debtorDays: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Debtor Days"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.debtorDays),
        ],
        creditorDays: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Creditor Days"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.creditorDays),
        ],
        inventoryDays: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Inventory Days"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.inventoryDays),
        ],
        debtorValue: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Debtor Value"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.debtorsValue?.toString()),
        ],
        creditorValue: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Creditor Value"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.creditorsValue?.toString()),
        ],
        inventoryValue: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Inventory Value"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.inventoryValue?.toString()),
        ],
        additionalCashInOut: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Additional Cash (+/-)"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
        ],
        additionalCashForInventory: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Additional Cash For inventory (+/-)"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
        ],
        closingBalance: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Closing Balance"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.closingBalance?.toString()),
        ],
    };
    forecast.monthlyAccounts.forEach((monthlyAccount, monthId) => {
        const monthString = monthId;
        tableData.debtorDays.push(React.createElement(TableCell, { key: `${monthString}-debtorDays` },
            React.createElement(ForecastFieldSimple, { name: `monthlyAccounts[${monthId}].debtorDays`, label: "", fullWidth: true, variant: "outlined", type: "number" })));
        tableData.creditorDays.push(React.createElement(TableCell, { key: `${monthString}-creditorDays` },
            React.createElement(ForecastFieldSimple, { name: `monthlyAccounts[${monthId}].creditorDays`, label: "", fullWidth: true, variant: "outlined", type: "number" })));
        tableData.inventoryDays.push(React.createElement(TableCell, { key: `${monthString}-inventoryDays` },
            React.createElement(ForecastFieldSimple, { name: `monthlyAccounts[${monthId}].inventoryDays`, label: "", fullWidth: true, variant: "outlined", type: "number" })));
        tableData.debtorValue.push(React.createElement(TableCell, { key: `${monthString}-debtorValue`, align: "right" }, monthlyAccount.debtorsValue?.toString()));
        tableData.creditorValue.push(React.createElement(TableCell, { key: `${monthString}-creditValue`, align: "right" }, monthlyAccount.creditorsValue?.toString()));
        tableData.inventoryValue.push(React.createElement(TableCell, { key: `${monthString}-inventoryValue`, align: "right" }, monthlyAccount.inventoryValue?.toString()));
        tableData.additionalCashInOut.push(React.createElement(TableCell, { key: `${monthString}-additionalCashInOut` },
            React.createElement(ForecastFieldMoney, { name: `monthlyAccounts[${monthId}].additionalCashInOut`, label: "", fullWidth: true, variant: "outlined" })));
        tableData.additionalCashForInventory.push(React.createElement(TableCell, { key: `${monthString}-additionalCashForInventory` },
            React.createElement(ForecastFieldMoney, { name: `monthlyAccounts[${monthId}].additionalCashForInventory`, label: "", fullWidth: true, variant: "outlined" })));
        tableData.closingBalance.push(React.createElement(TableCell, { key: `${monthString}-closingBalance`, align: "right" }, monthlyAccount.closingBalance?.toString()));
    });
    return (React.createElement(TableContainer, { className: "forecast-table" },
        React.createElement(Table, { size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                    React.createElement(TableCell, null, "Month"),
                    React.createElement(TableCell, { className: "forecast-card__prior-period" }, "Prior Period"),
                    forecast.months.map((month) => (React.createElement(TableCell, { align: "right", key: month.toString() }, formatDate(month, 'LLL-yy')))))),
            React.createElement(TableBody, null, Object.entries(tableData).map(([key, cols]) => (React.createElement(TableRow, { className: ROW_CLASSES[key], key: key }, cols)))))));
};
export default ForecastCashTable;
