import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const LOGIN_VALIDATION = yup.object().shape({
    email: yup
        .string()
        .email('Please enter your email')
        .lowercase()
        .required('Please enter your email'),
    password: yup.string().required('Please enter your password'),
});
async function loginApi(buslane, validation, { formik, values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const body = await buslane
            .post('api/rest/auth/login', { json: input })
            .json();
        if (!body.success) {
            throw new Error('Auth failure');
        }
        else {
            await buslane.emitAsync('authentication');
        }
    }
    catch (e) {
        formik.setFieldError('email', ' ');
        formik.setFieldError('password', 'Incorrect email or password');
        throw new Error('Incorrect email or password');
    }
}
const useLoginApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => loginApi(buslane, LOGIN_VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: LOGIN_VALIDATION,
    };
};
export default useLoginApi;
