import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQueryCache } from 'butler';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import FormTextField from '../../form/FormTextField';
import positiveLogoImage from '../../static/images/logo_positive.png';
import useLoginApi from '../../api/useLoginApi';
import ButtonLink from "../components/ButtonLink";
const LoginComponent = () => {
    const history = useHistory();
    const { handleRequest, validation } = useLoginApi();
    const [mutate] = useMutation(handleRequest);
    const queryCache = useQueryCache();
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            await mutate({ values, formik: formikHelpers });
            if (history.location.state) {
                history.replace(history.location.state.from || '/');
            }
            else {
                history.replace('/');
            }
        }
        catch (e) { }
    }, [mutate, queryCache]);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Formik, { validationSchema: validation, onSubmit: onSubmit, initialValues: {} }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST" },
            React.createElement(Card, null,
                React.createElement(CardHeader, { title: React.createElement("img", { src: positiveLogoImage, width: "100%", height: "auto", alt: "Numereyes" }) }),
                React.createElement(CardContent, null,
                    React.createElement(Grid, { container: true, spacing: 4 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "email", label: "Email", autoComplete: "email", variant: "outlined" })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(FormTextField, { fullWidth: true, name: "password", label: "Password", type: "password", autoComplete: "current-password", variant: "outlined" })))),
                React.createElement(CardActions, null,
                    React.createElement(Box, { padding: 1, width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" },
                        React.createElement(Box, { position: "relative" },
                            React.createElement(Button, { type: "submit", onClick: formikProps.submitForm, disabled: formikProps.isSubmitting, variant: "contained", color: "primary", disableElevation: true }, "Login"),
                            React.createElement(Fade, { in: formikProps.isSubmitting, style: {
                                    transitionDelay: formikProps.isSubmitting
                                        ? '200ms'
                                        : '0ms',
                                }, unmountOnExit: true },
                                React.createElement(Box, { top: "0", left: "0", position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
                                    React.createElement(CircularProgress, { size: 24 })))))))))),
        React.createElement(Box, { padding: 1, marginTop: 3, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" },
            React.createElement(ButtonLink, { to: "/privacy-policy", variant: "text", color: "primary", disableElevation: true }, "Privacy Policy"))));
};
export default LoginComponent;
