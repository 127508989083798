import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import UploadCompleteIcon from '@material-ui/icons/Check';
import UploadRecordIcon from '@material-ui/icons/CloudUpload';
import UploadErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import processRecordFile from '../utils/processRecordFile';
/*
Papa.parse(fileInput.files[0], {
    complete: function(results) {
        console.log(results);
    }
}); */
const useStyles = makeStyles((theme) => ({
    drop: {
        borderRadius: 4,
        borderWidth: 3,
        borderStyle: 'dashed',
        borderColor: theme.palette.common.black,
        color: theme.palette.common.black,
        '&.drop--valid': {
            borderStyle: 'dashed',
            borderColor: theme.palette.success.main,
            color: theme.palette.success.main,
        },
        '&.drop--invalid': {
            borderStyle: 'dashed',
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        },
        '&.drop--disabled': {
            borderWidth: 0,
        },
    },
}));
const ProgressSpinner = () => (React.createElement(Box, { top: "0", left: "0", position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
    React.createElement(CircularProgress, { size: 64 })));
const UploadRecordZone = ({ error, erpType, onChange, disabled, hasRecord, }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [fileInfo, setFileInfo] = useState({});
    const [isProcessing, setProcessing] = useState(false);
    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        setProcessing(true);
        const file = acceptedFiles[0];
        if (!file) {
            onChange?.(null);
            setProcessing(false);
            setFileInfo({});
            setErrorMessage('Please upload a CSV file');
            return;
        }
        setErrorMessage('');
        setFileInfo({
            fileName: file.name,
        });
        try {
            const accounts = await processRecordFile(erpType, file);
            onChange?.(accounts);
            setErrorMessage(null);
        }
        catch (err) {
            setErrorMessage(`Error processing "${file.name}"`);
        }
        setProcessing(false);
        // Do something with the files
    }, [setProcessing]);
    const { getRootProps, getInputProps, isDragAccept, isDragReject, } = useDropzone({
        accept: [
            '.csv',
            'text/csv',
            'application/vnd.ms-excel',
            'application/csv',
            'text/x-csv',
            'application/x-csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
        ],
        onDrop,
        disabled: isProcessing || disabled,
    });
    let message = '';
    let isError = false;
    let icon = React.createElement(UploadRecordIcon, { fontSize: "large", color: "inherit" });
    if (isDragAccept) {
        icon = React.createElement(UploadCompleteIcon, { fontSize: "large", color: "inherit" });
    }
    else if (isDragReject) {
        isError = true;
        message = 'Please upload a CSV file';
        icon = React.createElement(UploadErrorIcon, { fontSize: "large", color: "inherit" });
    }
    else if (errorMessage || error) {
        isError = true;
        message = errorMessage ?? error;
        icon = React.createElement(UploadErrorIcon, { fontSize: "large", color: "inherit" });
    }
    else if (hasRecord) {
        message = fileInfo.fileName ?? '';
        icon = React.createElement(UploadCompleteIcon, { fontSize: "large", color: "inherit" });
    }
    return (React.createElement("div", { ...getRootProps(), style: { outline: 'none' } },
        React.createElement("input", { ...getInputProps() }),
        React.createElement(Box, { className: clsx({
                [classes.drop]: true,
                'drop--valid': isDragAccept || Boolean(hasRecord),
                'drop--invalid': isError,
                'drop--disabled': isProcessing,
            }), width: "100%", minHeight: 128, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" },
            isProcessing && React.createElement(ProgressSpinner, null),
            icon,
            message && React.createElement(Typography, { variant: "body1" }, message))));
};
export default UploadRecordZone;
