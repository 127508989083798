import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import positiveLogoImage from '../../static/images/logo_positive.png';
import ButtonLink from '../components/ButtonLink';
const PrivacyComponent = () => {
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Card, null,
            React.createElement(CardHeader, { title: React.createElement(React.Fragment, null,
                    React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                        React.createElement(Typography, { variant: "h4" }, "NumerEyes Privacy Policy"),
                        React.createElement(Box, { display: "flex", alignItems: "center" },
                            React.createElement(ButtonLink, { style: { marginRight: 16 }, to: "/", variant: "text", color: "primary", disableElevation: true }, "Navigate Home"),
                            React.createElement(Link, { to: "/" },
                                React.createElement("img", { src: positiveLogoImage, width: "auto", height: "42px", alt: "Numereyes" })))),
                    React.createElement(Divider, { style: { backgroundColor: '#F68B1F' } })) }),
            React.createElement(CardContent, null,
                React.createElement(Typography, null,
                    "This Privacy Policy applies to the web site that, NumerEyes Limited operates and all of the Products and services we provide. It only applies to Business Owners that are sole traders and does not apply to incorporated companies. This Privacy Policy sets out how we collect, share and use personal data of sole trader Business Owners and their rights of access and correction of it.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u201CPersonal data\u201D means identifiable information about the sole trader, like their name, date of birth, email address, mobile phone number, bank account details, payment information or support queries and so on.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "By using NumerEyes Products and services, technical support services, website you agree that you have read and understood our Privacy Policy.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "NumerEyes may update this Privacy Policy from time to time and make this available on its web site."),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(Typography, { variant: "h6" }, "How NumerEyes collects Personal Data"),
                React.createElement(Typography, null, "When you visit our web site or use NumerEyes Products, we collect personal data. The ways NumerEyes collects it are set out below:"),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "1. Information collected automatically"),
                    ":",
                    ' ',
                    "NumerEyes collects some information about you automatically when you visit our website or use NumerEyes Products. NumerEyes also collects information when you navigate through our website, including what pages you looked at and what links you clicked on. Some of this information is collected using cookies and similar tracking technologies."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "2. Information you provide to"),
                    " NumerEyes directly: Should you register on our website as a Reseller coach to use our Products, then we may collect data such as your username, email address, business address and other contact details."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "3. Information"),
                    " NumerEyes gets",
                    ' ',
                    React.createElement("strong", null, "from other parties"),
                    ": The majority of information NumerEyes collects, is collected directly from you. Sometimes NumerEyes might collect personal data from other sources and this information supplements the personal data NumerEyes already holds about you, in order to better inform, personalise and improve our Products, and to validate the personal data you provide.",
                    React.createElement("br", null),
                    "When NumerEyes collects personal data, it will only process it:",
                    React.createElement("ul", null,
                        React.createElement("li", null, "to provide Products or services to you"),
                        React.createElement("li", null, "to enter into a reseller agreement with you;"),
                        React.createElement("li", null, "where we have your consent;"),
                        React.createElement("li", null, "where we have legitimate interests to process the personal data and they\u2019re not overridden by your rights; or"),
                        React.createElement("li", null, "in accordance with a legal obligation."),
                        React.createElement("li", null, "to provide Products or services to you")),
                    "If you don\u2019t want to provide NumerEyes with personal data, you don\u2019t have to, but it might mean you can\u2019t use some parts of our website or Products."),
                React.createElement(Typography, { variant: "h6", style: { display: 'block', marginTop: 24 } }, "1. How we use your Personal Data"),
                React.createElement(Typography, null, "Sometimes NumerEyes will use your personal data when you use our website to provide you with any Products you\u2019ve requested, and to manage its relationship with you. NumerEyes may use your username to set up and authenticate your user account and use your email address to send you an activation link to your profile so that you can receive Products and reports. NumerEyes will not share your personal data with anyone else except as detailed in Clause 3 below (Disclosure of your Information). NumerEyes may also use your personal data for other purposes, which may include the following:"),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "To support you:"),
                    " This may include assisting you with the resolution of technical support issues or other issues relating to NumerEyes website or Products, whether by phone or email."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "To protect you:"),
                    " So that NumerEyes can detect and prevent any spam, fraud or abuse of its website by logging the IP address of its users. Also, to make sure its website and Products are being used in accordance with the Reseller coach Terms."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "To enhance the NumerEyes website and Products:"),
                    ' ',
                    "Sometimes NumerEyes may track and monitor how you use our website and Products so that we keep improving them, or optimise your user experience of them."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "To communicate with you."),
                    " NumerEyes may do this for a number of reasons, such as providing information you\u2019ve requested (like training materials) or Product information and depending on your marketing preferences marketing material (about new Products we think you might be interested in)."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "To maintain good relations with you:"),
                    ' ',
                    "NumerEyes needs to ensure that both parties adhere to our respective contractual obligations to prevent and resolve disputes or overdue accounts."),
                React.createElement("br", null),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "To analyse, aggregate and report:"),
                    ' ',
                    "NumerEyes may use the personal data it collects from its website and Products (whether obtained directly or from third parties) to produce aggregated and anonymised analytics and reports, which we may share publicly or with third parties."),
                React.createElement(Typography, { variant: "h6", style: { display: 'block', marginTop: 24 } }, "2. How NumerEyes may share your Personal Data"),
                React.createElement(Typography, null,
                    "Sometimes NumerEyes may need to share your personal data with third parties. We will only disclose your personal data to",
                    React.createElement("ul", null,
                        React.createElement("li", null, "law enforcement agencies, Courts or other third parties where it\u2019s necessary to comply with applicable laws or regulations, or to exercise or defend our legal rights such as the collection of overdue accounts. Where possible and appropriate, NumerEyes will notify you of this type of disclosure;"),
                        React.createElement("li", null, "third party service providers assisting us to provide our Products to you;"),
                        React.createElement("li", null, "agencies that assist us to use personal data to support delivery of or provide functionality on our website or Products, or"),
                        React.createElement("li", null, "other agencies where we have your consent."))),
                React.createElement(Typography, null,
                    React.createElement("strong", null, "International Personal Data Transfers"),
                    React.createElement("br", null),
                    "When NumerEyes processes or shares personal data, it may be transferred to countries outside of New Zealand such as England, where the data may be stored or processed. When NumerEyes does this, it ensures your personal data remains protected by local laws subject to similar safeguards to those in the Privacy Act 2020.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "For sole traders in the European Economic Area (EEA), this means that your data may be transferred outside of the EEA. Where your personal data is transferred outside the EEA, it will only be transferred to countries that have been identified as providing similar safeguards for EEA data (like those in the Privacy Act 2020), or to a third party where NumerEyes has an approved transfer mechanisms in place to protect your personal data."),
                React.createElement(Typography, { variant: "h6", style: { display: 'block', marginTop: 24 } }, "3. How NumerEyes keeps your Personal Data safe"),
                React.createElement(Typography, null, "Security is a high priority for NumerEyes when it comes to your personal data. NumerEyes is committed to protecting your personal data and have appropriate technical and organisational safe guards in place to make sure that happens."),
                React.createElement(Typography, { variant: "h6", style: { display: 'block', marginTop: 24 } }, "4. Holding Personal Data about you"),
                React.createElement(Typography, null,
                    "The length of time NumerEyes keeps your personal data depends on the purpose we collected it for, what it is and whether we have an ongoing business need to retain it (for example, to provide you with a Products you\u2019ve requested or to comply with applicable N.Z legal, tax or accounting requirements) and for a period of time afterwards.",
                    React.createElement("br", null),
                    "Following that period, NumerEyes will delete it unless you direct us otherwise."),
                React.createElement(Typography, { variant: "h6", style: { display: 'block', marginTop: 24 } }, "5. Your rights to access Personal Data"),
                React.createElement(Typography, null,
                    "It\u2019s your personal data and you have certain rights of access and correction of it. Sometimes you can do this by updating your personal data through the settings available in your account. When it comes to marketing communications, you can ask NumerEyes not to send you these at any time. Please follow the unsubscribe instructions contained in the marketing communication or send your request to",
                    ' ',
                    React.createElement("a", { href: "mailto:hello@numereyes.co.nz" }, "hello@numereyes.co.nz"),
                    React.createElement("br", null),
                    "You have rights to:",
                    React.createElement("ul", null,
                        React.createElement("li", null, "know what personal data we hold about you, and to make sure it\u2019s accurate and up to date;"),
                        React.createElement("li", null, "request a copy of your personal data that NumerEyes is holding but there may be a small charge for this;"),
                        React.createElement("li", null, "ask NumerEyes to delete or restrict processing your personal data."))),
                React.createElement(Typography, { variant: "h6", style: { display: 'block', marginTop: 24 } }, "6. How to contact NumerEyes"),
                React.createElement(Typography, null,
                    "If you are interested about what personal data NumerEyes holds about you or would like it to correct or delete it or you have a question or feedback for us on this Privacy Policy, our website or Products, please get in touch.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "If you\u2019re not happy with how we are processing your personal data or believe your privacy has been breached, please let NumerEyes know at",
                    ' ',
                    React.createElement("a", { href: "mailto:hello@numereyes.co.nz" }, "hello@numereyes.co.nz"),
                    ' ',
                    "NumerEyes will review and investigate your concerns and try to get back to you as soon as possible.")))));
};
export default PrivacyComponent;
