import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React from 'react';
import { MoneyHundred } from '@local/website-stdlib';
const ROW_CLASSES = {
    directLabour: 'forecast-table__row--start',
    contributionProfit: 'forecast-table__row--end',
    facilities: 'forecast-table__row--start',
    remainingOpEx: 'forecast-table__row--end',
    totalOpEx: 'forecast-table__row--start',
    operatingProfit: 'forecast-table__row--end',
    otherIncomeExpenses: 'forecast-table__row--start',
    netProfitBeforeTax: 'forecast-table__row--important',
    cumulativeNetProfit: 'forecast-table__row--end forecast-table__row--important',
    // Percentages
    grossProfitPercent: 'forecast-table__row--start forecast-table__row--percentage forecast-table__row--important',
    contributionProfitPercent: 'forecast-table__row--percentage forecast-table__row--important',
    totalOpExPercent: 'forecast-table__row--percentage forecast-table__row--important',
    netProfitBeforeTaxPercent: 'forecast-table__row--end forecast-table__row--percentage forecast-table__row--important',
    totalLer: 'forecast-table__row--start forecast-table__row--ler forecast-table__row--important',
};
const ReportProfitTable = ({ forecast }) => {
    let cumulativeNetProfit = new MoneyHundred(0);
    const tableData = {
        revenue: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Revenue"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.revenue.toString()),
        ],
        cogs: [
            React.createElement(TableCell, { align: "left", component: "th" }, "COGS"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.cogs.toString()),
        ],
        grossProfit: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Gross Profit"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
        ],
        directLabour: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Direct Labour"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.directLabour.toString()),
        ],
        contributionProfit: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Contribution Profit"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
        ],
        facilities: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Facilities"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.facilities.toString()),
        ],
        marketingSales: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Marketing and Sales"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.marketingSales.toString()),
        ],
        salaries: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Salaries"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.salaries.toString()),
        ],
        payrollBenefits: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Payroll and Benefits"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.payrollBenefits.toString()),
        ],
        remainingOpEx: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Remaining OpEx"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.remainingOpEx.toString()),
        ],
        totalOpEx: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Total Operating Expenses"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.totalOpEx.toString()),
        ],
        operatingProfit: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Net Operating Profit"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
        ],
        otherIncomeExpenses: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Other Income/Expenses"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.otherIncomeExpenses.toString()),
        ],
        netProfitBeforeTax: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Net Profit Before Tax"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.netProfitBeforeTax.toString()),
        ],
        cumulativeNetProfit: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Cumulative Net Profit"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }),
        ],
        // Percentages
        grossProfitPercent: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Gross Profit (%)"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.grossProfitPercent.toString()),
        ],
        contributionProfitPercent: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Contribution Profit (%)"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.contributionProfitPercent.toString()),
        ],
        totalOpExPercent: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Operating Expenses (%)"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.totalOpExPercent.toString()),
        ],
        netProfitBeforeTaxPercent: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Net Profit Before Tax (%)"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.netProfitBeforeTaxPercent.toString()),
        ],
        // LERs
        totalLer: [
            React.createElement(TableCell, { align: "left", component: "th" }, "Revenue / Total Labour"),
            React.createElement(TableCell, { className: "forecast-card__prior-period", align: "right" }, forecast.rollingAverage.totalLer?.format(true, 2)),
        ],
    };
    forecast.monthlyAccounts.forEach((monthlyAccount, monthId) => {
        const monthString = monthId;
        cumulativeNetProfit = cumulativeNetProfit.add(monthlyAccount.netProfitBeforeTax);
        tableData.revenue.push(React.createElement(TableCell, { key: `${monthString}-revenue`, align: "right" }, monthlyAccount.revenue.toString()));
        tableData.cogs.push(React.createElement(TableCell, { key: `${monthString}-cogs`, align: "right" }, monthlyAccount.cogs.toString()));
        tableData.grossProfit.push(React.createElement(TableCell, { key: `${monthString}-grossProfit`, align: "right" }, monthlyAccount.grossProfit.toString()));
        tableData.directLabour.push(React.createElement(TableCell, { key: `${monthString}-directLabour`, align: "right" }, monthlyAccount.directLabour.toString()));
        tableData.contributionProfit.push(React.createElement(TableCell, { key: `${monthString}-contributionProfit`, align: "right" }, monthlyAccount.contributionProfit.toString()));
        tableData.facilities.push(React.createElement(TableCell, { key: `${monthString}-facilities`, align: "right" }, monthlyAccount.facilities.toString()));
        tableData.marketingSales.push(React.createElement(TableCell, { key: `${monthString}-marketingSales`, align: "right" }, monthlyAccount.marketingSales.toString()));
        tableData.salaries.push(React.createElement(TableCell, { key: `${monthString}-salaries`, align: "right" }, monthlyAccount.salaries.toString()));
        tableData.payrollBenefits.push(React.createElement(TableCell, { key: `${monthString}-payrollBenefits`, align: "right" }, monthlyAccount.payrollBenefits.toString()));
        tableData.remainingOpEx.push(React.createElement(TableCell, { key: `${monthString}-remainingOpEx`, align: "right" }, monthlyAccount.remainingOpEx.toString()));
        tableData.totalOpEx.push(React.createElement(TableCell, { key: `${monthString}-totalOpEx`, align: "right" }, monthlyAccount.totalOpEx.toString()));
        tableData.operatingProfit.push(React.createElement(TableCell, { key: `${monthString}-operatingProfit`, align: "right" }, monthlyAccount.operatingProfit.toString()));
        tableData.otherIncomeExpenses.push(React.createElement(TableCell, { key: `${monthString}-otherIncomeExpenses`, align: "right" }, monthlyAccount.otherIncomeExpenses.toString()));
        tableData.netProfitBeforeTax.push(React.createElement(TableCell, { key: `${monthString}-netProfitBeforeTax`, align: "right" }, monthlyAccount.netProfitBeforeTax.toString()));
        tableData.cumulativeNetProfit.push(React.createElement(TableCell, { key: `${monthString}-cumulativeNetProfit`, align: "right" }, cumulativeNetProfit.toString()));
        // Percentages
        tableData.grossProfitPercent.push(React.createElement(TableCell, { key: `${monthString}-grossProfitPercent`, align: "right" }, monthlyAccount.grossProfitPercent));
        tableData.contributionProfitPercent.push(React.createElement(TableCell, { key: `${monthString}-contributionProfitPercent`, align: "right" }, monthlyAccount.contributionProfitPercent));
        tableData.totalOpExPercent.push(React.createElement(TableCell, { key: `${monthString}-totalOpExPercent`, align: "right" }, monthlyAccount.totalOpExPercent));
        tableData.netProfitBeforeTaxPercent.push(React.createElement(TableCell, { key: `${monthString}-netProfitBeforeTaxPercent`, align: "right" }, monthlyAccount.netProfitBeforeTaxPercent));
        tableData.totalLer.push(React.createElement(TableCell, { key: `${monthString}-totalLer`, align: "right" }, monthlyAccount.totalLer?.format(true, 2)));
    });
    Object.keys(tableData).forEach((key) => {
        if (forecast.totals[key] === undefined) {
            tableData[key].push(React.createElement(TableCell, { align: "right" }), React.createElement(TableCell, { align: "right" }));
            return;
        }
        if (key === 'totalLer') {
            tableData[key].push(React.createElement(TableCell, { align: "right" }, forecast.totals[key]?.format?.(true, 2)), React.createElement(TableCell, { align: "right" }));
        }
        else {
            tableData[key].push(React.createElement(TableCell, { align: "right" }, forecast.totals[key]?.toString()), React.createElement(TableCell, { align: "right" }, forecast.totals[`${key}Percent`]?.toString()));
        }
    });
    return (React.createElement(TableContainer, { className: "forecast-table" },
        React.createElement(Table, { size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                    React.createElement(TableCell, null, "Month"),
                    React.createElement(TableCell, { className: "forecast-card__prior-period" }, "Prior Period"),
                    forecast.months.map((month) => (React.createElement(TableCell, { align: "right", key: month.toString() }, formatDate(month, 'LLL-yy')))),
                    React.createElement(TableCell, { component: "th", align: "right" }, "Total"),
                    React.createElement(TableCell, { component: "th", align: "right" }, "% of Rev"))),
            React.createElement(TableBody, null, Object.entries(tableData).map(([key, cols]) => (React.createElement(TableRow, { className: ROW_CLASSES[key], key: key }, cols)))))));
};
export default ReportProfitTable;
