import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccountPage from './account/AccountPage';
import CompaniesPage from './company/CompaniesPage';
import CompanyPage from './company/CompanyPage';
import CreateCompanyPage from './company/CreateCompanyPage';
import NotFoundErrorPage from './errors/NotFoundErrorPage';
import RouteStatus from './errors/RouteStatus';
import CompanyForecastPage from './forecast/CompanyForecastPage';
import CreateForecastPage from './forecast/CreateForecastPage';
import ReportPage from './forecast/report/ReportPage';
import ViewForecastPage from './forecast/ViewForecastPage';
import { CanRoute } from './gatekeeper/Gatekeeper';
import LoginPage from './login/LoginPage';
import PrivacyPage from './privacy/PrivacyPage';
import CreateRecordPage from './record/CreateRecordPage';
import UpdateRecordPage from './record/UpdateRecordPage';
import CreateResellerPage from './reseller/CreateResellerPage';
import ResellerPage from './reseller/ResellerPage';
import ResellersPage from './reseller/ResellersPage';
const Pages = () => (React.createElement(React.Fragment, null,
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CompaniesPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/account" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': AccountPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/company/create" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CreateCompanyPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/company/:id" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CompanyPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/company/:id/forecast" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CompanyForecastPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/company/:id/create-forecast" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CreateForecastPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/forecast/:id" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': ViewForecastPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/forecast/:id/:tab" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': ViewForecastPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/companies" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CompaniesPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/company/:id/upload-record" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': CreateRecordPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/record/:id" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': UpdateRecordPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/login" },
            React.createElement(CanRoute, { to: {
                    'website:Login': LoginPage,
                }, redirect: "/" })),
        React.createElement(Route, { exact: true, path: "/generate/report/forecast/:id" },
            React.createElement(CanRoute, { to: {
                    'website:Authenticated': ReportPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/reseller/create" },
            React.createElement(CanRoute, { to: {
                    'website:Administrator': CreateResellerPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/reseller/:id" },
            React.createElement(CanRoute, { to: {
                    'website:Administrator': ResellerPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/resellers" },
            React.createElement(CanRoute, { to: {
                    'website:Administrator': ResellersPage,
                }, redirect: "/login" })),
        React.createElement(Route, { exact: true, path: "/privacy-policy" },
            React.createElement(PrivacyPage, null)),
        React.createElement(Route, { component: () => (React.createElement(RouteStatus, { code: 404 },
                React.createElement(NotFoundErrorPage, null))) }))));
export default Pages;
