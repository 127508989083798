import currency from 'currency.js';
import kindOf from 'kind-of';
import objectPath from 'object-path';
const CURRENCIES = {
    USD: {
        currency: 'USD',
        symbol: '$',
        precision: 2,
        unit: 'cent',
    },
    NZD: {
        currency: 'NZD',
        symbol: '$',
        precision: 2,
        unit: 'cent',
    },
    JPY: {
        currency: 'JPY',
        symbol: '¥',
        precision: 0,
        unit: 'yen',
    },
};
// TODO: revert this, remove default NZD
const UNKNOWN_CURRENCY = {
    currency: 'NZD',
    symbol: '$',
    precision: 2,
    unit: 'cent',
};
function getIso(currencyCode) {
    if (!currencyCode) {
        return UNKNOWN_CURRENCY;
    }
    const iso = CURRENCIES[currencyCode.toUpperCase()];
    if (!iso) {
        throw new Error(`Undefined currency code: ${currencyCode}`);
    }
    return iso;
}
export default class Money {
    static CURRENCIES = CURRENCIES;
    static getIso = getIso;
    static sum(itemsInput, field) {
        const items = [...itemsInput];
        let base = items.shift();
        if (!base) {
            return undefined;
        }
        if (field) {
            base = objectPath.get(base, field);
            items.forEach((item) => {
                base = base.add(objectPath.get(item, field));
            });
        }
        else {
            items.forEach((item) => {
                base = base.add(item);
            });
        }
        return base;
    }
    amount;
    currency;
    unit;
    value;
    constructor(value, currencyCode) {
        if (value === undefined || value == null) {
            return;
        }
        let iso = getIso(currencyCode);
        if (value instanceof currency) {
            this.value = currency(value, iso);
        }
        else if (value instanceof Money || kindOf(value) === 'object') {
            iso = getIso(value.currency);
            this.value = currency(value.amount, { ...iso, fromCents: true });
        }
        else {
            this.value = currency(value, iso);
        }
        this.amount = this.value.intValue;
        this.currency = iso.currency;
        this.unit = iso.unit;
    }
    assert(money) {
        if (!this.currency) {
            this.currency = money.currency;
            this.unit = money.unit;
        }
        if (!money.currency) {
            return;
        }
        if (this.currency !== money.currency) {
            throw new Error(`Cannot mix currencies (${this.currency}, ${money.currency})`);
        }
    }
    getValueFromMoney(money) {
        if (kindOf(money) === 'object') {
            this.assert(money);
            return money.value;
        }
        return money;
    }
    add(money) {
        const amount = this.value.add(this.getValueFromMoney(money));
        return new Money(amount, this.currency);
    }
    subtract(money) {
        const amount = this.value.subtract(this.getValueFromMoney(money));
        return new Money(amount, this.currency);
    }
    multiply(value) {
        const amount = this.value.multiply(value);
        return new Money(amount, this.currency);
    }
    divide(value) {
        const amount = this.value.divide(value);
        return new Money(amount, this.currency);
    }
    /*
        DISCLAIMER:
        This method has been based on "allocate" from https://github.com/macor161/ts-money/blob/master/index.ts
     */
    allocate(ratios) {
        let remainder = this.value;
        let total = 0;
        const values = [];
        ratios.forEach((ratio) => {
            total += ratio;
        });
        ratios.forEach((ratio) => {
            const share = this.value.multiply(ratio).divide(total);
            values.push(new Money(share, this.currency));
            remainder = remainder.subtract(share);
        });
        for (let i = 0; remainder.intValue > 0; i += 1) {
            // We're working with cents here
            values[i] = new Money({
                amount: values[i].amount + 1,
                currency: values[i].currency,
            });
            // @ts-ignore
            remainder.intValue -= 1;
        }
        return values;
    }
    distribute(between) {
        const values = this.value.distribute(between);
        return values.map((value) => new Money(value, this.currency));
    }
    /**
     * X of the current currency to one of the target currency
     * @param ratio X of the current currency to one of the target currency
     * @param currencyCode
     */
    convert(ratio, currencyCode) {
        const amount = this.value.divide(ratio);
        return new Money(amount, currencyCode);
    }
    // Boolean operators
    equalsCurrency(money) {
        if (!this.currency) {
            return true;
        }
        if (!money.currency) {
            return true;
        }
        return this.currency === money.currency;
    }
    equals(money) {
        if (money instanceof Money) {
            return this.amount === money.amount && this.equalsCurrency(money);
        }
        return this.value.value === money;
    }
    greaterThan(money) {
        if (money instanceof Money) {
            return this.amount > money.amount && this.equalsCurrency(money);
        }
        return this.value.value > money;
    }
    greaterThanOrEqual(money) {
        return this.greaterThan(money) || this.equals(money);
    }
    lessThan(money) {
        if (money instanceof Money) {
            return this.amount < money.amount && this.equalsCurrency(money);
        }
        return this.value.value < money;
    }
    lessThanOrEqual(money) {
        return this.lessThan(money) || this.equals(money);
    }
    format(withSymbol = true, precision = 0) {
        if (!this.value) {
            return '';
        }
        if (withSymbol) {
            return currency(this.value, {
                precision,
                symbol: this.getSymbol(),
            }).format();
        }
        return currency(this.value, { precision, symbol: '' }).format();
    }
    // We make the assumption that the value will always be displayed with its symbol by default
    toString() {
        return this.format();
    }
    toJSON() {
        return {
            currency: this.currency,
            amount: this.amount,
            unit: this.unit,
        };
    }
    toNumber() {
        return this.value.value;
    }
    clone() {
        return new Money(this);
    }
    getSymbol() {
        return this.value?.s?.symbol || '';
    }
}
