// @graph-mind
// Remove the previous line to stop Ada from updating this file
import * as yupBase from 'yup';
import CommonQuerySchemaType from './commonQuerySchemaType';
import MoneySchemaType from './moneySchemaType';
// Create our new "yup" base
const yup = {
    ...yupBase,
    money: () => new MoneySchemaType(),
    commonQueryArguments: (args) => new CommonQuerySchemaType(args),
};
export default yup;
