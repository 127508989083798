import { useBuslane } from 'buslane';
import { useCallback } from 'react';
export function transformReseller(reseller) {
    return {
        ...reseller,
        createdAt: new Date(reseller.createdAt),
        updatedAt: new Date(reseller.updatedAt ?? reseller.createdAt),
    };
}
async function resellerApi(buslane, id) {
    const body = await buslane
        .get(`api/rest/resellers/${id}`)
        .json();
    if (!body.success) {
        throw new Error('Coach not found');
    }
    return body.data;
}
const useResellerApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, id) => {
        return resellerApi(buslane, id);
    }, [buslane]);
    return {
        handleRequest,
        transform: transformReseller,
    };
};
export default useResellerApi;
