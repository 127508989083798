import { useBuslane } from 'buslane';
import { useCallback } from 'react';
async function logoutApi(buslane) {
    try {
        const body = await buslane
            .post('api/rest/auth/logout')
            .json();
        if (!body.success) {
            throw new Error('Auth failure');
        }
        else {
            await buslane.emitAsync('authentication');
        }
    }
    catch (e) {
        throw new Error('Failed to logout');
    }
}
const useLogoutApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback(() => logoutApi(buslane), [buslane]);
    return {
        handleRequest,
    };
};
export default useLogoutApi;
