import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React, { useCallback } from 'react';
import WarningButton from '../../components/WarningButton';
import ForecastFieldMoney from "../components/ForecastFieldMoney";
import ForecastFieldSimple from "../components/ForecastFieldSimple";
import ForecastFieldPercent from "../components/ForecastFieldPercent";
import { calculatePercentOf } from "../../entities/forecast";
const ForecastSalesStream = ({ index, revenueStream, totalRevenue, forecast, onRemove, }) => {
    const revenueCols = [];
    const cogsCols = [];
    const grossProfitCols = [];
    revenueStream.months.forEach((month, monthId) => {
        const monthString = monthId;
        revenueCols.push(React.createElement(TableCell, { key: `${monthString}-revenue` },
            React.createElement(ForecastFieldMoney, { name: `revenueStreams[${index}].months[${monthId}].revenue`, fullWidth: true, variant: "outlined", margin: "dense" })));
        cogsCols.push(React.createElement(TableCell, { key: `${monthString}-cogs` },
            React.createElement(ForecastFieldMoney, { name: `revenueStreams[${index}].months[${monthId}].cogs`, fullWidth: true, variant: "outlined", margin: "dense", disabled: Boolean(forecast.values.revenueStreams[index].cogsPercent) })));
        grossProfitCols.push(React.createElement(TableCell, { key: `${monthString}-grossProfit`, align: "right" }, month.grossProfit?.toString?.()));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { className: "forecast-table__row--start" },
            React.createElement(TableCell, { component: "th", scope: "row" }, "Revenue"),
            React.createElement(TableCell, null,
                React.createElement(ForecastFieldSimple, { name: `revenueStreams[${index}].name`, label: "Name", variant: "outlined", margin: "dense", fullWidth: true })),
            revenueCols,
            React.createElement(TableCell, { align: "right" }, revenueStream.totalRevenue.toString()),
            React.createElement(TableCell, { align: "right" },
                React.createElement("b", null, calculatePercentOf(revenueStream.totalRevenue, totalRevenue))),
            React.createElement(TableCell, null)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { component: "th" }, "COGS"),
            React.createElement(TableCell, null,
                React.createElement(ForecastFieldPercent, { name: `revenueStreams[${index}].cogsPercent`, label: "COGS %", variant: "outlined", margin: "dense", fullWidth: true })),
            cogsCols,
            React.createElement(TableCell, { align: "right" }, revenueStream.totalCogs.toString()),
            React.createElement(TableCell, { align: "right" }, calculatePercentOf(revenueStream.totalCogs, totalRevenue)),
            React.createElement(TableCell, null)),
        React.createElement(TableRow, { className: "forecast-table__row--end" },
            React.createElement(TableCell, { component: "th" }, "Gross Profit"),
            React.createElement(TableCell, null),
            grossProfitCols,
            React.createElement(TableCell, { align: "right" }, revenueStream.totalGrossProfit.toString()),
            React.createElement(TableCell, { align: "right" },
                React.createElement("b", null, calculatePercentOf(revenueStream.totalGrossProfit, totalRevenue))),
            React.createElement(TableCell, null,
                React.createElement(WarningButton, { onClick: onRemove(index), variant: "contained", disableElevation: true }, "X")))));
};
const ForecastSalesTable = ({ forecast, }) => {
    const handleRemoveCategory = useCallback((index) => () => {
        forecast.removeRevenueStream(index);
    }, [forecast]);
    return (React.createElement(TableContainer, { className: "forecast-table" },
        React.createElement(Table, { size: "small" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                    React.createElement(TableCell, null, "Month"),
                    React.createElement(TableCell, null),
                    forecast.values.months.map((month) => (React.createElement(TableCell, { align: "right", key: month.toString() }, formatDate(month, 'LLL-yy')))),
                    React.createElement(TableCell, { component: "th", align: "right" }, "Total"),
                    React.createElement(TableCell, { component: "th", align: "right" }, "%"),
                    React.createElement(TableCell, null))),
            React.createElement(TableBody, null,
                React.createElement(TableRow, { className: "forecast-table__row--important" },
                    React.createElement(TableCell, { component: "th" }, "Total Revenue"),
                    React.createElement(TableCell, null),
                    forecast.values.monthlyAccounts.map((month, index) => (React.createElement(TableCell, { align: "right", key: `revenue-${index}` }, month.revenue.toString()))),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.revenue.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, calculatePercentOf(forecast.values.totals.revenue, forecast.values.totals.revenue))),
                    React.createElement(TableCell, null)),
                React.createElement(TableRow, { className: "forecast-table__row--important" },
                    React.createElement(TableCell, { component: "th" }, "Total COGS"),
                    React.createElement(TableCell, null),
                    forecast.values.monthlyAccounts.map((month, index) => (React.createElement(TableCell, { align: "right", key: `cogs-${index}` }, month.cogs.toString()))),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.cogs.toString()),
                    React.createElement(TableCell, { align: "right" }, calculatePercentOf(forecast.values.totals.cogs, forecast.values.totals.revenue)),
                    React.createElement(TableCell, null)),
                React.createElement(TableRow, { className: "forecast-table__row--important" },
                    React.createElement(TableCell, { component: "th" }, "Total Gross Profit"),
                    React.createElement(TableCell, null),
                    forecast.values.monthlyAccounts.map((month, index) => (React.createElement(TableCell, { align: "right", key: `grossProfit-${index}` }, month.grossProfit.toString()))),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.grossProfit.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.totals.grossProfitPercent)),
                    React.createElement(TableCell, null)),
                forecast.values.revenueStreams.map((revenueStream, index) => (React.createElement(ForecastSalesStream, { key: `sales-stream-${index}`, revenueStream: revenueStream, index: index, forecast: forecast, totalRevenue: forecast.values.totals.revenue, onRemove: handleRemoveCategory })))))));
};
export default ForecastSalesTable;
