import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const RECORD_VALIDATION = yup.object().shape({
    monthlyAccounts: yup
        .array()
        .min(1, 'Accounts file required')
        .required('Accounts file required'),
});
const VALIDATION = yup.object().shape({
    record: RECORD_VALIDATION.required(),
});
async function updateRecordApi(buslane, validation, { formik, values, recordId }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const { monthlyAccounts } = input.record;
        const transformedInput = {
            id: recordId,
            monthlyAccounts,
        };
        const body = await buslane
            .post('api/rest/account-record/update', { json: transformedInput })
            .json();
        if (body.success) {
            return true;
        }
    }
    catch (e) { }
    throw new Error('Failed to update record');
}
const useUpdateRecordApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => updateRecordApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
    };
};
export default useUpdateRecordApi;
