import Box from '@material-ui/core/Box';
import formatDate from 'date-fns/format';
import React, { useMemo } from 'react';
import FinancialGraph from '../FinancialGraph';
const COLOR_LIST = [
    '#52B9DF',
    '#7EEDF7',
    '#213558',
    '#0A9A9A',
    '#005990',
    '#91B6D6',
];
const DATA_SERIES_OFFSET = 1;
const MONTH_OFFSET = 0;
const ForecastSalesGraph = ({ forecast, noAnimate }) => {
    const categoryColorList = [...COLOR_LIST];
    const labels = useMemo(() => [...forecast.months.map((month) => formatDate(month, 'LLL-yy'))], [forecast]);
    const series = [
        {
            color: '#43a047',
            name: 'Total Revenue',
            type: 'line',
            data: [],
        },
    ];
    forecast.monthlyAccounts.forEach((monthlyAccount) => {
        series[0].data.push(monthlyAccount.revenue?.toNumber?.() || 0);
    });
    forecast.months.forEach((month, monthIndex) => {
        forecast.revenueStreams.forEach((revenueStream, streamIndex) => {
            if (!series[streamIndex + DATA_SERIES_OFFSET]) {
                series.push({
                    color: categoryColorList.pop(),
                    name: `GP ${revenueStream.name}`,
                    data: [...new Array(forecast.months.length).fill(0)],
                    type: 'area',
                });
            }
            series[streamIndex + DATA_SERIES_OFFSET].data[monthIndex] =
                revenueStream.months[monthIndex]?.grossProfit?.toNumber?.() ||
                    0;
        });
    });
    return (React.createElement(Box, { width: "85%", marginLeft: "5%", height: 480, paddingX: 2 },
        React.createElement(FinancialGraph, { labels: labels, data: series, type: "area", stacked: true, noAnimate: noAnimate })));
};
export default ForecastSalesGraph;
