import Box from '@material-ui/core/Box';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import LoginComponent from './LoginComponent';
const LoginPage = () => (React.createElement(Box, { display: "flex", width: "100%", minHeight: "62%", justifyContent: "center", alignItems: "center" },
    React.createElement(Helmet, null,
        React.createElement("title", null, "Numereyes - Login")),
    React.createElement(Box, { width: "100%", maxWidth: 480, padding: 4 },
        React.createElement(LoginComponent, null))));
export default LoginPage;
