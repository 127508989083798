import Box from '@material-ui/core/Box';
import formatDate from 'date-fns/format';
import React, { useMemo } from 'react';
import FinancialGraph from '../FinancialGraph';
const ForecastProfitGraph = ({ forecast, noAnimate }) => {
    const labels = useMemo(() => [...forecast.months.map((month) => formatDate(month, 'LLL-yy'))], [forecast]);
    const series = [
        {
            color: '#43a047',
            name: 'Revenue',
            type: 'line',
            data: [],
        },
        {
            color: '#1e88e5',
            name: 'Contribution Profit',
            type: 'line',
            data: [],
        },
        {
            dash: 8,
            color: '#757575',
            name: 'Net Profit Before Tax',
            type: 'line',
            data: [],
        },
        {
            dash: 8,
            color: '#F68B1F',
            name: 'Cash on Hand',
            type: 'line',
            data: [],
        },
    ];
    forecast.monthlyAccounts.forEach((monthlyAccount) => {
        series[0].data.push(monthlyAccount.revenue?.toNumber?.() || 0);
        series[1].data.push(monthlyAccount.contributionProfit?.toNumber?.() || 0);
        series[2].data.push(monthlyAccount.netProfitBeforeTax?.toNumber?.() || 0);
        series[3].data.push(monthlyAccount.closingBalance?.toNumber?.() || 0);
    });
    return (React.createElement(Box, { width: "89%", marginLeft: "4%", height: 480, paddingX: 4 },
        React.createElement(FinancialGraph, { labels: labels, data: series, type: "area", hideByDefault: ['Cash on Hand'], noAnimate: noAnimate })));
};
export default ForecastProfitGraph;
