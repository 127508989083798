import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import withStyles from '@material-ui/styles/withStyles';
const NegativeButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[600]),
        backgroundColor: red[600],
        '&:hover': {
            backgroundColor: red[800],
        },
    },
}))(Button);
export default NegativeButton;
