import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const ERP_TYPES = [
    {
        value: '',
        label: 'None',
    },
    {
        value: 'SAP4.4',
        label: 'SAP 4.4',
    },
];
const COMPANY_VALIDATION = yup.object().shape({
    name: yup
        .string()
        .min(1, 'Please enter a name')
        .max(50, 'Please use a shorter name')
        .required('Please enter a name'),
    erpType: yup
        .string()
        .oneOf(ERP_TYPES.map(({ value }) => value), `Please select one of ${ERP_TYPES.map(({ label }) => label).join(', ')}`)
        .optional()
        .ensure()
        .default(''),
});
const VALIDATION = yup.object().shape({
    company: COMPANY_VALIDATION.required(),
});
async function createCompanyApi(buslane, validation, { formik, values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const body = await buslane
            .post('api/rest/companies/create', { json: input.company })
            .json();
        if (body.success) {
            return body.data.id;
        }
    }
    catch (e) { }
    throw new Error('Failed to create company');
}
const useCreateCompanyApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => createCompanyApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
        erpOptions: ERP_TYPES,
    };
};
export default useCreateCompanyApi;
