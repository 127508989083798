/* eslint-disable no-param-reassign */
import { Money, MoneyHundred } from '@local/website-stdlib';
import addMonths from 'date-fns/addMonths';
import dateIsEqual from 'date-fns/isEqual';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import { debounce } from 'debounce';
import EventEmitter from 'eventemitter3';
import objectPath from 'object-path';
const TAX_MONTHS_LOOKUP = {
    1: true,
    4: true,
    7: true,
    10: true,
};
// Utility functions
function dateTo2000(date) {
    const gstPeriodEndedAtYear = date.getFullYear();
    if (gstPeriodEndedAtYear < 1000) {
        const yearString = `20${gstPeriodEndedAtYear}`.slice(0, 4);
        date.setFullYear(Number(yearString));
    }
}
export function calculatePercentOf(numerator, denominator) {
    if (!numerator) {
        return '0%';
    }
    if (!denominator) {
        return '0%';
    }
    if (denominator.equals(0)) {
        return '0%';
    }
    const percent = ((numerator.toNumber() / denominator.toNumber()) *
        100).toFixed(0);
    return `${percent}%`;
}
function sumAll(values) {
    return values.reduce((acc, val) => {
        if (val) {
            return acc.add(val);
        }
        return acc;
    }, new Money(0));
}
export function calculateCogsAsPercentageOfRevenue(baseRevenue, baseCogs, forecastRevenue) {
    let cogsPercentNumeric = 0;
    if (!baseRevenue.equals(0)) {
        cogsPercentNumeric = baseCogs.toNumber() / baseRevenue.toNumber();
    }
    return new MoneyHundred(forecastRevenue).multiply(cogsPercentNumeric);
}
function initEmpty(obj, path, value) {
    if (!objectPath.get(obj, path)) {
        objectPath.set(obj, path, value);
    }
}
// State transition functions
function calculateMonthlyView(initialMonthlyView) {
    const monthlyView = {
        ...initialMonthlyView,
    };
    if (!monthlyView.revenue) {
        monthlyView.revenue = new MoneyHundred(0);
    }
    if (!monthlyView.cogs) {
        monthlyView.cogs = new MoneyHundred(0);
    }
    if (!monthlyView.directLabour) {
        monthlyView.directLabour = new MoneyHundred(0);
    }
    if (!monthlyView.facilities) {
        monthlyView.facilities = new MoneyHundred(0);
    }
    if (!monthlyView.marketingSales) {
        monthlyView.marketingSales = new MoneyHundred(0);
    }
    if (!monthlyView.salaries) {
        monthlyView.salaries = new MoneyHundred(0);
    }
    if (!monthlyView.payrollBenefits) {
        monthlyView.payrollBenefits = new MoneyHundred(0);
    }
    if (!monthlyView.remainingOpEx) {
        monthlyView.remainingOpEx = new MoneyHundred(0);
    }
    if (!monthlyView.otherIncomeExpenses) {
        monthlyView.otherIncomeExpenses = new MoneyHundred(0);
    }
    // Ensure these values exist
    if (!monthlyView.inventoryValue) {
        monthlyView.inventoryValue = new MoneyHundred(0);
    }
    if (!monthlyView.creditorsValue) {
        monthlyView.creditorsValue = new MoneyHundred(0);
    }
    if (!monthlyView.debtorsValue) {
        monthlyView.debtorsValue = new MoneyHundred(0);
    }
    if (!monthlyView.closingBalance) {
        monthlyView.closingBalance = new MoneyHundred(0);
    }
    if (!monthlyView.coreCashRequirements) {
        monthlyView.coreCashRequirements = new MoneyHundred(0);
    }
    // TotalOpEx
    monthlyView.grossProfit =
        monthlyView.revenue?.subtract(monthlyView.cogs) || new MoneyHundred(0);
    monthlyView.contributionProfit =
        monthlyView.grossProfit?.subtract(monthlyView.directLabour) ||
            new MoneyHundred(0);
    monthlyView.totalOpEx = new MoneyHundred(sumAll([
        monthlyView.facilities,
        monthlyView.marketingSales,
        monthlyView.salaries,
        monthlyView.payrollBenefits,
        monthlyView.remainingOpEx,
        // eslint-disable-next-line consistent-return
    ]));
    monthlyView.operatingProfit = monthlyView.contributionProfit.subtract(monthlyView.totalOpEx);
    monthlyView.netProfitBeforeTax = monthlyView.operatingProfit.add(monthlyView.otherIncomeExpenses);
    // Cumulative net profit...
    // Calculate Percentages
    monthlyView.revenuePercent = calculatePercentOf(monthlyView.revenue, monthlyView.revenue);
    monthlyView.cogsPercent = calculatePercentOf(monthlyView.cogs, monthlyView.revenue);
    monthlyView.directLabourPercent = calculatePercentOf(monthlyView.directLabour, monthlyView.revenue);
    monthlyView.facilitiesPercent = calculatePercentOf(monthlyView.facilities, monthlyView.revenue);
    monthlyView.marketingSalesPercent = calculatePercentOf(monthlyView.marketingSales, monthlyView.revenue);
    monthlyView.salariesPercent = calculatePercentOf(monthlyView.salaries, monthlyView.revenue);
    monthlyView.payrollBenefitsPercent = calculatePercentOf(monthlyView.payrollBenefits, monthlyView.revenue);
    monthlyView.remainingOpExPercent = calculatePercentOf(monthlyView.remainingOpEx, monthlyView.revenue);
    monthlyView.otherIncomeExpensesPercent = calculatePercentOf(monthlyView.otherIncomeExpenses, monthlyView.revenue);
    monthlyView.grossProfitPercent = calculatePercentOf(monthlyView.grossProfit, monthlyView.revenue);
    monthlyView.contributionProfitPercent = calculatePercentOf(monthlyView.contributionProfit, monthlyView.revenue);
    monthlyView.totalOpExPercent = calculatePercentOf(monthlyView.totalOpEx, monthlyView.revenue);
    monthlyView.operatingProfitPercent = calculatePercentOf(monthlyView.operatingProfit, monthlyView.revenue);
    monthlyView.netProfitBeforeTaxPercent = calculatePercentOf(monthlyView.netProfitBeforeTax, monthlyView.revenue);
    // Calculate LERS
    monthlyView.totalLer = new Money(0);
    monthlyView.directLer = new Money(0);
    monthlyView.adminLer = new Money(0);
    const labourAndSalaries = monthlyView.directLabour.toNumber() + monthlyView.salaries.toNumber();
    if (labourAndSalaries) {
        monthlyView.totalLer = new Money(monthlyView.revenue).divide(labourAndSalaries);
    }
    if (!monthlyView.directLabour.equals(0)) {
        monthlyView.directLer = new Money(monthlyView.grossProfit).divide(monthlyView.directLabour.toNumber());
    }
    if (!monthlyView.salaries.equals(0)) {
        monthlyView.adminLer = new Money(monthlyView.contributionProfit).divide(monthlyView.salaries.toNumber());
    }
    return monthlyView;
}
function calculateMonthlyCashFlow(monthlyView, forecast) {
    monthlyView.inventoryValue = monthlyView.revenue
        .subtract(monthlyView.grossProfit)
        .multiply(monthlyView.inventoryDays / 30);
    const gstRevenuePercent = forecast.gstRevenue / 100;
    const gstExpensesPercent = forecast.gstExpenses / 100;
    monthlyView.debtorsValue = monthlyView.revenue
        .multiply(1 + gstRevenuePercent)
        .multiply(monthlyView.debtorDays / 30);
    monthlyView.creditorsValue = new MoneyHundred(sumAll([
        monthlyView.cogs,
        monthlyView.facilities,
        monthlyView.marketingSales,
        monthlyView.remainingOpEx,
    ])
        .multiply(1 + gstExpensesPercent)
        .add(monthlyView.payrollBenefits)
        .multiply(monthlyView.creditorDays / 30));
    monthlyView.coreCashRequirements = monthlyView.directLabour
        .add(monthlyView.totalOpEx)
        .subtract(monthlyView.salaries)
        .multiply(2);
    return monthlyView;
}
function calculateMonthlyAccountView(initialMonthlyView, forecast, previousMonthlyAccount) {
    let monthlyView = calculateMonthlyView(initialMonthlyView);
    monthlyView = calculateMonthlyCashFlow(monthlyView, forecast);
    // Calculate generated values
    const gstRevenuePercent = forecast.gstRevenue / 100;
    const gstExpensesPercent = forecast.gstExpenses / 100;
    const tax = monthlyView.netProfitBeforeTax.multiply(forecast.taxRate / 100);
    // Calculate Defaults
    // Calculate inventoryValue
    if (!monthlyView.additionalCashInOut) {
        monthlyView.additionalCashInOut = new MoneyHundred(0);
    }
    if (!monthlyView.additionalCashForInventory) {
        monthlyView.additionalCashForInventory = new MoneyHundred(0);
    }
    const cashIn = previousMonthlyAccount.debtorsValue
        .subtract(monthlyView.debtorsValue)
        .add(monthlyView.revenue
        .add(monthlyView.otherIncomeExpenses)
        .multiply(1 + gstRevenuePercent));
    let cashOut = previousMonthlyAccount.creditorsValue
        .subtract(monthlyView.creditorsValue)
        .add(sumAll([
        monthlyView.cogs,
        monthlyView.facilities,
        monthlyView.marketingSales,
        monthlyView.remainingOpEx,
    ])
        .multiply(1 + gstExpensesPercent)
        .add(monthlyView.directLabour)
        .add(monthlyView.salaries)
        .add(monthlyView.payrollBenefits));
    const gstIn = new Money(monthlyView.revenue)
        .add(monthlyView.otherIncomeExpenses)
        .multiply(gstRevenuePercent);
    const gstOut = new Money(sumAll([
        monthlyView.cogs,
        monthlyView.facilities,
        monthlyView.marketingSales,
        monthlyView.remainingOpEx,
    ])).multiply(gstExpensesPercent);
    // Calculate our GST payment
    if (monthlyView.isGstDue) {
        cashOut = cashOut.add(previousMonthlyAccount.netGst);
        monthlyView.netGst = gstIn.subtract(gstOut);
    }
    else {
        monthlyView.netGst = gstIn
            .subtract(gstOut)
            .add(previousMonthlyAccount.netGst);
    }
    // Calculate out tax payment
    monthlyView.netTax = previousMonthlyAccount.netTax.add(tax);
    if (monthlyView.isTaxDue) {
        cashOut = cashOut.add(monthlyView.netTax);
        monthlyView.netTax = new Money(0);
    }
    monthlyView.closingBalance = previousMonthlyAccount.closingBalance
        .add(cashIn)
        .subtract(cashOut)
        .add(monthlyView.additionalCashInOut)
        .add(monthlyView.additionalCashForInventory)
        .add(previousMonthlyAccount.inventoryValue)
        .subtract(monthlyView.inventoryValue);
    return monthlyView;
}
function calculateMonthlyChange(forecast) {
    const monthlyChange = forecast.monthlyChange || {};
    if (!monthlyChange.priceIncreasePercent) {
        monthlyChange.priceIncreasePercent = 0;
    }
    if (!monthlyChange.revenue) {
        monthlyChange.revenue = new MoneyHundred(0);
    }
    if (!monthlyChange.cogs) {
        monthlyChange.cogs = new MoneyHundred(0);
    }
    if (!monthlyChange.directLabour) {
        monthlyChange.directLabour = new MoneyHundred(0);
    }
    if (!monthlyChange.facilities) {
        monthlyChange.facilities = new MoneyHundred(0);
    }
    if (!monthlyChange.marketingSales) {
        monthlyChange.marketingSales = new MoneyHundred(0);
    }
    if (!monthlyChange.salaries) {
        monthlyChange.salaries = new MoneyHundred(0);
    }
    if (!monthlyChange.payrollBenefits) {
        monthlyChange.payrollBenefits = new MoneyHundred(0);
    }
    if (!monthlyChange.remainingOpEx) {
        monthlyChange.remainingOpEx = new MoneyHundred(0);
    }
    if (!monthlyChange.otherIncomeExpenses) {
        monthlyChange.otherIncomeExpenses = new MoneyHundred(0);
    }
    // If debtor days is not undefined, try its number
    // Then if it is NaN OR equal to the average debtorDays, set it to undefined
    if (monthlyChange.debtorDays !== undefined) {
        monthlyChange.debtorDays = Number(monthlyChange.debtorDays);
    }
    if (Number.isNaN(monthlyChange.debtorDays) ||
        monthlyChange.debtorDays === forecast.totalsAverage.debtorDays) {
        monthlyChange.debtorDays = undefined;
    }
    // If creditor days is not undefined, try its number
    // Then if it is NaN OR equal to the average creditorDays, set it to undefined
    if (monthlyChange.creditorDays !== undefined) {
        monthlyChange.creditorDays = Number(monthlyChange.creditorDays);
    }
    if (Number.isNaN(monthlyChange.creditorDays) ||
        monthlyChange.creditorDays === forecast.totalsAverage.creditorDays) {
        monthlyChange.creditorDays = undefined;
    }
    // If inventory days is not undefined, try its number
    // Then if it is NaN OR equal to the average inventoryDays, set it to undefined
    if (monthlyChange.inventoryDays !== undefined) {
        monthlyChange.inventoryDays = Number(monthlyChange.inventoryDays);
    }
    if (Number.isNaN(monthlyChange.inventoryDays) ||
        monthlyChange.inventoryDays === forecast.totalsAverage.inventoryDays) {
        monthlyChange.inventoryDays = undefined;
    }
    return monthlyChange;
}
function calculateAverageMonthlyView(monthlyView, numberOfMonths) {
    let averageMonth = {
        revenue: new MoneyHundred(monthlyView.revenue.divide(numberOfMonths)),
        cogs: new MoneyHundred(monthlyView.cogs.divide(numberOfMonths)),
        directLabour: new MoneyHundred(monthlyView.directLabour.divide(numberOfMonths)),
        facilities: new MoneyHundred(monthlyView.facilities.divide(numberOfMonths)),
        marketingSales: new MoneyHundred(monthlyView.marketingSales.divide(numberOfMonths)),
        salaries: new MoneyHundred(monthlyView.salaries.divide(numberOfMonths)),
        payrollBenefits: new MoneyHundred(monthlyView.payrollBenefits.divide(numberOfMonths)),
        remainingOpEx: new MoneyHundred(monthlyView.remainingOpEx.divide(numberOfMonths)),
        otherIncomeExpenses: new MoneyHundred(monthlyView.otherIncomeExpenses.divide(numberOfMonths)),
    };
    averageMonth = calculateMonthlyView(averageMonth);
    return averageMonth;
}
function calculateAverageMonthCashFlow(monthlyView, forecast, monthlyAccounts) {
    const numberOfMonths = monthlyAccounts.length || 1;
    let averageInventoryValue = new MoneyHundred(0);
    let averageCreditValue = new MoneyHundred(0);
    let averageDebtorValue = new MoneyHundred(0);
    let averageCoreCashRequirements = new MoneyHundred(0);
    monthlyView.debtorDays = 0;
    monthlyView.creditorDays = 0;
    monthlyView.inventoryDays = 0;
    monthlyAccounts.forEach((monthlyAccount) => {
        monthlyView.debtorDays += Number(monthlyAccount.debtorDays);
        monthlyView.creditorDays += Number(monthlyAccount.creditorDays);
        monthlyView.inventoryDays += Number(monthlyAccount.inventoryDays);
        const forecastAdjustedAccount = calculateMonthlyCashFlow({
            ...monthlyAccount,
        }, forecast);
        averageInventoryValue = averageInventoryValue.add(forecastAdjustedAccount.inventoryValue);
        averageCreditValue = averageCreditValue.add(forecastAdjustedAccount.creditorsValue);
        averageDebtorValue = averageDebtorValue.add(forecastAdjustedAccount.debtorsValue);
        averageCoreCashRequirements = averageCoreCashRequirements.add(forecastAdjustedAccount.coreCashRequirements);
    });
    monthlyView.debtorDays = Math.round(monthlyView.debtorDays / numberOfMonths);
    monthlyView.creditorDays = Math.round(monthlyView.creditorDays / numberOfMonths);
    monthlyView.inventoryDays = Math.round(monthlyView.inventoryDays / numberOfMonths);
    monthlyView.inventoryValue = averageInventoryValue.divide(numberOfMonths);
    monthlyView.creditorsValue = averageCreditValue.divide(numberOfMonths);
    monthlyView.debtorsValue = averageDebtorValue.divide(numberOfMonths);
    monthlyView.coreCashRequirements = averageCoreCashRequirements.divide(numberOfMonths);
    return monthlyView;
}
function calculateRevenueStream(stream, monthIndex, numberOfMonths) {
    // If month 0, reset all
    if (monthIndex === 0) {
        stream.totalRevenue = new MoneyHundred(0);
        stream.totalCogs = new MoneyHundred(0);
        stream.totalGrossProfit = new MoneyHundred(0);
        stream.months = stream.months.slice(0, numberOfMonths);
        while (stream.months.length < numberOfMonths) {
            stream.months.push({
                revenue: new MoneyHundred(0),
                cogs: new MoneyHundred(0),
                grossProfit: new MoneyHundred(0),
            });
        }
    }
    if (!stream.months[monthIndex]) {
        return stream;
    }
    if (!stream.months[monthIndex].revenue) {
        stream.months[monthIndex].revenue = new MoneyHundred(0);
    }
    if (!stream.months[monthIndex].cogs) {
        stream.months[monthIndex].cogs = new MoneyHundred(0);
    }
    if (stream.cogsPercent) {
        stream.cogsPercent = Number(stream.cogsPercent);
        if (stream.cogsPercent) {
            stream.months[monthIndex].cogs = stream.months[monthIndex].revenue.multiply(stream.cogsPercent / 100);
        }
    }
    else {
        stream.cogsPercent = 0;
    }
    stream.months[monthIndex].grossProfit = stream.months[monthIndex].revenue.subtract(stream.months[monthIndex].cogs);
    // Stream totals
    stream.totalRevenue = stream.totalRevenue.add(stream.months[monthIndex].revenue);
    stream.totalCogs = stream.totalCogs.add(stream.months[monthIndex].cogs);
    stream.totalGrossProfit = stream.totalGrossProfit.add(stream.months[monthIndex].grossProfit);
    return stream;
}
export default class Forecast extends EventEmitter {
    static FORECAST_NUMBER_OF_MONTHS = 12;
    static MAXIMUM_REVENUE_STREAMS = 6;
    errors;
    values;
    isChanged;
    autofill = true;
    constructor(data = {}) {
        super();
        this.errors = {};
        this.values = {
            firstMonth: new Date(),
            numberOfMonths: 12,
            gstRevenue: 15,
            gstExpenses: 15,
            gstPaidAfterDays: 15,
            rolling: {},
            ...data,
        };
        // Bind our event handlers
        const debouncedUpdate = debounce(this.onCalculate.bind(this), 250);
        const debouncedRefresh = debounce(this.requestRefresh.bind(this), 150);
        this.on('updateSimpleField', this.onUpdateSimpleField, this);
        this.on('updateNumericField', this.onUpdateNumericField, this);
        this.on('postFieldUpdate', this.onPostFieldUpdate, this);
        this.on('calculate', debouncedUpdate);
        this.on('requestRefresh', debouncedRefresh);
        // Finally calculate our values;
        this.onCalculate();
    }
    requestRefresh() {
        this.emit('refresh');
    }
    initialize() {
        // Ensure defaults
        initEmpty(this.values, 'cashOnHand', new MoneyHundred(0));
        initEmpty(this.values, 'receivables', new MoneyHundred(0));
        initEmpty(this.values, 'payables', new MoneyHundred(0));
        initEmpty(this.values, 'inventoryValue', new MoneyHundred(0));
        if (!this.values.inventoryValue) {
            this.values.inventoryValue = new MoneyHundred(0);
        }
        if (!this.values.inventoryDays && this.values.inventoryDays !== 0) {
            this.values.inventoryDays = undefined;
        }
        initEmpty(this.values, 'monthlyAccounts', []);
        initEmpty(this.values, 'revenueStreams', []);
        if (!this.values.monthlyAccounts) {
            this.values.monthlyAccounts = [];
        }
        // Ensure the first and last month details are set correctly
        this.values.numberOfMonths = Number(this.values.numberOfMonths) || 1;
        if (!this.values.lastMonthOfData && !this.values.firstMonth) {
            this.values.firstMonth = startOfMonth(new Date());
        }
        if (!this.values.lastMonthOfData) {
            this.values.lastMonthOfData = subMonths(this.values.firstMonth, 1);
        }
        if (!this.values.firstMonth) {
            this.values.firstMonth = addMonths(this.values.lastMonthOfData, 1);
        }
        if (this.values.firstMonth) {
            dateTo2000(this.values.firstMonth);
        }
        if (this.values.lastMonthOfData) {
            dateTo2000(this.values.lastMonthOfData);
        }
        if (this.values.gstPeriodEndedAt) {
            dateTo2000(this.values.gstPeriodEndedAt);
        }
        this.values.months = new Array(Number(Forecast.FORECAST_NUMBER_OF_MONTHS) || 1)
            .fill(0)
            .map((_, i) => addMonths(startOfMonth(this.values.firstMonth), i));
        this.values.rolling = calculateMonthlyView(this.values.rolling);
        this.values.rollingAverage = calculateAverageMonthlyView(this.values.rolling, this.values.numberOfMonths);
        this.values.rollingAverage.closingBalance = this.values.cashOnHand;
        this.values.rollingAverage.debtorsValue = this.values.receivables;
        this.values.rollingAverage.creditorsValue = this.values.payables;
        this.values.rollingAverage.inventoryValue = this.values.inventoryValue;
        this.values.rollingAverage.creditorDays = this.values.creditorDays;
        this.values.rollingAverage.debtorDays = this.values.debtorDays;
        this.values.rollingAverage.inventoryDays = this.values.inventoryDays;
        this.values.rollingAverage = calculateMonthlyAccountView(this.values.rollingAverage, this.values, {
            closingBalance: new MoneyHundred(0),
            debtorsValue: new MoneyHundred(0),
            creditorsValue: new MoneyHundred(0),
            inventoryValue: new MoneyHundred(0),
            netGst: new Money(0),
            netTax: new Money(0),
        });
        this.values.rollingAverage.netTax = new MoneyHundred(0);
        this.values.rollingAverage.closingBalance = this.values.cashOnHand;
        this.values.rollingAverage.debtorsValue = this.values.receivables;
        this.values.rollingAverage.creditorsValue = this.values.payables;
        this.values.rollingAverage.inventoryValue = this.values.inventoryValue;
        this.values.rollingAverage.creditorDays = this.values.creditorDays;
        this.values.rollingAverage.debtorDays = this.values.debtorDays;
        this.values.rollingAverage.inventoryDays = this.values.inventoryDays;
    }
    calculateSales() {
        // Ensure the number of monthly accounts matches the number of months
        let previousMonthlyAccount = this.values.rollingAverage;
        this.values.monthlyAccounts = this.values.monthlyAccounts.slice(0, Forecast.FORECAST_NUMBER_OF_MONTHS);
        this.values.months.forEach((month, monthIndex) => {
            if (!this.values.monthlyAccounts[monthIndex]) {
                // Seed to the monthly account
                this.values.monthlyAccounts[monthIndex] = calculateMonthlyAccountView({
                    date: month,
                    creditorDays: previousMonthlyAccount.creditorDays,
                    debtorDays: previousMonthlyAccount.debtorDays,
                    inventoryDays: previousMonthlyAccount.inventoryDays,
                }, this.values, previousMonthlyAccount);
            }
            this.values.monthlyAccounts[monthIndex].date = month;
            previousMonthlyAccount = this.values.monthlyAccounts[monthIndex];
        });
        // Populate monthly accounts only if there are revenue streams
        if (this.values.revenueStreams.length) {
            this.values.months.forEach((month, monthIndex) => {
                this.values.monthlyAccounts[monthIndex].revenue = new MoneyHundred(0);
                this.values.monthlyAccounts[monthIndex].cogs = new MoneyHundred(0);
                this.values.revenueStreams = this.values.revenueStreams.map((stream) => {
                    const nextStream = calculateRevenueStream(stream, monthIndex, Forecast.FORECAST_NUMBER_OF_MONTHS);
                    // Overview totals
                    this.values.monthlyAccounts[monthIndex].revenue = this.values.monthlyAccounts[monthIndex].revenue.add(nextStream.months[monthIndex].revenue);
                    this.values.monthlyAccounts[monthIndex].cogs = this.values.monthlyAccounts[monthIndex].cogs.add(nextStream.months[monthIndex].cogs);
                    return nextStream;
                });
            });
        }
        this.calculateProfit();
    }
    calculateProfit() {
        this.values.totals = calculateMonthlyView({});
        let previousMonthlyAccount = this.values.rollingAverage;
        let nextGstPeriod = addMonths(startOfMonth(this.values.gstPeriodEndedAt), this.values.gstPeriodMonths);
        this.values.monthlyAccounts = this.values.monthlyAccounts.slice(0, Forecast.FORECAST_NUMBER_OF_MONTHS);
        this.values.monthlyAccounts = this.values.monthlyAccounts.map((monthlyAccount, monthIndex) => {
            const isGstDue = dateIsEqual(nextGstPeriod, monthlyAccount.date);
            if (isGstDue) {
                monthlyAccount.isGstDue = true;
                nextGstPeriod = addMonths(nextGstPeriod, this.values.gstPeriodMonths);
            }
            monthlyAccount.isTaxDue =
                TAX_MONTHS_LOOKUP[monthlyAccount.date.getMonth()];
            const nextMonthlyAccount = calculateMonthlyAccountView(monthlyAccount, this.values, previousMonthlyAccount);
            previousMonthlyAccount = nextMonthlyAccount;
            // Information
            Object.keys(this.values.totals).forEach((key) => {
                if (!this.values.totals[key]?.add) {
                    return;
                }
                if (!nextMonthlyAccount[key]) {
                    return;
                }
                this.values.totals[key] = this.values.totals[key].add(nextMonthlyAccount[key]);
            });
            return nextMonthlyAccount;
        });
        this.values.totals = calculateMonthlyView(this.values.totals);
        this.values.totalsAverage = calculateAverageMonthlyView(this.values.totals, Forecast.FORECAST_NUMBER_OF_MONTHS);
        this.values.totalsAverage = calculateAverageMonthCashFlow(this.values.totalsAverage, this.values, this.values.monthlyAccounts);
        this.values.totals.closingBalance =
            this.values.monthlyAccounts[this.values.monthlyAccounts.length - 1]
                .closingBalance ?? new MoneyHundred(0);
        this.values.totals.coreCashRequirements =
            this.values.monthlyAccounts[this.values.monthlyAccounts.length - 1]
                .coreCashRequirements ?? new MoneyHundred(0);
    }
    calculateGrowth() {
        this.values.monthlyChange = calculateMonthlyChange(this.values);
        this.values.growth = {
            ...this.values.totals,
            inventoryValue: new MoneyHundred(0),
            creditorsValue: new MoneyHundred(0),
            debtorsValue: new MoneyHundred(0),
            closingBalance: new MoneyHundred(0),
            coreCashRequirements: new MoneyHundred(0),
        };
        // Calculate our current monthly view
        this.values.growthMonthly = calculateAverageMonthlyView(this.values.growth, Forecast.FORECAST_NUMBER_OF_MONTHS);
        // Calculate our monthlyChange percentages
        this.values.monthlyChange.revenuePercent = calculatePercentOf(this.values.monthlyChange.revenue, this.values.growthMonthly.revenue);
        this.values.monthlyChange.directLabourPercent = calculatePercentOf(this.values.monthlyChange.directLabour, this.values.growthMonthly.directLabour);
        this.values.monthlyChange.facilitiesPercent = calculatePercentOf(this.values.monthlyChange.facilities, this.values.growthMonthly.facilities);
        this.values.monthlyChange.marketingSalesPercent = calculatePercentOf(this.values.monthlyChange.marketingSales, this.values.growthMonthly.marketingSales);
        this.values.monthlyChange.salariesPercent = calculatePercentOf(this.values.monthlyChange.salaries, this.values.growthMonthly.salaries);
        this.values.monthlyChange.payrollBenefitsPercent = calculatePercentOf(this.values.monthlyChange.payrollBenefits, this.values.growthMonthly.payrollBenefits);
        this.values.monthlyChange.remainingOpExPercent = calculatePercentOf(this.values.monthlyChange.remainingOpEx, this.values.growthMonthly.remainingOpEx);
        this.values.monthlyChange.otherIncomeExpensesPercent = calculatePercentOf(this.values.monthlyChange.otherIncomeExpenses, this.values.growthMonthly.otherIncomeExpenses);
        // Update our 12 month forecast
        const forecastNumberOfMonths = 12;
        this.values.growth.revenue = this.values.growth.revenue.add(this.values.monthlyChange.revenue.multiply(forecastNumberOfMonths));
        // For the growth forecast we must change cogs to be a percentage of our revenue growth
        // That is, revenue growth is more sales
        let cogsPercentNumeric = 0;
        if (!this.values.totals.revenue.equals(0)) {
            cogsPercentNumeric =
                this.values.totals.cogs.toNumber() /
                    this.values.totals.revenue.toNumber();
        }
        this.values.growth.cogs = this.values.growth.revenue.multiply(cogsPercentNumeric);
        // Calculate the price increase after the cogs have been calculated
        const priceIncreasePercent = Number(this.values.monthlyChange.priceIncreasePercent || 0) / 100;
        this.values.growth.revenue = this.values.growth.revenue.add(this.values.growth.revenue.multiply(priceIncreasePercent));
        this.values.growth.cogs = this.values.growth.cogs.add(this.values.monthlyChange.cogs.multiply(forecastNumberOfMonths));
        this.values.growth.directLabour = this.values.growth.directLabour.add(this.values.monthlyChange.directLabour.multiply(forecastNumberOfMonths));
        this.values.growth.facilities = this.values.growth.facilities.add(this.values.monthlyChange.facilities.multiply(forecastNumberOfMonths));
        this.values.growth.marketingSales = this.values.growth.marketingSales.add(this.values.monthlyChange.marketingSales.multiply(forecastNumberOfMonths));
        this.values.growth.salaries = this.values.growth.salaries.add(this.values.monthlyChange.salaries.multiply(forecastNumberOfMonths));
        this.values.growth.payrollBenefits = this.values.growth.payrollBenefits.add(this.values.monthlyChange.payrollBenefits.multiply(forecastNumberOfMonths));
        this.values.growth.remainingOpEx = this.values.growth.remainingOpEx.add(this.values.monthlyChange.remainingOpEx.multiply(forecastNumberOfMonths));
        this.values.growth.otherIncomeExpenses = this.values.growth.otherIncomeExpenses.add(this.values.monthlyChange.otherIncomeExpenses.multiply(forecastNumberOfMonths));
        // We must recalculate the percentages...
        this.values.growth = calculateMonthlyView(this.values.growth);
        this.values.growthMonthly = calculateAverageMonthlyView(this.values.growth, Forecast.FORECAST_NUMBER_OF_MONTHS);
        // Calculate our updated cogs, this is based on the dollar value of revenue changes,
        this.values.growthMonthly.cogs = calculateCogsAsPercentageOfRevenue(this.values.totals.revenue, this.values.totals.cogs, this.values.growthMonthly.revenue);
        this.values.monthlyChange.cogsPercent = calculatePercentOf(this.values.monthlyChange.cogs, this.values.growthMonthly.cogs);
        this.values.growthMonthly.cogs = new MoneyHundred(this.values.growthMonthly.cogs.add(this.values.monthlyChange.cogs));
        // Default the monthlyChange days
        const inventoryDays = this.values.monthlyChange.inventoryDays ??
            this.values.totalsAverage.inventoryDays;
        const creditorDays = this.values.monthlyChange.creditorDays ??
            this.values.totalsAverage.creditorDays;
        const debtorDays = this.values.monthlyChange.debtorDays ??
            this.values.totalsAverage.debtorDays;
        this.values.growth = calculateAverageMonthCashFlow(this.values.growth, this.values, this.values.monthlyAccounts.map((monthlyAccount) => ({
            ...monthlyAccount,
            inventoryDays,
            creditorDays,
            debtorDays,
        })));
        this.values.growthMonthly.coreCashRequirements = this.values.growthMonthly.directLabour
            .add(this.values.growthMonthly.totalOpEx)
            .subtract(this.values.growthMonthly.salaries)
            .multiply(2);
    }
    onCalculate() {
        this.initialize();
        this.calculateSales();
        this.calculateGrowth();
        // Finally request an update;
        this.emit('refresh');
    }
    getRevisedImpact() {
        const revisedImpacts = {};
        revisedImpacts.revenueImpact = this.values.growth.revenue.subtract(this.values.totals.revenue);
        revisedImpacts.netProfitImpact = this.values.growth.netProfitBeforeTax.subtract(this.values.totals.netProfitBeforeTax);
        revisedImpacts.netProfitPercentImpact = `${Number(this.values.growth.netProfitBeforeTaxPercent.slice(0, -1)) -
            Number(this.values.totals.netProfitBeforeTaxPercent.slice(0, -1))}%`;
        revisedImpacts.totalLerImpact = this.values.growth.totalLer.subtract(this.values.totals.totalLer);
        revisedImpacts.directLerImpact = this.values.growth.directLer.subtract(this.values.totals.directLer);
        revisedImpacts.adminLerImpact = this.values.growth.adminLer.subtract(this.values.totals.adminLer);
        revisedImpacts.debtorValueImpact = this.values.totalsAverage.debtorsValue.subtract(this.values.growth.debtorsValue);
        revisedImpacts.creditorValueImpact = this.values.totalsAverage.creditorsValue.subtract(this.values.growth.creditorsValue);
        revisedImpacts.inventoryValueImpact = this.values.totalsAverage.inventoryValue.subtract(this.values.growth.inventoryValue);
        revisedImpacts.priorCashRequirements = this.values.totals.coreCashRequirements;
        revisedImpacts.estimatedCashRequirementsImpact = this.values.growthMonthly.coreCashRequirements.subtract(revisedImpacts.priorCashRequirements);
        revisedImpacts.estimatedCashOnHand = this.values.totals.closingBalance
            .add(revisedImpacts.netProfitImpact.multiply((100 - this.values.taxRate) / 100))
            .add(revisedImpacts.debtorValueImpact)
            .add(revisedImpacts.creditorValueImpact)
            .add(revisedImpacts.inventoryValueImpact);
        revisedImpacts.estimatedCashOnHandImpact = revisedImpacts.estimatedCashOnHand.subtract(this.values.totals.closingBalance);
        let revenuePercentNumeric = 0;
        if (!this.values.totals.revenue.equals(0)) {
            revenuePercentNumeric =
                this.values.totals.grossProfit.toNumber() /
                    this.values.totals.revenue.toNumber();
        }
        revisedImpacts.labourChange = this.values.growthMonthly.directLabour
            .subtract(this.values.totalsAverage.directLabour)
            .add(this.values.growthMonthly.salaries)
            .subtract(this.values.totalsAverage.salaries);
        revisedImpacts.labourChangeNetProfit = new MoneyHundred(0);
        revisedImpacts.labourChangeTotalLer = new MoneyHundred(0);
        if (!revisedImpacts.labourChange.equals(0)) {
            if (revenuePercentNumeric) {
                revisedImpacts.labourChangeNetProfit = revisedImpacts.labourChange.divide(revenuePercentNumeric);
            }
            revisedImpacts.labourChangeTotalLer = revisedImpacts.labourChange.multiply(this.values.totalsAverage.totalLer.toNumber());
        }
        return revisedImpacts;
    }
    nameToObjectPath(name) {
        return name.replace(/\[(\d+)\]/g, '.$1');
    }
    onUpdateSimpleField(name, value) {
        this.isChanged = true;
        objectPath.set(this.values, this.nameToObjectPath(name), value);
        this.emit('postFieldUpdate', name, value);
        this.emit('requestRefresh');
    }
    onUpdateNumericField(name, value) {
        this.isChanged = true;
        objectPath.set(this.values, this.nameToObjectPath(name), value);
        this.emit('postFieldUpdate', name, value);
        this.emit('calculate');
    }
    onPostFieldUpdate(name, value) {
        if (!this.autofill) {
            return;
        }
        const pathName = this.nameToObjectPath(name);
        const pathParts = pathName.split('.') || [];
        if (pathParts[0] === 'revenueStreams' && pathParts.includes('months')) {
            const [streamKey, streamIndex, monthKey, monthIndex, fieldKey,] = pathParts;
            if (!['revenue', 'cogs'].includes(fieldKey)) {
                return;
            }
            const targetList = this.values[streamKey][Number(streamIndex)]
                .months;
            this.populateList(targetList, fieldKey, Number(monthIndex));
        }
        else if (pathParts[0] === 'monthlyAccounts') {
            const [monthKey, monthIndex, fieldKey] = pathParts;
            if (![
                'revenue',
                'cogs',
                'directLabour',
                'facilities',
                'marketingSales',
                'salaries',
                'payrollBenefits',
                'remainingOpEx',
                'otherIncomeExpenses',
                'debtorDays',
                'creditorDays',
                'inventoryDays',
            ].includes(fieldKey)) {
                return;
            }
            const targetList = this.values[monthKey];
            this.populateList(targetList, fieldKey, Number(monthIndex));
        }
    }
    populateList(list, key, fromIndex = 1) {
        if (fromIndex + 1 >= list.length) {
            return;
        }
        // Assume we are filling the whole list
        const fillValue = list[fromIndex][key];
        const testValue = list[fromIndex + 1][key];
        const startIndex = fromIndex;
        let endIndex = startIndex;
        // findEndIndex
        list.forEach((item, itemIndex) => {
            // If the next item is one along from the current item,
            // Then if the next item is equal to our current item,
            // Update the endIndex
            if (itemIndex === endIndex + 1) {
                if (this.isValueEqual(item[key], testValue)) {
                    endIndex = itemIndex;
                }
            }
        });
        list.forEach((item, itemIndex) => {
            if (itemIndex < startIndex + 1) {
                return;
            }
            if (itemIndex > endIndex) {
                return;
            }
            if (typeof item[key] === 'object') {
                item[key] = new MoneyHundred(fillValue);
            }
            item[key] = fillValue;
        });
    }
    // eslint-disable-next-line class-methods-use-this
    isValueEqual(item, previousItem) {
        if (typeof item === 'object') {
            if (item?.equals?.(previousItem)) {
                return true;
            }
            if (item?.equals?.(0)) {
                return true;
            }
            return false;
        }
        if (item === previousItem) {
            return true;
        }
        if (!item) {
            return true;
        }
        return false;
    }
    getValueByPath(name) {
        return objectPath.get(this.values, this.nameToObjectPath(name));
    }
    getErrorByPath(name) {
        return objectPath.get(this.errors, this.nameToObjectPath(name));
    }
    addRevenueStream() {
        if (this.values.revenueStreams.length >=
            Forecast.MAXIMUM_REVENUE_STREAMS) {
            return;
        }
        this.values.revenueStreams.push({
            name: `Category ${this.values.revenueStreams.length + 1}`,
            cogsPercent: undefined,
            months: this.values.months.map((month) => ({
                revenue: new MoneyHundred(0),
                cogs: new MoneyHundred(0),
                grossProfit: new MoneyHundred(0),
            })),
            totalRevenue: new MoneyHundred(0),
            totalCogs: new MoneyHundred(0),
            totalGrossProfit: new MoneyHundred(0),
        });
        this.emit('calculate');
    }
    removeRevenueStream(index) {
        this.values.revenueStreams[index] = undefined;
        this.values.revenueStreams = this.values.revenueStreams.filter(Boolean);
        this.emit('calculate');
    }
    canAddRevenueStream() {
        return (this.values.revenueStreams.length < Forecast.MAXIMUM_REVENUE_STREAMS);
    }
    async validateWith(validationSchema) {
        try {
            await validationSchema.validate(this.values, {
                stripUnknown: true,
                abortEarly: false,
                context: {},
            });
            this.errors = {};
        }
        catch (err) {
            if (err.name === 'ValidationError') {
                this.errors = this.yupToFormErrors(err);
            }
            throw err;
        }
    }
    // Validation util functions
    yupToFormErrors(yupError) {
        const errors = {};
        if (yupError.inner) {
            if (yupError.inner.length === 0) {
                return objectPath.set(errors, yupError.path, yupError.message);
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const err of yupError.inner) {
                if (!objectPath.get(errors, err.path)) {
                    objectPath.set(errors, err.path, err.message);
                }
            }
        }
        return errors;
    }
}
