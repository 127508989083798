import { Money, Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
import Forecast from '../app/entities/forecast';
const VALIDATION = yup.object().shape({
    id: yup.string().required(),
    firstMonth: yup
        .date()
        .required('Please enter a date')
        .typeError('Please enter a date'),
    lastMonthOfData: yup
        .date()
        .required('Please enter a date')
        .typeError('Please enter a date'),
    gstPeriodEndedAt: yup
        .date()
        .required('Please enter a date')
        .typeError('Please enter a date'),
    name: yup
        .string()
        .min(1, 'Please enter a name')
        .max(50, 'Please use a shorter name')
        .required('Please enter a name'),
    numberOfMonths: yup
        .number()
        .integer(' ')
        .min(1, ' ')
        .max(120, ' ')
        .required(' '),
    creditorDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    debtorDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    inventoryDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required')
        .typeError('Please enter a number'),
    gstPaidAfterDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstRevenue: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstExpenses: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstPeriodMonths: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    taxRate: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    cashOnHand: yup.money().required('This field is required'),
    payables: yup.money().required('This field is required'),
    receivables: yup.money().required('This field is required'),
    inventoryValue: yup.money().required('This field is required'),
    rolling: yup.object().shape({
        revenue: yup.money().required('Please enter your revenue'),
        cogs: yup.money().required('Please enter your cogs'),
        directLabour: yup.money().required('Please enter your direct labour'),
        facilities: yup.money().required('Please enter your facilities'),
        marketingSales: yup
            .money()
            .required('Please enter your marketing and sales'),
        salaries: yup.money().required('Please enter your salaries'),
        payrollBenefits: yup
            .money()
            .required('Please enter your payroll benefits'),
        remainingOpEx: yup
            .money()
            .required('Please enter your remaining op ex'),
        otherIncomeExpenses: yup
            .money()
            .required('Please enter your other income or expenses'),
    }),
    monthlyAccounts: yup
        .array()
        .of(yup.object().shape({
        revenue: yup.money().required('Please enter your revenue'),
        cogs: yup.money().required('Please enter your cogs'),
        directLabour: yup
            .money()
            .required('Please enter your direct labour'),
        facilities: yup
            .money()
            .required('Please enter your facilities'),
        marketingSales: yup
            .money()
            .required('Please enter your marketing and sales'),
        salaries: yup.money().required('Please enter your salaries'),
        payrollBenefits: yup
            .money()
            .required('Please enter your payroll benefits'),
        remainingOpEx: yup
            .money()
            .required('Please enter your remaining op ex'),
        otherIncomeExpenses: yup
            .money()
            .required('Please enter your other income or expenses'),
        creditorDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .required('This field is required')
            .default(0),
        debtorDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .required('This field is required')
            .default(0),
        inventoryDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .required('This field is required')
            .default(0),
        inventoryValue: yup
            .money()
            .required('Please enter your inventory value')
            .default(new Money(0)),
        additionalCashInOut: yup
            .money()
            .required('Please enter your remaining op ex'),
        additionalCashForInventory: yup
            .money()
            .required('Please enter your other income or expenses'),
    }))
        .min(Forecast.FORECAST_NUMBER_OF_MONTHS)
        .max(Forecast.FORECAST_NUMBER_OF_MONTHS),
    monthlyChange: yup.object().shape({
        revenue: yup.money().required('Please enter your revenue'),
        cogs: yup.money().required('Please enter your cogs'),
        directLabour: yup.money().required('Please enter your direct labour'),
        facilities: yup.money().required('Please enter your facilities'),
        marketingSales: yup
            .money()
            .required('Please enter your marketing and sales'),
        salaries: yup.money().required('Please enter your salaries'),
        payrollBenefits: yup
            .money()
            .required('Please enter your payroll benefits'),
        remainingOpEx: yup
            .money()
            .required('Please enter your remaining op ex'),
        otherIncomeExpenses: yup
            .money()
            .required('Please enter your other income or expenses'),
        priceIncreasePercent: yup
            .number()
            .min(0, 'Please enter a number')
            .required('Please enter a number')
            .default(0),
        creditorDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .optional(),
        debtorDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .optional(),
        inventoryDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .optional(),
    }),
    revenueStreams: yup
        .array()
        .of(yup.object().shape({
        name: yup
            .string()
            .min(1, 'Please enter a stream name')
            .required('Please enter a stream name'),
        cogsPercent: yup
            .number()
            .min(0, 'Please enter a number')
            .required('Please enter a number')
            .default(0),
        months: yup
            .array()
            .of(yup.object().shape({
            revenue: yup
                .money()
                .required('Please enter your revenue'),
            cogs: yup
                .money()
                .required('Please enter your cogs'),
        }))
            .min(Forecast.FORECAST_NUMBER_OF_MONTHS)
            .max(Forecast.FORECAST_NUMBER_OF_MONTHS),
    }))
        .max(6),
});
async function updateForecastApi(buslane, validation, { formik, values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        // Default the monthlyChange days
        input.monthlyChange.creditorDays =
            input.monthlyChange.creditorDays ??
                values.totalsAverage.creditorDays;
        input.monthlyChange.debtorDays =
            input.monthlyChange.debtorDays ?? values.totalsAverage.debtorDays;
        input.monthlyChange.inventoryDays =
            input.monthlyChange.inventoryDays ??
                values.totalsAverage.inventoryDays;
        const body = await buslane
            .post('api/rest/forecast/update', { json: input })
            .json();
        if (body.success) {
            return body.data.id;
        }
    }
    catch (e) {
        console.log('#updateForecastApi', e);
    }
    throw new Error('Failed to update forecast');
}
const useUpdateForecastApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => updateForecastApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
    };
};
export default useUpdateForecastApi;
