import TextField from '@material-ui/core/TextField';
import { connect } from 'formik';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import useForecastField from "./useForecastField";
function PercentInput(props) {
    const { inputRef, onChange, value, ...other } = props;
    const [isNegative, setNegative] = useState(false);
    let literalValue = String(value);
    literalValue = literalValue.replace('%', '');
    literalValue = Number(literalValue);
    if (!other.disabled) {
        if (!literalValue) {
            if (isNegative) {
                literalValue = '-';
            }
            else {
                literalValue = '';
            }
        }
    }
    return (React.createElement(NumberFormat, { ...other, value: literalValue, getInputRef: inputRef, onChange: (event) => {
            const nextStringValue = String(event.target.value).replace(/[,%]/g, '');
            let percentValue;
            if (nextStringValue === '-') {
                percentValue = 0;
            }
            else {
                percentValue = Number(nextStringValue);
            }
            if (nextStringValue.startsWith('-')) {
                setNegative(true);
            }
            else {
                setNegative(false);
            }
            onChange({
                target: {
                    name: props.name,
                    value: percentValue,
                },
            });
        }, thousandSeparator: true, isNumericString: true, prefix: "", suffix: "%" }));
}
const ForecastFieldPercent = ({ formik, name, label, disabled, onChange, ...rest }) => {
    const { value, hasError, helperText, inputProps, } = useForecastField(formik, name, rest.helperText, rest.value, 'updateNumericField');
    let handleChange = inputProps.onChange;
    if (onChange) {
        handleChange = (event) => {
            onChange({
                // @ts-ignore
                target: {
                    value: event.target.value,
                    name,
                },
            });
            inputProps.onChange(event);
        };
    }
    return (React.createElement(TextField, { name: name, label: label, value: value, helperText: helperText, error: hasError, disabled: formik.isSubmitting || disabled, type: "text", InputProps: {
            inputComponent: PercentInput,
            inputProps: {
                className: 'percent-input',
            },
        }, ...inputProps, ...rest, onChange: handleChange }));
};
// @ts-ignore
export default connect(ForecastFieldPercent);
