import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatDate from 'date-fns/format';
import React from 'react';
const ReportStart = ({ forecast }) => {
    const lastMonthOfData = formatDate(forecast.lastMonthOfData, 'LLLL yyyy');
    const firstMonthOfForecast = formatDate(forecast.firstMonth, 'LLLL yyyy');
    const gstPeriodEndedAt = formatDate(forecast.gstPeriodEndedAt, 'dd/MM/yyyy');
    return (React.createElement(Box, { width: "100%", display: "flex", justifyContent: "space-between" },
        React.createElement(Box, { width: "38%" },
            React.createElement(TableContainer, { className: "forecast-table" },
                React.createElement(Table, { size: "small" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                            React.createElement(TableCell, null, "Company Name"),
                            React.createElement(TableCell, { align: "right" }, forecast.companyName))),
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Last Month of Data"),
                            React.createElement(TableCell, { align: "right" }, lastMonthOfData)),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "First Month of Forecast"),
                            React.createElement(TableCell, { align: "right" }, firstMonthOfForecast)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "GST/VAT % - Revenue"),
                            React.createElement(TableCell, { align: "right" },
                                forecast.gstRevenue,
                                "%")),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "GST/VAT % - Expenses"),
                            React.createElement(TableCell, { align: "right" },
                                forecast.gstExpenses,
                                "%")),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Tax Rate"),
                            React.createElement(TableCell, { align: "right" },
                                forecast.taxRate,
                                "%")),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Cash on Hand"),
                            React.createElement(TableCell, { align: "right" }, forecast.cashOnHand.toString())),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Receivables"),
                            React.createElement(TableCell, { align: "right" }, forecast.receivables.toString())),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Days to get Paid"),
                            React.createElement(TableCell, { align: "right" }, forecast.debtorDays)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Payables"),
                            React.createElement(TableCell, { align: "right" }, forecast.payables.toString())),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Days to Pay"),
                            React.createElement(TableCell, { align: "right" }, forecast.creditorDays)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Inventory Value"),
                            React.createElement(TableCell, { align: "right" }, forecast.inventoryValue.toString())),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Inventory Days"),
                            React.createElement(TableCell, { align: "right" }, forecast.inventoryDays)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "GST/VAT Period (Months)"),
                            React.createElement(TableCell, { align: "right" }, forecast.gstPeriodMonths)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Last day of most recent GST/VAT period"),
                            React.createElement(TableCell, { align: "right" }, gstPeriodEndedAt)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "GST/VAT Paid after (Days)"),
                            React.createElement(TableCell, { align: "right" }, forecast.gstPaidAfterDays)))))),
        React.createElement(Box, { width: "38%" },
            React.createElement(TableContainer, { className: "forecast-table" },
                React.createElement(Table, { size: "small" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                            React.createElement(TableCell, null),
                            React.createElement(TableCell, { align: "right" },
                                "Rolling ",
                                forecast.numberOfMonths),
                            React.createElement(TableCell, { align: "right" }, "Monthly"),
                            React.createElement(TableCell, { align: "right" }, "% of Revenue"))),
                    React.createElement(TableBody, null,
                        React.createElement(TableRow, { className: "forecast-table__row--start" },
                            React.createElement(TableCell, null, "Revenue"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.revenue.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.revenue.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.revenuePercent)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "COGS"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.cogs.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.cogs.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.cogsPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Gross Profit"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.grossProfit.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.grossProfit.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.grossProfitPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--start" },
                            React.createElement(TableCell, null, "Direct Labour"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.directLabour.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.directLabour.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .directLabourPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Contribution Profit"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.contributionProfit.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.contributionProfit.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .contributionProfitPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--start" },
                            React.createElement(TableCell, null, "Facilities"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.facilities.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.facilities.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.facilitiesPercent)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Marketing & Sales"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.marketingSales.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.marketingSales.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .marketingSalesPercent)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Salaries"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.salaries.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.salaries.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.salariesPercent)),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Payroll & Benefits"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.payrollBenefits.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.payrollBenefits.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .payrollBenefitsPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Remaining Op Ex"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.remainingOpEx.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.remainingOpEx.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .remainingOpExPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--start" },
                            React.createElement(TableCell, null, "Total OpEx"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.totalOpEx.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.totalOpEx.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.totalOpExPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Operating Profit"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.operatingProfit.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.operatingProfit.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .operatingProfitPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--start" },
                            React.createElement(TableCell, null, "Other Income / Expenses"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.otherIncomeExpenses.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.otherIncomeExpenses.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .otherIncomeExpensesPercent)),
                        React.createElement(TableRow, { className: "forecast-table__row--end" },
                            React.createElement(TableCell, null, "Net Profit Before Tax"),
                            React.createElement(TableCell, { align: "right" }, forecast.rolling.netProfitBeforeTax.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage.netProfitBeforeTax.toString()),
                            React.createElement(TableCell, { align: "right" }, forecast.rollingAverage
                                .netProfitBeforeTaxPercent))))))));
};
export default ReportStart;
