import { Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
const VALIDATION = yup.object().shape({
    resellerId: yup.string().required(),
    companyId: yup.string().required(),
});
async function addCompanyToResellerApi(buslane, validation, { values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const body = await buslane
            .post('api/rest/reseller/companies', { json: input })
            .json();
        if (body.success) {
            return;
        }
    }
    catch (e) { }
    throw new Error('Failed to create record');
}
const useAddCompanyToResellerApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => addCompanyToResellerApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
    };
};
export default useAddCompanyToResellerApi;
