/* eslint-disable no-param-reassign */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import FinanceCell from '../../../components/FinanceTableCell';
const ReportGrowthPositionTable = ({ forecast, }) => {
    const revisedImpacts = forecast.getRevisedImpact();
    return (React.createElement(TableContainer, { className: "forecast-table" },
        React.createElement(Table, { size: "small", padding: "default" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: "forecast-table__row--important" },
                    React.createElement(TableCell, { component: "th" }),
                    React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", colSpan: 3, align: "center" }, "Annualised Position")),
                React.createElement(TableRow, { className: "forecast-table__row--important forecast-table__row--end" },
                    React.createElement(TableCell, { component: "th" }),
                    React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "Forecast"),
                    React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "Revised"),
                    React.createElement(TableCell, { className: "forecast-card__forecast-period", component: "th", align: "center" }, "Impact"))),
            React.createElement(TableBody, null,
                React.createElement(TableRow, { className: "forecast-table__row--start" },
                    React.createElement(TableCell, { align: "left" }, "Revenue"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.revenue?.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growth.revenue?.toString())),
                    React.createElement(FinanceCell, { value: revisedImpacts.revenueImpact, align: "right" })),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left" }, "Net Profit Before Tax"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.netProfitBeforeTax?.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growth.netProfitBeforeTax?.toString())),
                    React.createElement(FinanceCell, { value: revisedImpacts.netProfitImpact, align: "right" })),
                React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                    React.createElement(TableCell, { align: "left" }, "Net Profit %"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.netProfitBeforeTaxPercent?.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growth.netProfitBeforeTaxPercent?.toString())),
                    React.createElement(FinanceCell, { value: revisedImpacts.netProfitPercentImpact, align: "right" })),
                React.createElement(TableRow, { className: "forecast-table__row--placeholder" },
                    React.createElement(TableCell, { colSpan: 4 })),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left" }, "Revenue / Total Labour"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.totalLer?.format(true, 2)),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growth.totalLer?.format(true, 2))),
                    React.createElement(FinanceCell, { value: revisedImpacts.totalLerImpact, precision: 2, align: "right" })),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left" }, "Direct LER"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.directLer?.format(true, 2)),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growth.directLer?.format(true, 2))),
                    React.createElement(FinanceCell, { value: revisedImpacts.directLerImpact, precision: 2, align: "right" })),
                React.createElement(TableRow, { className: "forecast-table__row--end" },
                    React.createElement(TableCell, { align: "left" }, "Admin LER"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.adminLer?.format(true, 2)),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growth.adminLer?.format(true, 2))),
                    React.createElement(FinanceCell, { value: revisedImpacts.adminLerImpact, precision: 2, align: "right" })),
                React.createElement(TableRow, { className: "forecast-table__row--placeholder" },
                    React.createElement(TableCell, { colSpan: 4 })),
                React.createElement(TableRow, { className: "forecast-table__row--important" },
                    React.createElement(TableCell, { colSpan: 4 }, "Cash Impact")),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left" }, "Debtor Days"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.debtorDays?.toString()),
                    React.createElement(TableCell, { align: "right" }, forecast.values.monthlyChange.debtorDays),
                    React.createElement(FinanceCell, { value: revisedImpacts.debtorValueImpact, align: "right" })),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left" }, "Creditor Days"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.creditorDays?.toString()),
                    React.createElement(TableCell, { align: "right" }, forecast.values.monthlyChange.creditorDays),
                    React.createElement(FinanceCell, { value: revisedImpacts.creditorValueImpact, align: "right" })),
                React.createElement(TableRow, { className: "" },
                    React.createElement(TableCell, { align: "left" }, "Inventory Days"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.inventoryDays?.toString()),
                    React.createElement(TableCell, { align: "right" }, forecast.values.monthlyChange.inventoryDays),
                    React.createElement(FinanceCell, { value: revisedImpacts.inventoryValueImpact, align: "right" })),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left" }, "Core Cash Requirements"),
                    React.createElement(TableCell, { align: "right" }, revisedImpacts.priorCashRequirements?.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, forecast.values.growthMonthly.coreCashRequirements?.toString())),
                    React.createElement(FinanceCell, { value: revisedImpacts.estimatedCashRequirementsImpact, align: "right", invert: true })),
                React.createElement(TableRow, { className: "forecast-table__row--end" },
                    React.createElement(TableCell, { align: "left" }, "Estimated Cash"),
                    React.createElement(TableCell, { align: "right" }, forecast.values.totals.closingBalance?.toString()),
                    React.createElement(TableCell, { align: "right" },
                        React.createElement("b", null, revisedImpacts.estimatedCashOnHand?.toString())),
                    React.createElement(FinanceCell, { value: revisedImpacts.estimatedCashOnHandImpact, align: "right" })),
                React.createElement(TableRow, { className: "forecast-table__row--placeholder" },
                    React.createElement(TableCell, { colSpan: 4 }))))));
};
export default ReportGrowthPositionTable;
