import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Form } from 'formik';
import React from 'react';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import PositiveButton from '../../components/PositiveButton';
import ForecastSubHeader from '../ForecastSubHeader';
import { useForecast } from "../components/forecastProvider";
import RollingForecastField from '../components/RollingForecastField';
import ForecastFieldSimple from "../components/ForecastFieldSimple";
import ForecastFieldPercent from "../components/ForecastFieldPercent";
import ForecastFieldMoney from '../components/ForecastFieldMoney';
import ForecastFieldDate from "../components/ForecastFieldDate";
const ForecastStartingComponent = ({ formikProps, create, }) => {
    const forecast = useForecast();
    const actionButton = (React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, create ? 'Create' : 'Save'));
    return (React.createElement(Form, { noValidate: true, autoComplete: "off", method: "POST" },
        React.createElement(Card, null,
            React.createElement(CardHeader, { className: "forecast-card__header", title: "Start", subheader: React.createElement(ForecastSubHeader, { forecast: forecast }), action: actionButton }),
            React.createElement(CardContent, null,
                React.createElement(Grid, { container: true, spacing: 6 },
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 6 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldSimple, { name: "name", label: "Forecast Name", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldDate, { name: "firstMonth", label: "First Month of Forecast", variant: "outlined", margin: "dense", fullWidth: true, inputFormat: "MM/yyyy", views: ['month', 'year'] })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldPercent, { name: "gstRevenue", label: "GST/VAT % - Revenue", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldPercent, { name: "gstExpenses", label: "GST/VAT % - Expenses", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldPercent, { name: "taxRate", label: "Tax Rate", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldMoney, { name: "cashOnHand", label: "Cash on Hand", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldMoney, { name: "receivables", label: "Receivables", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldSimple, { name: "debtorDays", label: "Days to get Paid", variant: "outlined", margin: "dense", type: "number", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldMoney, { name: "payables", label: "Payables", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldSimple, { name: "creditorDays", label: "Days to Pay", variant: "outlined", margin: "dense", type: "number", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldMoney, { name: "inventoryValue", label: "Inventory Value", variant: "outlined", margin: "dense", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldSimple, { name: "inventoryDays", label: "Inventory Days", variant: "outlined", margin: "dense", type: "number", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(Divider, null)),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldSimple, { name: "gstPeriodMonths", label: "GST/VAT Period (Months)", variant: "outlined", margin: "dense", type: "number", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldDate, { name: "gstPeriodEndedAt", label: "Last day of most recent GST/VAT period", variant: "outlined", margin: "dense", inputFormat: "dd/MM/yyyy", fullWidth: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 8 },
                            React.createElement(ForecastFieldSimple, { name: "gstPaidAfterDays", label: "GST/VAT Paid after (Days)", variant: "outlined", margin: "dense", type: "number", fullWidth: true }))),
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 6 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(ForecastFieldDate, { name: "lastMonthOfData", label: "Last Month of Data", variant: "outlined", margin: "dense", fullWidth: true, inputFormat: "MM/yyyy", views: ['month', 'year'] })),
                        React.createElement(Grid, { item: true, xs: 12, container: true },
                            React.createElement(Grid, { item: true, xs: 5, sm: 5 },
                                React.createElement(Box, { display: "flex", alignItems: "center", flexDirection: "row", width: "100%", height: "100%" },
                                    React.createElement(Typography, { variant: "subtitle2" },
                                        "Rolling",
                                        ' ',
                                        forecast.values.numberOfMonths))),
                            React.createElement(Grid, { item: true, xs: 4, sm: 4 },
                                React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", width: "100%", height: "100%", paddingX: 1 },
                                    React.createElement(Typography, { variant: "subtitle2" }, "Monthly"))),
                            React.createElement(Grid, { item: true, xs: 3, sm: 3 },
                                React.createElement(ForecastFieldSimple, { name: "numberOfMonths", label: "Months", variant: "outlined", margin: "dense", type: "number", fullWidth: true }))),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.revenue, percentage: forecast.values.rollingAverage.revenuePercent, name: "rolling.revenue", label: "Revenue" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.cogs, percentage: forecast.values.rollingAverage.cogsPercent, name: "rolling.cogs", label: "COGS" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.grossProfit, percentage: forecast.values.rollingAverage.grossProfitPercent, name: "rolling.grossProfit", label: "Gross Profit", disabled: true, bold: true }),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Divider, null)),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.directLabour, percentage: forecast.values.rollingAverage.directLabourPercent, name: "rolling.directLabour", label: "Direct Labour" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.contributionProfit, percentage: forecast.values.rollingAverage
                                .contributionProfitPercent, name: "rolling.contributionProfit", label: "Contribution Profit", disabled: true, bold: true }),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Divider, null)),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.facilities, percentage: forecast.values.rollingAverage.facilitiesPercent, name: "rolling.facilities", label: "Facilities" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.marketingSales, percentage: forecast.values.rollingAverage
                                .marketingSalesPercent, name: "rolling.marketingSales", label: "Marketing & Sales" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.salaries, percentage: forecast.values.rollingAverage.salariesPercent, name: "rolling.salaries", label: "Salaries" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.payrollBenefits, percentage: forecast.values.rollingAverage
                                .payrollBenefitsPercent, name: "rolling.payrollBenefits", label: "Payroll & Benefits" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.remainingOpEx, percentage: forecast.values.rollingAverage.remainingOpExPercent, name: "rolling.remainingOpEx", label: "Remaining Op Ex" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.totalOpEx, percentage: forecast.values.rollingAverage.totalOpExPercent, name: "rolling.totalOpEx", label: "Total OpEx", disabled: true }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.operatingProfit, percentage: forecast.values.rollingAverage
                                .operatingProfitPercent, name: "rolling.operatingProfit", label: "Operating Profit", disabled: true, bold: true }),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Divider, null)),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.otherIncomeExpenses, percentage: forecast.values.rollingAverage
                                .otherIncomeExpensesPercent, name: "rolling.otherIncomeExpenses", label: "Other Income / Expenses (+/-)" }),
                        React.createElement(RollingForecastField, { average: forecast.values.rollingAverage.netProfitBeforeTax, percentage: forecast.values.rollingAverage
                                .netProfitBeforeTaxPercent, name: "rolling.netProfitBeforeTax", label: "Net Profit Before Tax", disabled: true, bold: true })))),
            React.createElement(CardActions, null,
                React.createElement(Box, { padding: 1, width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" }, actionButton)))));
};
export default ForecastStartingComponent;
