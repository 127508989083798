import { useBuslane } from 'buslane';
import qs from 'qs';
import { useCallback } from 'react';
export function transformResellers(resellers) {
    return {
        totalCount: resellers.totalCount,
        items: resellers.items.map((reseller) => ({
            ...reseller,
            createdAt: new Date(reseller.createdAt),
            updatedAt: new Date(reseller.updatedAt ?? reseller.createdAt),
        })),
    };
}
async function resellersListApi(buslane, { page, pageSize, sort }) {
    const input = {
        $limit: pageSize,
        $offset: page * pageSize,
        $orderBy: sort,
    };
    const body = await buslane
        .get('api/rest/resellers', {
        searchParams: qs.stringify(input),
    })
        .json();
    if (!body.success) {
        return {
            items: [],
            totalCount: 0,
        };
    }
    const tableInfo = {
        items: body.data,
        totalCount: body.pageInfo.totalCount,
    };
    return tableInfo;
}
const useResellersListApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, parameters) => {
        return resellersListApi(buslane, parameters);
    }, [buslane]);
    return {
        handleRequest,
        handleTransform: transformResellers,
    };
};
export default useResellersListApi;
