import TextField from '@material-ui/core/TextField';
import { connect } from 'formik';
import React from 'react';
import useForecastField from './useForecastField';
const ForecastFieldSimple = ({ formik, name, label, disabled, onChange, ...rest }) => {
    let eventType;
    if (rest.type === 'number') {
        eventType = 'updateNumericField';
    }
    const { value, hasError, helperText, inputProps, } = useForecastField(formik, name, rest.helperText, rest.value, eventType);
    let handleChange = inputProps.onChange;
    if (onChange) {
        handleChange = (event) => {
            onChange({
                // @ts-ignore
                target: {
                    value: event.target.value,
                    name,
                },
            });
            inputProps.onChange(event);
        };
    }
    return (React.createElement(TextField, { name: name, label: label, value: value, helperText: helperText, error: hasError, disabled: formik.isSubmitting || disabled, ...inputProps, ...rest, onChange: handleChange }));
};
// @ts-ignore
export default connect(ForecastFieldSimple);
