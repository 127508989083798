import Box from '@material-ui/core/Box';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import CompaniesListComponent from './CompanyListComponent';
const CompaniesPage = () => {
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Numereyes - Companies")),
        React.createElement(Box, { width: "100%", padding: 4 },
            React.createElement(CompaniesListComponent, null))));
};
export default CompaniesPage;
