/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { ThemeProvider } from '@material-ui/core/styles';
import TableListTheme from '../components/TableListTheme';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCachePaginatedQuery, useCacheQuery, useMutation } from 'butler';
import { Form, Formik } from 'formik';
import MUIDataTable, { TableFooter } from 'mui-datatables';
import React, { useState } from 'react';
import useAddCompanyToResellerApi from '../../api/useAddCompanyToResellerApi';
import useCompaniesListApi from '../../api/useCompaniesListApi';
import useRemoveCompanyFromResellerApi from '../../api/useRemoveCompanyFromResellerApi';
import useResellerApi from '../../api/useResellerApi';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import NegativeButton from '../components/NegativeButton';
import PositiveButton from '../components/PositiveButton';
function generateCustomFooter(isFetching, onAdd, companiesQuery, existingCompanies) {
    return (footerProps) => {
        const [selectedCompany, setSelectedCompany,] = useState();
        return (React.createElement(Box, { position: "relative" },
            React.createElement(Fade, { in: isFetching, style: {
                    transitionDelay: isFetching ? '400ms' : '0ms',
                }, unmountOnExit: true },
                React.createElement(Box, { top: "0", left: "0", position: "absolute", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 3 },
                    React.createElement(CircularProgress, { color: "primary", size: 24 }))),
            React.createElement(TableFooter, { ...footerProps }),
            React.createElement(Box, { padding: 1, paddingLeft: 2, paddingRight: 2, display: "flex", alignItems: "center", justifyContent: "center" },
                React.createElement(Box, { width: "100%", paddingRight: 8, justifyContent: "flex-start", position: "relative" }, companiesQuery.isFetching ? null : (React.createElement(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: companiesQuery.resolvedData?.items?.filter((it) => !existingCompanies.find((existing) => existing.companyId ===
                        it.id)) ?? [], onChange: (it, company) => {
                        if (typeof company !== 'string') {
                            setSelectedCompany(company);
                        }
                    }, getOptionLabel: (option) => option.name, renderInput: (params) => (React.createElement(TextField, { ...params, label: "Company" })) }))),
                React.createElement(Formik, { onSubmit: () => onAdd({ companyId: selectedCompany?.id }), initialValues: {
                        companyId: '',
                    } }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST" },
                    React.createElement(Box, { width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" },
                        React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Add"))))))));
    };
}
const ResellerCompaniesListComponent = ({ resellerId, }) => {
    // Sync the parameters and our URL
    const resellerApi = useResellerApi();
    const resellerQuery = useCacheQuery(['reseller', resellerId], resellerApi.handleRequest, resellerApi.transform, {
        retry: false,
    });
    const addCompanyToResellerApi = useAddCompanyToResellerApi();
    const [mutateAddCompanyToReseller] = useMutation(addCompanyToResellerApi.handleRequest);
    const onAdd = async ({ companyId }) => {
        await mutateAddCompanyToReseller({ values: { resellerId, companyId } });
        await resellerQuery.refetch();
    };
    const removeCompanyFromResellerApi = useRemoveCompanyFromResellerApi();
    const [mutateRemoveCompanyFromReseller] = useMutation(removeCompanyFromResellerApi.handleRequest);
    const onRemove = async ({ companyId }) => {
        await mutateRemoveCompanyFromReseller({
            values: { resellerId, companyId },
        });
        await resellerQuery.refetch();
    };
    const companiesApi = useCompaniesListApi();
    const companiesQuery = useCachePaginatedQuery(['companies', { page: 0, pageSize: 200, sort: 'name' }], companiesApi.handleRequest, companiesApi.handleTransform, {
        retry: false,
    });
    const CustomFooter = generateCustomFooter(resellerQuery.isFetching || companiesQuery.isFetching, onAdd, companiesQuery, resellerQuery.data?.companies ?? []);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(ThemeProvider, { theme: TableListTheme },
            React.createElement(MUIDataTable, { title: React.createElement(Box, { paddingY: 2 },
                    React.createElement(Typography, { variant: "h5" },
                        "Coach - ",
                        resellerQuery.data?.name ?? '...'),
                    React.createElement(Typography, { variant: "body1" }, resellerQuery.data?.email ?? '...')), data: resellerQuery.data?.companies ?? [], columns: [
                    {
                        label: 'Company',
                        name: 'name',
                        options: {
                            searchable: true,
                            print: false,
                            sort: true,
                            draggable: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: ' ',
                        name: 'companyId',
                        options: {
                            searchable: true,
                            print: false,
                            sort: false,
                            draggable: false,
                            viewColumns: false,
                            customBodyRender: (companyId) => (React.createElement(Formik, { onSubmit: onRemove, initialValues: {
                                    companyId,
                                } }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST" },
                                React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: NegativeButton, disableElevation: true }, "Remove"))))),
                        },
                    },
                ], options: {
                    serverSide: false,
                    elevation: 2,
                    search: true,
                    print: false,
                    download: false,
                    selectableRows: 'none',
                    viewColumns: false,
                    filter: false,
                    responsive: 'standard',
                }, components: {
                    TableFooter: CustomFooter,
                } }))));
};
export default ResellerCompaniesListComponent;
