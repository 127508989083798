import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import ButtonLink from '../components/ButtonLink';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ErrorCard from './ErrorCard';
const NotFoundErrorPage = () => (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
    React.createElement(Helmet, null,
        React.createElement("title", null, "Numereyes - Page not Found")),
    React.createElement(ErrorCard, { title: "404: Page not Found" },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(ButtonLink, { to: "/", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "Take me Home"))))));
export default NotFoundErrorPage;
