import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useCachePaginatedQuery } from 'butler';
import MUIDataTable from 'mui-datatables';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Validation as yup } from '@local/website-stdlib';
import useCompanyForecastsListApi from '../../api/useCompanyForecastsListApi';
import ButtonLink from '../components/ButtonLink';
import InternalLink from '../components/InternalLink';
import PositiveButton from '../components/PositiveButton';
import { genLoadingTableFooter } from '../components/ProgressTableFooter';
import TableListTheme from '../components/TableListTheme';
import PrintForecastButton from './report/PrintForecastButton';
import ExternalButtonLink from "../components/ExternalButtonLink";
const PARAMETERS_VALIDATION = yup.object().shape({
    page: yup.number().min(1).default(1),
    pageSize: yup.number().min(1).default(10),
    sort: yup.string().min(0).default('-updatedAt'),
});
function toQueryParameters(parameters) {
    const queryParameters = {
        page: parameters.page + 1,
        pageSize: parameters.pageSize,
        sort: parameters.sort,
    };
    return queryParameters;
}
function fromQueryParameters(queryParameters, companyId) {
    let initState;
    try {
        initState = PARAMETERS_VALIDATION.validateSync(queryParameters);
        initState.page -= 1;
        initState.pageSize = Math.max(5, initState.pageSize);
    }
    catch (e) {
        initState = {
            page: 0,
            pageSize: 12,
        };
    }
    initState.companyId = companyId;
    return initState;
}
function forecastsSearchReducer(state, action) {
    switch (action.type) {
        case 'changePage':
            return { ...state, page: action.page };
        case 'changeRowsPerPage':
            return { ...state, page: 0, pageSize: action.rowsPerPage };
        case 'sort':
            let sort = action.sortOrder.name;
            if (action.sortOrder.direction === 'desc') {
                sort = `-${sort}`;
            }
            return { ...state, sort };
        default:
            return state;
    }
}
const ForecastsListComponent = ({ companyId, companyName }) => {
    // Sync the parameters and our URL
    const history = useHistory();
    const initParameters = useMemo(() => {
        const queryParameters = qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
        });
        return fromQueryParameters(queryParameters, companyId);
    }, [history, companyId]);
    const [parameters, dispatchParameters] = useReducer(forecastsSearchReducer, initParameters);
    useEffect(() => {
        const queryParameters = toQueryParameters(parameters);
        history.replace(`${history.location.pathname}?${qs.stringify(queryParameters)}`);
    }, [history, parameters]);
    const forecastsListApi = useCompanyForecastsListApi();
    const forecastsQuery = useCachePaginatedQuery(['forecasts', parameters], forecastsListApi.handleRequest, forecastsListApi.handleTransform);
    const handleTableChange = useCallback((action, tableState) => {
        if (action === 'changePage' ||
            action === 'changeRowsPerPage' ||
            action === 'sort') {
            dispatchParameters({ type: action, ...tableState });
        }
    }, [dispatchParameters]);
    const listInfo = {
        items: [],
        totalCount: 0,
    };
    if (forecastsQuery.resolvedData) {
        listInfo.items = forecastsQuery.resolvedData.items;
        listInfo.totalCount = forecastsQuery.resolvedData.totalCount;
    }
    const CustomFooter = genLoadingTableFooter(forecastsQuery.isFetching);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(ThemeProvider, { theme: TableListTheme },
            React.createElement(MUIDataTable, { title: React.createElement(Box, { paddingY: 2 },
                    React.createElement(Typography, { variant: "h5" }, "Forecasts"),
                    React.createElement(Typography, { variant: "body1" }, companyName)), data: listInfo.items, columns: [
                    {
                        label: 'Name',
                        name: 'name',
                        options: {
                            searchable: true,
                            print: false,
                            sort: true,
                            draggable: false,
                            viewColumns: false,
                            customBodyRender: (name, tableMeta) => (React.createElement(InternalLink, { to: `/forecast/${tableMeta.rowData[2]}` }, name)),
                        },
                    },
                    {
                        label: 'Last updated',
                        name: 'updatedAt',
                        options: {
                            searchable: true,
                            print: false,
                            sort: true,
                            draggable: false,
                            viewColumns: false,
                            customBodyRender: (date) => date.toLocaleDateString(),
                        },
                    },
                    {
                        label: 'Report',
                        name: 'id',
                        options: {
                            searchable: true,
                            print: false,
                            sort: false,
                            draggable: false,
                            viewColumns: false,
                            customBodyRender: (forecastId) => (React.createElement(PrintForecastButton, { forecastId: forecastId, variant: "contained", disableElevation: true, button: PositiveButton }, "Download Report")),
                        },
                    },
                    {
                        label: 'Export',
                        name: 'id',
                        options: {
                            searchable: true,
                            print: false,
                            sort: false,
                            draggable: false,
                            viewColumns: false,
                            customBodyRender: (forecastId) => (React.createElement(ExternalButtonLink, { to: `/api/rest/forecasts/${forecastId}/export`, variant: "contained", color: "primary", fullWidth: true, disableElevation: true, button: PositiveButton }, "Download Export")),
                        },
                    },
                    {
                        label: ' ',
                        name: 'id',
                        options: {
                            searchable: true,
                            print: false,
                            sort: false,
                            draggable: false,
                            viewColumns: false,
                            customBodyRender: (forecastId) => (React.createElement(ButtonLink, { to: `/forecast/${forecastId}`, variant: "contained", color: "primary", disableElevation: true }, "View Forecast")),
                        },
                    },
                ], options: {
                    serverSide: true,
                    elevation: 2,
                    search: true,
                    print: false,
                    download: false,
                    selectableRows: 'none',
                    viewColumns: false,
                    filter: false,
                    responsive: 'standard',
                    // List features
                    page: parameters.page,
                    rowsPerPage: parameters.pageSize,
                    rowsPerPageOptions: [5, 10, 25],
                    count: listInfo.totalCount,
                    onTableChange: handleTableChange,
                }, components: {
                    TableFooter: CustomFooter,
                } }))));
};
export default ForecastsListComponent;
