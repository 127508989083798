import { useBuslane } from 'buslane';
import qs from 'qs';
import { useCallback } from 'react';
import getMonthName from '../app/utils/getMonthName';
export function transformRecords(records) {
    return {
        totalCount: records.totalCount,
        items: records.items.map((record) => {
            const accountDate = new Date(record.accountMonth);
            const accountMonth = getMonthName(accountDate.getMonth());
            const accountYear = accountDate.getFullYear();
            return {
                id: record.id,
                companyId: record.companyId,
                accountMonth: accountDate,
                accountMonthName: String(accountMonth),
                accountYear: String(accountYear),
                updatedAt: new Date(record.updatedAt ?? record.createdAt),
                createdAt: new Date(record.createdAt),
            };
        }),
    };
}
async function recordsListApi(buslane, { page, pageSize, sort, companyId }) {
    const input = {
        $limit: pageSize,
        $offset: page * pageSize,
        $orderBy: sort,
        companyId,
    };
    if (input.$orderBy) {
        input.$orderBy = input.$orderBy.replace('accountYear', 'accountMonth');
        input.$orderBy = input.$orderBy.replace('accountMonthName', 'accountMonth');
    }
    const body = await buslane
        .get('api/rest/account-records', {
        searchParams: qs.stringify(input),
    })
        .json();
    if (!body.success) {
        return {
            items: [],
            totalCount: 0,
        };
    }
    const tableInfo = {
        items: body.data,
        totalCount: body.pageInfo.totalCount,
    };
    return tableInfo;
}
const useRecordsListApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, parameters) => recordsListApi(buslane, parameters), [buslane]);
    return {
        handleRequest,
        handleTransform: transformRecords,
    };
};
export default useRecordsListApi;
