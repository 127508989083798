import { Button, Snackbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useMutation, useQueryCache } from 'butler';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import useUpdateForecastApi from '../../api/useUpdateForecastApi';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import InternalButtonLink from '../components/InternalButtonLink';
import ForecastCashForm from './cash/ForecastCashForm';
import ForecastGrowthForm from './growth/ForecastGrowthForm';
import ForecastProfitForm from './profit/ForecastProfitForm';
import PrintForecastButton from './report/PrintForecastButton';
import ForecastSalesForm from './sales/ForecastSalesForm';
import ForecastStartForm from './start/ForecastStartForm';
import { ForecastProvider } from "./components/forecastProvider";
import Forecast from "../entities/forecast";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (React.createElement("div", { hidden: value !== index, id: `wrapped-tabpanel-${index}`, ...other }, value === index && children));
}
function SaveAlert({ isOpen, setOpen, formikProps }) {
    return (React.createElement(Snackbar, { className: "numereyes-orange-snackbar", anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, open: isOpen, message: "You have made changes, would you like to save?", action: (React.createElement(Box, { display: "flex" },
            React.createElement(ButtonWithSpinner, { onClick: formikProps.handleSubmit, disabled: formikProps.isSubmitting, color: "inherit" }, "Save"),
            React.createElement(Button, { onClick: () => {
                    setOpen(false);
                }, disabled: formikProps.isSubmitting, color: "inherit" }, "Close"))) }));
}
const UNSAVED_CHANGES_MESSAGE = 'You have unsaved changes, are you sure you want to leave?';
const EditForecastForm = ({ forecast: forceaseResource }) => {
    const [forecastEntity] = useState(() => new Forecast(forceaseResource));
    const [forceRefresh, setRefresh] = useState(false);
    useEffect(() => {
        forecastEntity.on('refresh', () => {
            setRefresh(!forceRefresh);
        });
        return () => {
            forecastEntity.removeListener('refresh');
        };
    }, [forceRefresh]);
    // Catch unload events to prompt
    useEffect(() => {
        const onUnload = (e) => {
            if (forecastEntity.isChanged) {
                // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onUnload);
        return () => window.removeEventListener('beforeunload', onUnload);
    }, [forecastEntity]);
    const formikRef = useRef();
    const [isSaveAlertOpen, setSetSaveAlertOpen] = useState(false);
    let { tab } = useParams();
    const history = useHistory();
    if (!tab) {
        tab = '';
    }
    const handleChange = (event, nextTab) => {
        if (tab === nextTab) {
            return;
        }
        const nextUrl = `/forecast/${forecastEntity.values.id}/${nextTab}`;
        history.replace(nextUrl);
        if (forecastEntity.isChanged) {
            setSetSaveAlertOpen(true);
        }
    };
    const queryCache = useQueryCache();
    const forecastApi = useUpdateForecastApi();
    const [mutate] = useMutation(forecastApi.handleRequest);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            await forecastEntity.validateWith(forecastApi.validation);
        }
        catch (e) {
            return;
        }
        try {
            const forecastId = await mutate({
                formik: formikHelpers,
                values: forecastEntity.values,
            });
            if (forecastId) {
                forecastEntity.isChanged = false;
                setSetSaveAlertOpen(false);
                await queryCache.invalidateQueries([
                    'forecast',
                    forecastId,
                ]);
            }
        }
        catch (e) { }
    }, [mutate, setSetSaveAlertOpen]);
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Prompt, { message: (location, action) => {
                if (location.pathname.startsWith('/forecast/')) {
                    return true;
                }
                if (!forecastEntity.isChanged) {
                    return true;
                }
                return UNSAVED_CHANGES_MESSAGE;
            } }),
        React.createElement(ForecastProvider, { forecast: forecastEntity },
            React.createElement(Formik, { onSubmit: onSubmit, initialValues: {}, enableReinitialize: true }, (formikProps) => {
                formikRef.current = formikProps;
                return (React.createElement(React.Fragment, null,
                    React.createElement(AppBar, { position: "static", color: "secondary" },
                        React.createElement(Tabs, { value: tab, onChange: handleChange, variant: "scrollable", scrollButtons: "auto", indicatorColor: "primary" },
                            React.createElement(Tab, { value: "", label: "Start" }),
                            React.createElement(Tab, { value: "sales", label: "Sales & Margin" }),
                            React.createElement(Tab, { value: "profit", label: "Profit Calculator" }),
                            React.createElement(Tab, { value: "cash", label: "Cash Calculator" }),
                            React.createElement(Tab, { value: "growth", label: "Growth Levers" }),
                            React.createElement(Box, { flexGrow: 1 }),
                            React.createElement(Box, { display: "flex" },
                                React.createElement(PrintForecastButton, { forecastId: forecastEntity.values.id, variant: "text", color: "inherit", disableElevation: true, disabled: formikProps.dirty }, "Download Report")),
                            React.createElement(Box, null,
                                React.createElement(InternalButtonLink, { className: "MuiTab-root", color: "inherit", variant: "text", to: `/company/${forecastEntity.values.companyId}/forecast` }, "Back to Forecasts")))),
                    React.createElement(TabPanel, { value: tab, index: "" },
                        React.createElement(ForecastStartForm, { formikProps: formikProps })),
                    React.createElement(TabPanel, { value: tab, index: "sales" },
                        React.createElement(ForecastSalesForm, { formikProps: formikProps })),
                    React.createElement(TabPanel, { value: tab, index: "profit" },
                        React.createElement(ForecastProfitForm, { formikProps: formikProps })),
                    React.createElement(TabPanel, { value: tab, index: "cash" },
                        React.createElement(ForecastCashForm, { formikProps: formikProps })),
                    React.createElement(TabPanel, { value: tab, index: "growth" },
                        React.createElement(ForecastGrowthForm, { formikProps: formikProps })),
                    React.createElement(SaveAlert, { isOpen: isSaveAlertOpen, setOpen: setSetSaveAlertOpen, formikProps: formikProps })));
            }))));
};
export default EditForecastForm;
