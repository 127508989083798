/* eslint-disable no-param-reassign */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import FinanceCell from '../../components/FinanceTableCell';
const ForecastGrowthLersTable = ({ forecast, }) => {
    const revisedImpacts = forecast.getRevisedImpact();
    return (React.createElement(TableContainer, { className: "forecast-table" },
        React.createElement(Table, { size: "small", padding: "default" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: "forecast-table__flow", align: "left", colSpan: 4 },
                        React.createElement(Typography, { variant: "body2", style: { fontSize: 12 } }, "If any Labour cost is added versus the Forecast, how much additional Monthly REVENUE is required to achieve the same Net Profit or same Total Labour Efficiency Ratio (LER)."))),
                React.createElement(TableRow, { className: "forecast-table__row--important" },
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, { className: "forecast-card__forecast-period", align: "center", colSpan: 2, component: "th" }, "Revenue Required")),
                React.createElement(TableRow, { className: "forecast-table__row--end forecast-table__row--important" },
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, { align: "center", className: "forecast-card__forecast-period", component: "th" }, "Net Profit"),
                    React.createElement(TableCell, { align: "center", className: "forecast-card__forecast-period", component: "th" }, "Revenue / Total Labour"))),
            React.createElement(TableBody, null,
                React.createElement(TableRow, { className: "forecast-table__row--end" },
                    React.createElement(TableCell, { align: "left" }, "Labour Change"),
                    React.createElement(FinanceCell, { value: revisedImpacts.labourChange, precision: 2, align: "right", invert: true }),
                    React.createElement(FinanceCell, { value: revisedImpacts.labourChangeNetProfit, precision: 2, align: "right", invert: true }),
                    React.createElement(FinanceCell, { value: revisedImpacts.labourChangeTotalLer, precision: 2, align: "right", invert: true }))))));
};
export default ForecastGrowthLersTable;
