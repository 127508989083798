import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';
const ErrorCard = ({ children, title }) => (React.createElement(Box, { display: "flex", width: "100%", minHeight: "62%", justifyContent: "center", alignItems: "center" },
    React.createElement(Box, { width: "100%", maxWidth: 480, padding: 4 },
        React.createElement(Card, null,
            React.createElement(CardHeader, { title: title }),
            React.createElement(CardContent, null, children)))));
export default ErrorCard;
