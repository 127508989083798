import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'butler';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import FormTextField from '../../form/FormTextField';
import useUpdateRecordApi from '../../api/useUpdateRecordApi';
import ButtonWithSpinner from '../components/ButtonWithSpinner';
import PositiveButton from '../components/PositiveButton';
import UploadRecordZone from './UploadRecordZone';
const UpdateRecordComponent = ({ recordId, accountYear, accountMonth, companyId, erpType, }) => {
    const history = useHistory();
    const uploadRecordApi = useUpdateRecordApi();
    const [mutate] = useMutation(uploadRecordApi.handleRequest);
    const onSubmit = useCallback(async (values, formikHelpers) => {
        try {
            const uploaded = await mutate({
                formik: formikHelpers,
                values,
                recordId,
            });
            if (uploaded) {
                history.push(`/company/${companyId}`);
            }
        }
        catch (e) { }
    }, [mutate, history]);
    const initialValues = {
        accountYear,
        accountMonth,
        monthlyAccounts: undefined,
    };
    return (React.createElement(Box, { width: "100%" },
        React.createElement(Formik, { validationSchema: uploadRecordApi.validation, onSubmit: onSubmit, initialValues: {
                record: {
                    ...initialValues,
                },
            } }, (formikProps) => (React.createElement(Form, { noValidate: true, method: "POST" },
            React.createElement(Card, null,
                React.createElement(CardHeader, { title: "Update Month" }),
                React.createElement(CardContent, null,
                    React.createElement(Grid, { container: true, spacing: 6 },
                        React.createElement(Grid, { item: true, xs: 12, sm: 12 },
                            React.createElement(UploadRecordZone, { erpType: erpType, hasRecord: Boolean(formikProps.values?.record
                                    ?.monthlyAccounts), onChange: (accounts) => {
                                    formikProps.setFieldValue('record.monthlyAccounts', accounts);
                                }, disabled: formikProps.isSubmitting, error: formikProps.errors?.record
                                    ?.monthlyAccounts })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                            React.createElement(FormTextField, { fullWidth: true, name: "record.accountMonth", label: "Month", type: "text", variant: "outlined", disabled: true })),
                        React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                            React.createElement(FormTextField, { fullWidth: true, name: "record.accountYear", label: "Year", type: "number", variant: "outlined", disabled: true })))),
                React.createElement(CardActions, null,
                    React.createElement(Box, { padding: 1, width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" },
                        React.createElement(ButtonWithSpinner, { type: "submit", disabled: formikProps.isSubmitting, variant: "contained", button: PositiveButton, disableElevation: true }, "Upload Record")))))))));
};
export default UpdateRecordComponent;
