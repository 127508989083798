import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCacheQuery } from 'butler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useCompanyApi from '../../api/useCompanyApi';
import ButtonLink from '../components/ButtonLink';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ErrorCard from '../errors/ErrorCard';
import CreateRecordComponent from './CreateRecordComponent';
const CreateRecordPage = () => {
    const { id } = useParams();
    const companyApi = useCompanyApi();
    const companyQuery = useCacheQuery(['company', id], companyApi.handleRequest, companyApi.transform, {
        retry: false,
    });
    if (companyQuery.isLoading) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Loading...")),
            React.createElement(Box, { width: "100%" }, "Loading...")));
    }
    const company = companyQuery.data;
    if (!company) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Company Not Found")),
            React.createElement(ErrorCard, { title: "Company not found" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonLink, { to: "/company/create", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "Create new company"))))));
    }
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Box, { display: "flex", width: "100%", justifyContent: "center", paddingTop: "8%" },
            React.createElement(Helmet, null,
                React.createElement("title", null, `Numereyes - ${company.name}`)),
            React.createElement(Box, { width: "100%", maxWidth: 480, padding: 4 },
                React.createElement(Typography, { variant: "h5", gutterBottom: true }, company.name),
                React.createElement(CreateRecordComponent, { companyId: company.id, erpType: company.erpType })))));
};
export default CreateRecordPage;
