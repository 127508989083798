import Button from '@material-ui/core/Button';
import React from 'react';
const ExternalButtonLink = ({ to, button, ...rest }) => {
    const renderLink = React.useMemo(() => React.forwardRef(({ children, ...linkRest }, ref) => (React.createElement("a", { ...linkRest, ref: ref, href: to }, children))), [to]);
    const buttonElement = React.createElement(button, {
        ...rest,
        component: renderLink,
    }, rest.children);
    return buttonElement;
};
ExternalButtonLink.defaultProps = {
    button: Button,
};
export default ExternalButtonLink;
