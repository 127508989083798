import { useBuslane } from 'buslane';
import { useCallback } from 'react';
import getMonthName from '../app/utils/getMonthName';
import { transformCompany } from './useCompanyApi';
function transformRecord(data) {
    const { record, company } = data;
    if (!record || !company) {
        return {};
    }
    const accountDate = new Date(record.accountMonth);
    const accountMonth = getMonthName(accountDate.getMonth());
    const accountYear = accountDate.getFullYear();
    return {
        record: {
            id: record.id,
            erpType: record.erpType,
            companyId: record.companyId,
            accountMonth: accountDate,
            accountMonthName: String(accountMonth),
            accountYear: String(accountYear),
            updatedAt: new Date(record.updatedAt ?? record.createdAt),
            createdAt: new Date(record.createdAt),
        },
        company: transformCompany(company),
    };
}
async function recordApi(buslane, id) {
    const recordResult = await buslane
        .get(`api/rest/account-records/${id}`)
        .json();
    if (!recordResult.success) {
        throw new Error('Record not found');
    }
    const companyResult = await buslane
        .get(`api/rest/companies/${recordResult.data.companyId}`)
        .json();
    if (!companyResult.success) {
        throw new Error('Record not found');
    }
    return {
        record: recordResult.data,
        company: companyResult.data,
    };
}
const useRecordApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((key, id) => recordApi(buslane, id), [
        buslane,
    ]);
    return {
        handleRequest,
        transform: transformRecord,
    };
};
export default useRecordApi;
