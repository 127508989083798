import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';
import { Money } from '@local/website-stdlib';
const PositiveCell = withStyles((theme) => ({
    root: {
        color: green[900],
        backgroundColor: green[100],
    },
}))(TableCell);
const NegativeCell = withStyles((theme) => ({
    root: {
        color: red[900],
        backgroundColor: red[100],
    },
}))(TableCell);
function valueToNumber(value) {
    if (value instanceof Money) {
        return value.toNumber();
    }
    if (typeof value === 'string') {
        return Number(value.replace('%', ''));
    }
    return value;
}
const FinanceCell = ({ value, invert, compare, precision, ...rest }) => {
    let CellType = TableCell;
    let testValue = valueToNumber(value);
    if (compare !== undefined) {
        testValue -= valueToNumber(compare);
    }
    if (invert) {
        if (testValue < 0) {
            CellType = PositiveCell;
        }
        else if (testValue > 0) {
            CellType = NegativeCell;
        }
    }
    else if (testValue < 0) {
        CellType = NegativeCell;
    }
    else if (testValue > 0) {
        CellType = PositiveCell;
    }
    let strVal = value?.toString();
    if (value instanceof Money) {
        strVal = value.format(true, precision);
    }
    return React.createElement(CellType, { ...rest, align: 'right' }, strVal);
};
export default FinanceCell;
