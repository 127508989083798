import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useCallback, useState, } from 'react';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
let printJS;
if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
    printJS = require('print-js/src/js/print').default;
}
const PrintForecastButton = ({ forecastId, disabled, children, ...rest }) => {
    const [isActive, setActive] = useState(false);
    const handleClick = useCallback(() => {
        setActive(true);
        const printParams = {
            documentTitle: 'Numereyes Forecast',
            frameId: `report-frame-${forecastId}`,
            printableElement: document.createElement('div'),
            onLoadingEnd: () => {
                setActive(false);
            },
        };
        let printFrame = document.getElementById(printParams.frameId);
        if (printFrame) {
            printFrame.remove();
        }
        printFrame = document.createElement('iframe');
        printFrame.setAttribute('src', `/generate/report/forecast/${forecastId}`);
        printFrame.setAttribute('id', printParams.frameId);
        printFrame.style.visibility = 'hidden';
        printFrame.style.width = '0px';
        printFrame.style.height = '0px';
        printFrame.style.position = 'fixed';
        printFrame.style.left = '-1px';
        printParams.printableElement.style.display = 'none';
        printJS.send(printParams, printFrame);
    }, [setActive]);
    let message = '';
    if (disabled) {
        message = 'Save required before download is allowed';
    }
    return (React.createElement(Tooltip, { title: message, placement: "bottom" },
        React.createElement(Box, { display: "flex" },
            React.createElement(ButtonWithSpinner, { ...rest, onClick: handleClick, disabled: isActive || disabled, noSpinner: disabled }, children))));
};
PrintForecastButton.defaultProps = {};
export default PrintForecastButton;
