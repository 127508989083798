import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import withStyles from '@material-ui/styles/withStyles';
const PositiveButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[600]),
        backgroundColor: green[600],
        '&:hover': {
            backgroundColor: green[800],
        },
    },
}))(Button);
export default PositiveButton;
