import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useCacheQuery } from 'butler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import useResellerApi from '../../api/useResellerApi';
import ButtonLink from '../components/ButtonLink';
import Dashboard from '../components/DashboardComponent';
import PrimaryNavListComponent from '../components/PrimaryNavListComponent';
import ErrorCard from '../errors/ErrorCard';
import ResellerCompaniesListComponent from './ResellerCompaniesListComponent';
const ResellerPage = () => {
    const { id } = useParams();
    const resellerApi = useResellerApi();
    const resellerQuery = useCacheQuery(['reseller', id], resellerApi.handleRequest, resellerApi.transform, {
        retry: false,
    });
    if (resellerQuery.isLoading) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Loading...")),
            React.createElement(Box, { width: "100%" }, "Loading...")));
    }
    const reseller = resellerQuery.data;
    if (!reseller) {
        return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
            React.createElement(Helmet, null,
                React.createElement("title", null, "Numereyes - Coach Not Found")),
            React.createElement(ErrorCard, { title: "Coach not found" },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonLink, { to: "/reseller/create", variant: "contained", color: "primary", fullWidth: true, disableElevation: true }, "Create new Coach"))))));
    }
    return (React.createElement(Dashboard, { mainListItems: React.createElement(PrimaryNavListComponent, null) },
        React.createElement(Helmet, null,
            React.createElement("title", null, `Numereyes - ${reseller.name}`)),
        React.createElement(Box, { width: "100%", padding: 4 },
            React.createElement(Box, { marginTop: 4 },
                React.createElement(ResellerCompaniesListComponent, { resellerId: reseller.id })))));
};
export default ResellerPage;
