import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import React, { useState } from 'react';
const AutofillToggle = ({ forecast, }) => {
    const [rendered, render] = useState({});
    return (React.createElement(FormGroup, null,
        React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: forecast.autofill, color: "primary", onChange: (_e, checked) => {
                    // eslint-disable-next-line no-param-reassign
                    forecast.autofill = checked;
                    render({});
                } }), label: "Copy to the right" })));
};
export default AutofillToggle;
