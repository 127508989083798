import { Money, Validation as yup } from '@local/website-stdlib';
import { useBuslane } from 'buslane';
import { useCallback } from 'react';
import Forecast from '../app/entities/forecast';
const VALIDATION = yup.object().shape({
    id: yup.string().required(),
    firstMonth: yup.date().required('Please enter a date'),
    lastMonthOfData: yup.date().required('Please enter a date'),
    gstPeriodEndedAt: yup.date().required('Please enter a date'),
    numberOfMonths: yup
        .number()
        .integer(' ')
        .min(1, ' ')
        .max(120, ' ')
        .required(' '),
    creditorDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    debtorDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    inventoryDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required')
        .typeError('Please enter a number'),
    gstPaidAfterDays: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstRevenue: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstExpenses: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    gstPeriodMonths: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    taxRate: yup
        .number()
        .integer()
        .min(0, 'This field is required')
        .required('This field is required'),
    cashOnHand: yup.money().required('This field is required'),
    payables: yup.money().required('This field is required'),
    receivables: yup.money().required('This field is required'),
    inventoryValue: yup.money().required('This field is required'),
    rolling: yup.object().shape({
        revenue: yup.money().required('Please enter your revenue'),
        cogs: yup.money().required('Please enter your cogs'),
        directLabour: yup.money().required('Please enter your direct labour'),
        facilities: yup.money().required('Please enter your facilities'),
        marketingSales: yup
            .money()
            .required('Please enter your marketing and sales'),
        salaries: yup.money().required('Please enter your salaries'),
        payrollBenefits: yup
            .money()
            .required('Please enter your payroll benefits'),
        remainingOpEx: yup
            .money()
            .required('Please enter your remaining op ex'),
        otherIncomeExpenses: yup
            .money()
            .required('Please enter your other income or expenses'),
    }),
    monthlyAccounts: yup
        .array()
        .of(yup.object().shape({
        revenue: yup.money().required('Please enter your revenue'),
        cogs: yup.money().required('Please enter your cogs'),
        directLabour: yup
            .money()
            .required('Please enter your direct labour'),
        facilities: yup
            .money()
            .required('Please enter your facilities'),
        marketingSales: yup
            .money()
            .required('Please enter your marketing and sales'),
        salaries: yup.money().required('Please enter your salaries'),
        payrollBenefits: yup
            .money()
            .required('Please enter your payroll benefits'),
        remainingOpEx: yup
            .money()
            .required('Please enter your remaining op ex'),
        otherIncomeExpenses: yup
            .money()
            .required('Please enter your other income or expenses'),
        creditorDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .required('This field is required')
            .default(0),
        debtorDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .required('This field is required')
            .default(0),
        inventoryDays: yup
            .number()
            .integer()
            .min(0, 'This field is required')
            .required('This field is required')
            .default(0),
        inventoryValue: yup
            .money()
            .required('Please enter your inventory value')
            .default(new Money(0)),
        additionalCashInOut: yup
            .money()
            .required('Please enter your remaining op ex'),
        additionalCashForInventory: yup
            .money()
            .required('Please enter your other income or expenses'),
    }))
        .min(Forecast.FORECAST_NUMBER_OF_MONTHS)
        .max(Forecast.FORECAST_NUMBER_OF_MONTHS),
    revenueStreams: yup.array().of(yup.object().shape({
        name: yup
            .string()
            .min(1, 'Please enter a stream name')
            .required('Please enter a stream name'),
        cogsPercent: yup
            .number()
            .min(0, 'Please enter a number')
            .required('Please enter a number')
            .default(0),
        months: yup
            .array()
            .of(yup.object().shape({
            revenue: yup
                .money()
                .required('Please enter your revenue'),
            cogs: yup.money().required('Please enter your cogs'),
        }))
            .min(Forecast.FORECAST_NUMBER_OF_MONTHS)
            .max(Forecast.FORECAST_NUMBER_OF_MONTHS),
    })),
});
function percentageToNumber(percent) {
    if (!percent) {
        return 0;
    }
    const percentNumber = Number(percent.replace('%', '') || 0) / 100;
    return percentNumber;
}
function percentageIncrease(percent) {
    return 1 + percentageToNumber(percent);
}
function calculateRevisedForecast(unrevisedForecast, priorForecast) {
    const revisedForecast = {
        ...unrevisedForecast,
    };
    revisedForecast.revenueStreams = revisedForecast.revenueStreams.map((revenueStream) => {
        const revisedRevenueStream = {
            ...revenueStream,
        };
        if (revisedRevenueStream.cogsPercent) {
            revisedRevenueStream.cogsPercent = Number((revisedRevenueStream.cogsPercent *
                percentageIncrease(priorForecast.monthlyChange.cogsPercent)).toFixed(0));
        }
        revisedRevenueStream.months = revisedRevenueStream.months.map((month) => {
            const revisedMonth = {
                ...month,
            };
            revisedMonth.revenue = revisedMonth.revenue.multiply(percentageIncrease(priorForecast.monthlyChange.revenuePercent));
            revisedMonth.cogs = revisedMonth.cogs.multiply(percentageIncrease(priorForecast.monthlyChange.cogsPercent));
            return revisedMonth;
        });
        return revisedRevenueStream;
    });
    revisedForecast.monthlyAccounts = revisedForecast.monthlyAccounts.map((monthlyAccount) => {
        const revisedMonthlyAccount = {
            ...monthlyAccount,
        };
        revisedMonthlyAccount.revenue = revisedMonthlyAccount.revenue.multiply(percentageIncrease(priorForecast.monthlyChange.revenuePercent));
        revisedMonthlyAccount.cogs = revisedMonthlyAccount.cogs.multiply(percentageIncrease(priorForecast.monthlyChange.cogsPercent));
        revisedMonthlyAccount.directLabour = revisedMonthlyAccount.directLabour.multiply(percentageIncrease(priorForecast.monthlyChange.directLabourPercent));
        revisedMonthlyAccount.facilities = revisedMonthlyAccount.facilities.multiply(percentageIncrease(priorForecast.monthlyChange.facilitiesPercent));
        revisedMonthlyAccount.marketingSales = revisedMonthlyAccount.marketingSales.multiply(percentageIncrease(priorForecast.monthlyChange.marketingSalesPercent));
        revisedMonthlyAccount.salaries = revisedMonthlyAccount.salaries.multiply(percentageIncrease(priorForecast.monthlyChange.salariesPercent));
        revisedMonthlyAccount.payrollBenefits = revisedMonthlyAccount.payrollBenefits.multiply(percentageIncrease(priorForecast.monthlyChange.payrollBenefitsPercent));
        revisedMonthlyAccount.remainingOpEx = revisedMonthlyAccount.remainingOpEx.multiply(percentageIncrease(priorForecast.monthlyChange.remainingOpExPercent));
        revisedMonthlyAccount.otherIncomeExpenses = revisedMonthlyAccount.otherIncomeExpenses.multiply(percentageIncrease(priorForecast.monthlyChange.otherIncomeExpensesPercent));
        revisedMonthlyAccount.debtorDays =
            priorForecast.monthlyChange.debtorDays ??
                priorForecast.totalsAverage.debtorDays;
        revisedMonthlyAccount.creditorDays =
            priorForecast.monthlyChange.creditorDays ??
                priorForecast.totalsAverage.creditorDays;
        revisedMonthlyAccount.inventoryDays =
            priorForecast.monthlyChange.inventoryDays ??
                priorForecast.totalsAverage.inventoryDays;
        return revisedMonthlyAccount;
    });
    return revisedForecast;
}
async function copyForecastApi(buslane, validation, { formik, values }) {
    try {
        const input = await validation.validate(values, { stripUnknown: true });
        const revisedForecast = calculateRevisedForecast(input, values);
        const body = await buslane
            .post('api/rest/forecast/copy', {
            json: revisedForecast,
        })
            .json();
        if (body.success) {
            return body.data.id;
        }
    }
    catch (e) {
        console.log('#copyForecastApi', e);
    }
    throw new Error('Failed to copy forecast');
}
const useCopyForecastApi = () => {
    const buslane = useBuslane();
    const handleRequest = useCallback((parameters) => copyForecastApi(buslane, VALIDATION, parameters), [buslane]);
    return {
        handleRequest,
        validation: VALIDATION,
    };
};
export default useCopyForecastApi;
