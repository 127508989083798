import TextField from '@material-ui/core/TextField';
import { connect, getIn } from 'formik';
import React, { useEffect, useState, } from 'react';
const FormTextField = ({ formik, as, ...props }) => {
    const [remoteState, setRemoteState] = useState({
        value: undefined,
        error: undefined,
    });
    useEffect(() => {
        // eslint-disable-next-line no-empty
        if (!formik.isSubmitting) {
            const remoteValue = getIn(formik.values, props.name);
            const remoteError = getIn(formik.errors, props.name);
            setRemoteState({ value: remoteValue, error: remoteError });
        }
    }, [formik.errors, formik.isSubmitting, formik.values, props.name]);
    let value = getIn(formik.values, props.name) || props.value;
    // Convert our value to empty if the field is null or undefined
    if (value === undefined || value === null) {
        value = '';
    }
    const isTouched = getIn(formik.touched, props.name);
    let helperText;
    if (isTouched || formik.submitCount > 0) {
        helperText = getIn(formik.errors, props.name);
        if (value === remoteState.value) {
            helperText = helperText || remoteState.error;
        }
    }
    let hasError = undefined;
    if (helperText) {
        hasError = true;
    }
    else {
        helperText = props.helperText;
    }
    if (helperText) {
        helperText = helperText.trim();
    }
    const fieldState = {
        value,
        helperText: helperText,
        error: hasError,
        disabled: formik.isSubmitting || props.disabled,
    };
    return React.createElement(as, {
        ...props,
        ...fieldState,
        onChange: (event) => {
            formik.setFieldValue(props.name, event.target.value);
            if (props.onChange) {
                props.onChange(props.name, event.target.value);
            }
        },
        onBlur: (event) => {
            formik.setFieldTouched(props.name, true);
        },
    });
};
FormTextField.defaultProps = {
    as: TextField,
};
// @ts-ignore
export default connect(FormTextField);
