// @graph-mind
// Remove the previous line to stop Ada from updating this file
import * as yupBase from 'yup';
const isAbsent = (value) => value == null;
export default class CommonQuerySchemaType extends yupBase.mixed {
    constructor(args) {
        super();
        // TODO(harry): Implement this!
    }
}
