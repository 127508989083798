import { getIn } from 'formik';
import { useState } from 'react';
import { useForecast } from "./forecastProvider";
export default function useForecastField(formik, name, placeholder, inputValue, updateEventType = 'updateSimpleField') {
    const forecast = useForecast();
    const [refresh, setRefresh] = useState(false);
    let helperText;
    let value;
    if (name) {
        value = forecast.getValueByPath(name) ?? inputValue ?? '';
        const isTouched = getIn(formik.touched, name);
        if (isTouched || formik.submitCount > 0) {
            helperText = forecast.getErrorByPath(name);
        }
    }
    // Convert our value to empty if the field is null or undefined
    if (value === undefined || value === null) {
        value = '';
    }
    let hasError = false;
    if (helperText !== undefined) {
        hasError = true;
    }
    else {
        helperText = placeholder;
    }
    if (helperText) {
        helperText = helperText.trim();
    }
    const inputProps = {
        onChange: (event) => {
            if (!name) {
                return;
            }
            forecast.emit(updateEventType, name, event.target.value);
            setRefresh(!refresh);
        },
        onBlur: (event) => {
            if (!name) {
                return;
            }
            formik.setFieldTouched(name, true);
        },
    };
    return {
        value,
        hasError,
        helperText,
        inputProps,
    };
}
