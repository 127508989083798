import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
const useStyles = makeStyles({
    button: {
        height: '100%',
    },
});
const ButtonWithSpinner = (props) => {
    const { 
    // Box
    width, height, maxHeight, marginBottom, 
    // Button
    children, disabled, buttonClassName, button, noSpinner, ...rest } = props;
    const classes = useStyles();
    const buttonClasses = [classes.button, buttonClassName || ''].join(' ');
    const buttonElement = React.createElement(button, {
        ...rest,
        fullWidth: true,
        className: buttonClasses,
        disabled,
    }, children);
    return (React.createElement(Box, { position: "relative", width: width, height: height, maxHeight: maxHeight, marginBottom: marginBottom },
        buttonElement,
        React.createElement(Fade, { in: disabled && !noSpinner, style: {
                transitionDelay: disabled ? '400ms' : '0ms',
            }, unmountOnExit: true },
            React.createElement(Box, { top: "0", left: "0", position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
                React.createElement(CircularProgress, { size: 24 })))));
};
ButtonWithSpinner.defaultProps = {
    button: Button,
};
export default ButtonWithSpinner;
